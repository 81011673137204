<template>
    <v-row justify="center">
        <v-dialog
            v-model="isOpen"
            width="60vw"
            @click:outside="close"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ layer_title }}</span>
                </v-card-title>
                <v-card-text>
                    <div v-if="format == 'list'">
                        <div
                            v-for="(item, i) in data"
                            :key="i"
                        >
                        <a target="_blank" :href="item" v-if="/^http/.test(item)">{{ item }}</a>
                        <div v-else>{{ item }}</div>
                        </div>
                    </div>
                    <v-data-table
                        v-else-if="format == 'table'"
                        :headers="headers"
                        :items="data"
                        :item-key="itemKey"
                        :items-per-page="-1"
                        hide-default-footer
                        dense
                        :sort-by="sortBy"
                    >
                    </v-data-table>
                    <highcharts v-else-if="format == 'chart'"
                        :options="data"
                    ></highcharts>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { requestLayerData } from '@api/layerData';
import { requestLayerInfo } from '@api/layerInfo';
import { showSnackbar } from './Actions';
import EventBus, { ACTIONS } from '@commons/EventBus';
import { mapGetters } from 'vuex';
import Highcharts from 'highcharts';

const LIST = 'list';
const TABLE = 'table';
const CHART = 'chart';

export default {
    name: 'ReferenceMap',
    computed: {
        ...mapGetters(['getAccess'])
    },
    props: {
        tool: String
    },
    data() {
        return {
            isOpen: false,
            layer_title: null,
            itemKey: null,
            sortBy: null,
            headers: [],
            data: [],
            format: null,
        }
    },
    mounted() {
        EventBus.$on(ACTIONS.FEATURE_DETAIL, (layer, slug) => {
            this.setData(layer, slug);
        });
    },
    methods: {
        close() {
            this.layer_title = null;
            this.itemKey = null;
            this.sortBy = null;
            this.headers = [];
            this.data = [];
            this.format = null;
        },
        async setData(layer, slug) {
            let response = await requestLayerData(this.getAccess, layer, slug);
        
            if (response.status != 200) {
                showSnackbar('No data', 'warning', 3000);
                return;
            }
            switch (layer) {
                case 'retiring-generator-summary-by-county':
                    this.format = TABLE;
                    this.layer_title = `${response.data.county_name}, ${response.data.state_usps_abbreviation} Retiring Generators`;
                    response = await requestLayerData(this.getAccess, 'select-generator-retirement', null, `county_id=${slug}`);
                    this.data = response.data;
                    this.itemKey = "id";
                    this.sortBy = ['utility_name', 'facility_name', 'generator_id'];
                    this.headers = [
                        {text: 'Utility', value: 'utility_name', sortable: false},
                        {text: 'Facility', value: 'facility_name', sortable: false},
                        {text: 'Generator ID', value: 'generator_id', sortable: false},
                        {text: 'Technology', value: 'technology', sortable: false},
                        {text: 'Retirement Status', value: 'milestone', sortable: false},
                        {text: 'Retirement Year', value: 'milestone_year', sortable: false},
                        {text: 'Nameplate Capacity (MW)', value: 'nameplate_capacity_megawatt_hours', sortable: false},
                    ];
                    break;
                case 'nuclear-facility-summary-by-county':
                    this.format = TABLE;
                    this.layer_title = `${response.data.county_name}, ${response.data.state_usps_abbreviation} Facilities`;
                    response = await requestLayerData(this.getAccess, 'facility', null, `county_id=${slug}`);
                    this.data = response.data.map(d => ({
                        id: d.id,
                        facility_name: d.facility_name,
                        utility_name: d.utility_name,
                        balancing_authority_code: d.balancing_authority_code,
                        is_nuclear: d.is_nuclear ? 'Yes' : 'No'
                    }));
                    this.itemKey = "id";
                    this.sortBy = 'facility_name';
                    this.headers = [
                        {text: 'Utility-Facility ID', value: 'id', sortable: false},
                        {text: 'Facility Name', value: 'facility_name', sortable: false},
                        {text: 'Utility Name', value: 'utility_name', sortable: false},
                        {text: 'Balancing Authority', value: 'balancing_authority_code', sortable: false},
                        {text: 'Nuclear', value: 'is_nuclear', sortable: false},
                    ];
                    break;
                case 'utility-nuclear-experience-by-county':
                    this.format = TABLE;
                    this.layer_title = `${response.data.county_name}, ${response.data.state_usps_abbreviation} Utilities`;
                    this.data = response.data.utilities;
                    this.itemKey = 'utility';
                    this.sortBy = 'utility';
                    this.headers = [
                        {text: 'Utility', value: 'utility', sortable: false},
                        {text: 'Related Nuclear Experienced Entity', value: 'nuclear_experienced_entity', sortable: false},
                    ];
                    break;
                case 'nuclear-r-and-d-by-county':
                    this.format = TABLE;
                    this.layer_title = `${response.data.county_name}, ${response.data.state_usps_abbreviation} Nuclear Research & Development`;
                    this.data = response.data.nuclear_institutions.map(d => ({
                        institution_name: d.institution_name,
                        operating_agency: d.operating_agency || 'n/a',
                        has_operating_research_or_test_reactor: d.has_operating_research_or_test_reactor ? 'Yes' : 'No',
                        has_robust_nuclear_technology_research: d.has_robust_nuclear_technology_research ? 'Yes' : 'No'
                    }));
                    this.itemKey = 'institution_name';
                    this.sortBy = 'institution_name';
                    this.headers = [
                        { text: 'Institution', value: 'institution_name', sortable: false },
                        { text: 'Operating Agency', value: 'operating_agency', sortable: false },
                        { text: 'Research or Test Reactor', value: 'has_operating_research_or_test_reactor', sortable: false },
                        { text: 'Robust Technology Research', value: 'has_robust_nuclear_technology_research', sortable: false },
                    ]
                    break;
                case 'nuclear-inclusive-policy-by-state':
                    this.format = LIST;
                    this.layer_title = `${response.data.state_name} Nuclear Inclusive Policy`;
                    this.data = [response.data.policy_summary, response.data.policy_url];
                    break;
                case 'nuclear-restriction-by-state':
                    this.format = LIST;
                    this.layer_title = `${response.data.state_name} Nuclear Restrictions`;
                    this.data = response.data.nuclear_restrictions;
                    break;
                case 'nuclear-sentiment-by-county':
                    this.format = CHART;
                    this.layer_title = `${response.data.county_name}, ${response.data.state_usps_abbreviation} Nuclear Sentiment`;
                    this.data = {
                        chart: { animation: false},
                        credits: { enabled: false },
                        title: { text: null },
                        plotOptions: {
                            series: {animation: false}
                        },
                        xAxis: [{
                            categories: ['Support for New Reactors', 'Perceived Risk', 'Perceived Benefit'],
                            crosshair: true,
                            title: { text: 'Sentiment' }
                        }],
                        yAxis: [{
                            labels: {
                                format: '{value}%',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: 'Percentage',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                        }],
                        tooltip: { shared: true },
                        series: [{
                            name: 'Percentage',
                            type: 'column',
                            showInLegend: false,
                            yAxis: 0,
                            data: [
                                parseInt(response.data.support_for_new_reactors_proportion * 100),
                                parseInt(response.data.perceived_risk_proportion * 100),
                                parseInt(response.data.perceived_benefit_proportion * 100),
                                ],
                            tooltip: { valueSuffix: '%' }
                        },
                    ]
                    };
                    break;
                case 'construction-mean-annual-wage-by-state': {
                    const dataUpdateYear = await requestLayerInfo(this.getAccess, layer, this.tool)
                        .then(res => (new Date(res.last_update).getFullYear()))
                        .catch(() => showSnackbar('Error fetching layer info', 'error', 3000));

                    this.format = CHART;
                    this.layer_title = `${response.data.state_name} Labor Rate`;
                    this.data = {
                        chart: { animation: false},
                        credits: { enabled: false },
                        title: { text: null },
                        plotOptions: {
                            series: {
                                animation: false,
                                pointStart: dataUpdateYear - 5
                            },
                        },
                        xAxis: {
                            accessibility: {
                                rangeDescription: `Range: ${dataUpdateYear - 5} to ${dataUpdateYear - 1}`
                            },
                            allowDecimals: false
                        },
                        yAxis: {
                            title: { text: 'Construction Mean Annual Wage (USD)'}
                        },
                        legend: { enabled: false },
                        series: [{
                            name: 'Mean Annual Wage',
                            data: [
                            response.data.maw_5_years_ago,
                            response.data.maw_4_years_ago,
                            response.data.maw_3_years_ago,
                            response.data.maw_2_years_ago,
                            response.data.maw_1_year_ago,
                            ]
                        }]
                    }
                    break;
                }
                case 'retail-energy-price-by-state':
                    this.format = CHART;
                    this.layer_title = `${response.data.state_name} Energy Price`;
                    this.data = {
                        chart: { animation: false},
                        credits: { enabled: false },
                        title: { text: null },
                        plotOptions: {
                            series: {animation: false}
                        },
                        xAxis: [{
                            categories: response.data.retail_energy_prices
                                            .map(d => d.sector)
                                            .filter(key => key !== 'All')
                                            .sort(),
                            crosshair: true,
                            title: { text: 'Sector' }
                        }],
                        yAxis: [{
                            labels: {
                                format: '{value}\u00A2',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: 'Cents per kWh',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            plotLines: [{
                                color: 'black',
                                value: parseFloat(response.data.retail_energy_prices.filter(d => d.sector === 'All')[0].cents_per_kilowatt_hour),
                                width: 2,
                                label: {
                                    text: `All sectors ${response.data.retail_energy_prices.filter(d => d.sector === 'All')[0].cents_per_kilowatt_hour.toFixed(3)}\u00A2`
                                },
                                zIndex: 5
                            }]
                        }],
                        tooltip: { shared: true },
                        series: [{
                            name: 'Sector Price',
                            type: 'column',
                            showInLegend: false,
                            yAxis: 0,
                            data: response.data.retail_energy_prices.filter(d => d.sector !== 'All')
                                                .sort((a, b) => {
                                                    if (a.sector < b.sector) {
                                                        return -1;
                                                    } else if (b.sector < a.sector) {
                                                        return 1;
                                                    } else {
                                                        return 0;
                                                    }
                                                })
                                                .map(d => parseFloat(d.cents_per_kilowatt_hour.toFixed(3))),
                            tooltip: { valueSuffix: '\u00A2' }
                        },
                    ]
                    };
                    break;
                case 'net-electricity-imports-by-state':
                    this.format = CHART;
                    this.layer_title = `${response.data.state_name} Net Electricity Imports`;
                    this.data = {
                        chart: { animation: false},
                        credits: { enabled: false },
                        title: { text: null },
                        plotOptions: {
                            series: {animation: false}
                        },
                        xAxis: [{
                            categories: ['Interstate', 'International'],
                            crosshair: true,
                            title: { text: 'Source' }
                        }],
                        yAxis: [{
                            labels: {
                                format: '{value}',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: 'Million kWh',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            plotLines: [{
                                color: 'black',
                                value: parseInt(response.data.total_million_kilowatt_hours),
                                width: 2,
                                label: {
                                    text: `Total ${response.data.total_million_kilowatt_hours} million kWh`
                                },
                                zIndex: 5
                            }]
                        }],
                        tooltip: { shared: true },
                        series: [{
                            name: 'Net Electricity Imports',
                            type: 'column',
                            showInLegend: false,
                            yAxis: 0,
                            data: [
                                response.data.interstate_million_kilowatt_hours,
                                response.data.international_million_kilowatt_hours
                            ],
                            tooltip: { valueSuffix: 'million kWh' }
                        },
                    ]
                    };
                    break;
                default:
                    return;
            }
            this.isOpen = true;
        }
    }
}
</script>

<style>
/*
This style is not scoped because v-data-table is a global object.
Every style defined here will be applied globally. No styles except
those that apply to the v-data-table should be applied here.
*/
    /* Alternating background color for table rows */
    tbody tr:nth-of-type(odd) {
        background-color: #f5f5f5;
    }
</style>