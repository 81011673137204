<template>
    <v-row>
        <v-col cols="4">
            {{ text }}
        </v-col>
        <v-col cols="3">
            <v-select
                outlined
                dense
                :items="[
                    {text: 'Not Relevant', value: 'Not Relevant'},
                    {text: 'Very Low', value: 'Very Low'},
                    {text: 'Low', value: 'Low'},
                    {text: 'Medium', value: 'Medium'},
                    {text: 'High', value: 'High'},
                    {text: 'Very High', value: 'Very High'}
                ]"
                :value="relevanceBin"
                @change="changeRelevanceBin"
                placeholder="Select"
            ></v-select>
        </v-col>
        <v-col>
            <v-slider
                v-show="relevance > 0"
                v-model="relevance"
                :max="sliderMax"
                :min="sliderMin"
                :tick-labels="sliderTicks"
            ></v-slider>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ComparisonRestrictionRelevanceItem",
    props: {
        index: Number,
        text: String
    },
    computed: {
        ...mapGetters(['getRestrictionRelevance']),
        relevance: {
            get() {
                return this.getRestrictionRelevance(this.index);
            },
            set(val){
                this.changeRestrictionRelevance({index: this.index, val});
            }
        },
        relevanceBin() {
            if (this.relevance > 40) {
                return 'Very High';
            } else if (this.relevance > 30) {
                return 'High';
            } else if (this.relevance > 20) {
                return 'Medium';
            } else if (this.relevance > 10) {
                return 'Low';
            } else if (this.relevance > 0) {
                return 'Very Low';
            } else {
                return 'Not Relevant';
            }
        },
        sliderMin() {
            if (this.relevance > 40) {
                return 46;
            } else if (this.relevance > 30) {
                return 36;
            } else if (this.relevance > 20) {
                return 26;
            } else if (this.relevance > 10) {
                return 16;
            } else if (this.relevance > 0) {
                return 6;
            } else {
                return 0;
            }
        },
        sliderMax() {
            if (this.relevance > 40) {
                return 50;
            } else if (this.relevance > 30) {
                return 40;
            } else if (this.relevance > 20) {
                return 30;
            } else if (this.relevance > 10) {
                return 20;
            } else if (this.relevance > 0) {
                return 10;
            } else {
                return 0;
            }
        },
    sliderTicks() {
        const ticks = [];
        for (let i=this.sliderMin; i <= this.sliderMax; i++) {
            if (i == this.sliderMin || i % 5 == 0) {
                ticks.push(i);
            } else {
                ticks.push(null);
            }
        }
        return ticks;
    }
    },
    methods: {
        ...mapActions(['changeRestrictionRelevance']),
        changeRelevanceBin(value) {
            switch(value){
                case 'Very High':
                    this.relevance = 48;
                    break;
                case 'High':
                    this.relevance = 38;
                    break;
                case 'Medium':
                    this.relevance = 28;
                    break;
                case 'Low':
                    this.relevance = 18;
                    break;
                case 'Very Low':
                    this.relevance = 8;
                    break;
                default:
                    this.relevance = 0;
                    break;
            }
        },
    },
}
</script>
