<template>
    <div class="wrapper">
        <v-stepper flat tile v-model="activeStep"
            height="100%" width="100%" color="primary">
            <v-stepper-header>
                <v-stepper-step
                    :complete="activeStep > 0"
                    step=0
                    @click="stepBack(0)"
                >
                    Process Overview
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="activeStep > 1"
                    step=1
                    @click="stepBack(1)"
                >
                    Review Sites
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="activeStep > 2"
                    step=2
                    @click="stepBack(2)"
                >
                    Relevance Form
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="activeStep > 3"
                    step=3
                    @click="stepBack(3)"
                >
                    Significance Form
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="activeStep > 4"
                    step=4
                    @click="stepBack(4)"
                >
                    AR–RS Matrix
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    step=5
                >
                    Results
                </v-stepper-step>
                <v-icon
                    v-show="activeStep > 0"
                    class="page-info"
                    x-large
                    color="primary"
                    @click="() => pageInfo = true"
                >mdi-information</v-icon>
            </v-stepper-header>
            <div class="flex-break"></div>
            <v-stepper-items>
                <v-stepper-content step=0>
                    <comparison-overview
                        :compareSites="() => activeStep = 1"
                    ></comparison-overview>
                </v-stepper-content>
                <v-stepper-content step=1>
                    <comparison-review-sites
                        :advance="() => activeStep = 2"
                    ></comparison-review-sites>
                </v-stepper-content>
                <v-stepper-content step=2>
                    <comparison-relevance-form
                        :advance="() => activeStep = 3"
                    ></comparison-relevance-form>
                </v-stepper-content>
                <v-stepper-content step=3>
                    <comparison-significance-form
                        :advance="() => activeStep = 4"
                    ></comparison-significance-form>
                </v-stepper-content>
                <v-stepper-content step=4>
                    <comparison-matrix
                        :advance="() => activeStep = 5"
                        :shown="activeStep == 4"
                    ></comparison-matrix>
                </v-stepper-content>
                <v-stepper-content step=5>
                    <comparison-results></comparison-results>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-dialog
            max-width="1000px"
            :value="mustRead || pageInfo"
            @click:outside="beenRead"
        >
            <comparison-page-info
                :activeStep="activeStep"
            ></comparison-page-info>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ComparisonReviewSites from './ComparisonReviewSites.vue';
import ComparisonRelevanceForm from './ComparisonRelevanceForm.vue';
import ComparisonSignificanceForm from './ComparisonSignificanceForm.vue';
import ComparisonResults from './ComparisonResults.vue';
import ComparisonOverview from './ComparisonOverview.vue';
import ComparisonPageInfo from './ComparisonPageInfo.vue';
import ComparisonMatrix from './ComparisonMatrix.vue';

export default {
    name: 'ComparisonPath',
    components: {
        ComparisonReviewSites,
        ComparisonRelevanceForm,
        ComparisonSignificanceForm,
        ComparisonResults,
        ComparisonOverview,
        ComparisonPageInfo,
        ComparisonMatrix,
    },
    data() {
        return {
            pageInfo: false,
            readRelevance: false,
            readSignificance: false
        }
    },
    computed: {
        ...mapGetters(['getComparisonStep']),
        activeStep: {
            get() {
                return this.getComparisonStep;
            },
            set(val) {
                this.changeComparisonStep(val);
            }
        },
        mustRead(){
            return (this.activeStep == 2 && !this.readRelevance) || (this.activeStep == 3 && !this.readSignificance)
        }
    },
    mounted() {
        this.restore();
    },
    methods: {
        ...mapActions(['restore','changeComparisonStep']),
        stepBack(val) {
            if (val < this.activeStep) {
                this.activeStep = val;
            }
        },
        beenRead() {
            this.pageInfo = false;
            if (this.activeStep == 2) {
                this.readRelevance = true;
            }
            if (this.activeStep == 3) {
                this.readSignificance = true;
            }
        }
    }
}
</script>


<style scoped>
    /* .wrapper {
        height: 100%;
    } */
    .page-info {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
    .v-sheet.v-stepper {
        background-color: #E3F2FD;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
    }
    .v-stepper__header {
        max-width: 65vw;
        min-width: 1000px;
        box-shadow: none;
    }
    .v-stepper__step--complete {
        cursor: pointer
    }
    .v-stepper__items {
        display: flex;
        flex-direction: column;
    }
    .v-stepper__content {
        width: 100vw;
        padding: 0;
    }
    .flex-break {
        flex-basis: 100%;
        height: 0;
    }
</style>