<template>
    <v-sheet id="list-container"
        outlined
        rounded
        elevation=1
    >
        <div class="text-h5 font-weight-bold primary--text">
            Top Matches
        </div>
        <div class="text-body-2">
            These are the top-ranking counties based on your priorities.
            Click a county name for details about its rank. Select up to
            five locations to analyze.
        </div>
        <v-data-table
            :headers="headers"
            multi-sort
            show-select
            :items="counties"
            sort-by="rank"
            dense
        >
            <template
                v-slot:[`header.data-table-select`]
            ></template>
            
            <template
                v-slot:[`item.data-table-select`]="{ item }"
            >
                <v-simple-checkbox
                    color="primary"
                    :ripple="false"
                    :disabled="maxCountySelectionReached && !isSelectedMatchCounty(item.id)"
                    :value="isSelectedMatchCounty(item.id)"
                    @click="selectCounty(item)"
                ></v-simple-checkbox>
            </template>
            <template
                v-slot:[`item.county_name`]="{ item }"
            >
                <span
                    class="text-decoration-underline primary--text"
                    @click="showCard(item.id)"
                >{{ item.county_name }}</span>
            </template>
            <template
                v-slot:[`item.rank`]="{ item }"
            >
                {{ item.rank }}
                <v-icon
                    small
                    @click="downloadReport(item.id)"
                >
                    mdi-download
                </v-icon>
            </template>
        </v-data-table>
        <div v-if="getSelectedMatchCounties.length" class="text-subtitle-2 font-weight-bold">Selected Counties:</div>
        <v-chip
            v-for="item in getSelectedMatchCounties"
            :item="item"
            :key="item.id"
            color="primary"
            close
            @click:close="selectCounty(item)"
        >{{ item.county_title }}</v-chip>
        <v-btn
            v-if="getSelectedMatchCounties.length"
            dark
            class="primary mb-2"
            width="100%"
            id="download-data"
            @click="downloadCountyData()"
        >Download Selected County Data</v-btn>
        <br/>
        <router-link to="/exploration/" class="text-decoration-none">
        <v-btn
            dark
            class="green"
            width="100%"
            id="explore"
        >Proceed to Site Exploration</v-btn>
        </router-link>
    </v-sheet>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { showSnackbar, showReportCard } from '@commons/Actions';
import {
    postDiscoverySelection,
    deleteDiscoverySelection,
    requestDiscoveryCountyReport
} from '@api/star/discovery';

export default {
    name: 'DiscoveryMatchList',
    data() {
        return {
            headers: [
                {
                    text: 'County',
                    value: 'county_name'
                },
                {
                    text: 'State',
                    value: 'state_name'
                },
                {
                    text: 'Rank',
                    align: 'right',
                    value: 'rank',
                    sort: (a, b) => parseInt(a) < parseInt(b) ? -1 : 1
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getPriorityMatchResults',
            'getPriorityMatchCounty',
            'getSelectedMatchCounties',
            'isSelectedMatchCounty',
            'maxCountySelectionReached',
            'getAccess'
        ]),
        counties() {
            if (this.getPriorityMatchResults) {
                return this.getPriorityMatchResults[1].map(d => ({
                    id: d.county_id_,
                    county_name: d.county_name_,
                    state_name: d.state_name_,
                    county_title: `${d.county_name_}, ${d.state_usps_abbreviation_}`,
                    rank: d.ranking_
                }));
            }
            else {
                return [];
            }
        }
    },
    methods: {
        ...mapActions(['changeSelectedMatchCounties', 'restore']),
        async downloadReport(county) {
            await this.restore();
            requestDiscoveryCountyReport(this.getAccess, county);
        },
        async selectCounty(item) {
            await this.restore();
            if (this.isSelectedMatchCounty(item.id)) {
                deleteDiscoverySelection(this.getAccess, item.id);
                this.changeSelectedMatchCounties(item);
            } else if (!this.maxCountySelectionReached) {
                postDiscoverySelection(this.getAccess, item.id);
                this.changeSelectedMatchCounties(item);
                if (this.maxCountySelectionReached) {
                    showSnackbar('Max county selections reached', 'warning', 3000);
                }
            } else {
                return;
            }
        },
        showCard(id) {
            showReportCard(id);
        },
        downloadCountyData() {
            const data = this.getSelectedMatchCounties.map(({id}) => {
                const raw_data = this.getPriorityMatchCounty(id);

                for (const key of Object.keys(raw_data)) {
                    let new_key;

                    switch (key) {
                        case 'cents_per_kilowatt_hour_':
                            new_key = 'State Electricity Price (cents/kwh)';
                            break;
                        case 'construction_maw_':
                            new_key = 'State Construction Mean Annual Wage (USD)';
                            break;
                        case 'county_id_':
                            new_key = 'County FIPS';
                            break;
                        case 'county_name_':
                            new_key = 'County Name';
                            break;
                        case 'has_nuclear_experienced_utility_':
                            new_key = 'County is Served by a Utility Related to an Entity with Nuclear Experience';
                            break;
                        case 'has_nuclear_facility_':
                            new_key = 'County has Operating Nuclear Facility';
                            break;
                        case 'has_nuclear_r_and_d_':
                            new_key = 'County is Home to Nuclear Research and Development';
                            break;
                        case 'has_retiring_generators_':
                            new_key = 'County is Home to Generators Entering Retirement';
                            break;
                        case 'is_net_importer_':
                            new_key = 'State is a Net Importer of Electricity';
                            break;
                        case 'is_preferred_market_':
                            new_key = 'State Electricity Market is Preferred Regulation Type';
                            break;
                        case 'policy_is_nuclear_inclusive_':
                            new_key = 'State Energy Policy is Nuclear Inclusive';
                            break;
                        case 'state_id_':
                            new_key = 'State FIPS';
                            break;
                        case 'state_name_':
                            new_key = 'State Name';
                            break;
                        case 'state_usps_abbreviation_':
                            new_key = 'State Abbreviation';
                            break;
                        case 'support_for_new_reactors_':
                            new_key = 'County Support for New Reactors (%)';
                            break;
                    }
                    
                    if (new_key) {
                        Object.defineProperty(raw_data, new_key, Object.getOwnPropertyDescriptor(raw_data, key));
                    }

                    delete raw_data[key];
                }

                return raw_data;
            });

            // const header = Object.keys(data[0]);
            const csv = 'data:text/csv;charset=utf-8,' + 
                Object.keys(data[0]).sort().join(',') + '\r\n' +
                data.map(d => Object.entries(d).sort((a, b) => {
                    if (a[0] < b[0]) {
                        return -1;
                    } else {
                        return 1;
                    }
                }).map(d => d[1]).join(',')).join('\r\n')

            const encoded_uri = encodeURI(csv);
            const link = document.createElement('a');
            link.setAttribute('href', encoded_uri);
            link.setAttribute('download', 'stand_selected_counties.csv');
            document.body.appendChild(link);
            link.click();

        }
    }
}
</script>


<style scoped>
    #list-container {
        padding: 20px;
    }
    .text-h5 {
        margin-bottom: 10px;
    }
    .v-chip {
        margin: 3px;
    }
</style>