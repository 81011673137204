var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relevance-item"},[_c('div',{staticClass:"text-subtitle-2 layer-title"},[_vm._v(" "+_vm._s(_vm.layerTitle)+" "),_c('sup',[_c('v-icon',{on:{"click":_vm.showInfo}},[_vm._v(" mdi-information-outline ")])],1)]),_c('v-row',[_c('v-col',[(_vm.hasNoDataSite)?_c('div',{staticClass:"text-subtitle-2 error--text"},[_vm._v(" Incomplete data coverage; layer unavailable. "),_c('sup',[_c('v-icon',{staticClass:"error--text",attrs:{"x-small":""},on:{"click":() => _vm.showNoDataInfo(true)}},[_vm._v(" mdi-information-outline ")])],1)]):_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                    {text: 'Not Relevant', value: 'Not Relevant'},
                    {text: 'Very Low', value: 'Very Low'},
                    {text: 'Low', value: 'Low'},
                    {text: 'Medium', value: 'Medium'},
                    {text: 'High', value: 'High'},
                    {text: 'Very High', value: 'Very High'}
                ],"value":_vm.relevanceBin,"placeholder":"Select"},on:{"change":_vm.changeRelevanceBin}})],1),_c('v-col',[_c('v-slider',{directives:[{name:"show",rawName:"v-show",value:(_vm.relevance > 0),expression:"relevance > 0"}],attrs:{"max":_vm.sliderMax,"min":_vm.sliderMin,"tick-labels":_vm.sliderTicks},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1),(!_vm.hasNoDataSite)?_vm._t("options"):_vm._e(),(_vm.noRefMap)?_c('div',{staticClass:"text-caption text-start"},[_vm._v("Reference Map Not Available")]):_c('div',{staticClass:"ref-map-link text-caption text-decoration-underline text-start",on:{"click":_vm.showMap}},[_vm._v("View "+_vm._s(_vm.layerTitle)+" Reference Map")]),_c('v-dialog',{attrs:{"width":"500"},on:{"click:outside":() => () => _vm.showNoDataInfo(false)},model:{value:(_vm.noDataInfo),callback:function ($$v) {_vm.noDataInfo=$$v},expression:"noDataInfo"}},[_c('v-card',[_c('v-card-title',{staticClass:"error--text",attrs:{"dark":""}},[_vm._v("Incomplete Data Coverage")]),_c('v-card-text',[_vm._v(" This layer does not cover "+_vm._s(_vm.noDataAreas ? _vm.noDataAreas.join(' or ') : null)+" and has been removed to maintain balanced weighting across all sites. To include this layer for comparison, delete all sites in "+_vm._s(_vm.noDataAreas ? _vm.noDataAreas.join(' and ') : null)+". ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }