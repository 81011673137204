const state = {
    map_zoom: null,
    feature_info: null,
    counties_geo: {order: 6, visible: false, wms_layer:'tiger:counties', max_zoom: 6},
    substations_geo: {order: 0, visible: false, wms_layer: 'hifld:substations', max_zoom: 8},
    facility_generation_geo: {order: 1, visible: false, wms_layer: 'eia:facility_generation', max_zoom: 8},
    transmission_lines_geo: {order: 1, visible: false, wms_layer: 'hifld:transmission_lines', max_zoom: 8},
    ers_territories_geo: {order: 1, visible: false, wms_layer: 'hifld:electric_retail_service_territories', max_zoom: 8},
    nerc_regions_geo: {order: 6, visible: false, wms_layer: 'hifld:nerc_regions', max_zoom: 0},
    fault_lines_geo: {order: 2, visible: false, wms_layer: 'ornl:fault_lines', max_zoom: -Infinity},
    major_roads_geo: {order: 2, visible: false, wms_layer: 'hifld:major_roads', max_zoom: 10},
    navigable_waterways_geo: {order: 2, visible: false, wms_layer: 'hifld:navigable_waterways', max_zoom: 8},
    rail_lines_geo: {order: 2, visible: false, wms_layer: 'hifld:rail_lines', max_zoom: 10},
    cejst_climate_geo: {order: 3, visible: false, wms_layer: 'cejst:climate', max_zoom: 6},
    cejst_energy_geo: {order: 3, visible: false, wms_layer: 'cejst:energy', max_zoom: 6},
    cejst_transit_geo: {order: 3, visible: false, wms_layer: 'cejst:transit', max_zoom: 6},
    cejst_housing_geo: {order: 3, visible: false, wms_layer: 'cejst:housing', max_zoom: 6},
    cejst_pollution_geo: {order: 3, visible: false, wms_layer: 'cejst:pollution', max_zoom: 6},
    cejst_water_geo: {order: 3, visible: false, wms_layer: 'cejst:water', max_zoom: 6},
    cejst_health_geo: {order: 3, visible: false, wms_layer: 'cejst:health', max_zoom: 6},
    cejst_workforce_geo: {order: 3, visible: false, wms_layer: 'cejst:workforce', max_zoom: 6},
    american_indian_reservations_geo: {order: 3, visible: false, wms_layer: 'ornl:american_indian_reservations', max_zoom: 6},
    critical_habitat_geo: {order: 3, visible: false, wms_layer: 'fws:critical_habitat', max_zoom: 6},
    correctional_facilities_geo: {order: 3, visible: false, wms_layer: 'ornl:correctional_facilities', max_zoom: 6},
    forests_geo: {order: 3, visible: false, wms_layer: 'ornl:forests', max_zoom: 6},
    hospitals_geo: {order: 3, visible: false, wms_layer: 'ornl:hospitals', max_zoom: 6},
    national_monuments_geo: {order: 3, visible: false, wms_layer: 'ornl:national_monuments', max_zoom: 6},
    national_state_and_local_parks_geo: {order: 3, visible: false, wms_layer: 'ornl:national_state_and_local_parks', max_zoom: 6},
    schools_colleges_geo: {order: 3, visible: false, wms_layer: 'ornl:schools_colleges', max_zoom: 6},
    wild_and_scenic_rivers_geo: {order: 3, visible: false, wms_layer: 'ornl:wild_and_scenic_rivers', max_zoom: 6},
    wilderness_areas_geo: {order: 3, visible: false, wms_layer: 'ornl:wilderness_areas', max_zoom: 6},
    wildlife_refuges_geo: {order: 3, visible: false, wms_layer: 'ornl:wildlife_refuges', max_zoom: 6},
    food_industry_geo: {order: 3, visible: false, wms_layer: 'ornl:food_industry_1mi', max_zoom: 10},
    manufacturing_geo: {order: 3, visible: false, wms_layer: 'ornl:manufacturing_1mi', max_zoom: 10},
    mining_geo: {order: 3, visible: false, wms_layer: 'ornl:mining_1mi', max_zoom: 10},
    airports_geo: {order: 3, visible: false, wms_layer: 'ornl:airports_5mi_buffer', max_zoom: 6},
    biodiesel_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:biodiesel_plants_5mi_buffer', max_zoom: 6},
    biological_products_mfg_geo: {order: 3, visible: false, wms_layer: 'ornl:biological_products_manufacturing_5mi_buffer', max_zoom: 6},
    chemical_mfg_geo: {order: 3, visible: false, wms_layer: 'ornl:chemical_manufacturing_5mi_buffer', max_zoom: 6},
    ethanol_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:ethanol_plants_5mi_buffer', max_zoom: 6},
    explosives_mfg_geo: {order: 3, visible: false, wms_layer: 'ornl:explosives_manufacturing_5mi_buffer', max_zoom: 6},
    liquified_ng_import_terminals_geo: {order: 3, visible: false, wms_layer: 'ornl:liquified_ng_import_terminals_5mi_buffer', max_zoom: 6},
    lubricating_oils_and_grease_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:lubricating_oils_and_grease_plants_5mi_buffer', max_zoom: 6},
    ng_compressor_stations_geo: {order: 3, visible: false, wms_layer: 'ornl:ng_compressor_stations_5mi_buffer', max_zoom: 6},
    ng_import_export_locations_geo: {order: 3, visible: false, wms_layer: 'ornl:ng_import_export_locations_5mi_buffer', max_zoom: 6},
    ng_processing_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:ng_processing_plants_5mi_buffer', max_zoom: 6},
    ng_storage_facilities_geo: {order: 3, visible: false, wms_layer: 'ornl:ng_storage_facilities_5mi_buffer', max_zoom: 6},
    nitrogenous_fertilizer_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:nitrogenous_fertilizer_plants_5mi_buffer', max_zoom: 6},
    nuclear_fuel_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:nuclear_fuel_plants_5mi_buffer', max_zoom: 6},
    oil_refineries_geo: {order: 3, visible: false, wms_layer: 'ornl:oil_refineries_5mi_buffer', max_zoom: 6},
    petroleum_pumping_stations_geo: {order: 3, visible: false, wms_layer: 'ornl:petroleum_pumping_stations_5mi_buffer', max_zoom: 6},
    pharmaceutical_preparations_mfg_geo: {order: 3, visible: false, wms_layer: 'ornl:pharmaceutical_preparations_manufacturing_5mi_buffer', max_zoom: 6},
    phosphatic_fertilizer_plants_geo: {order: 3, visible: false, wms_layer: 'ornl:phosphatic_fertilizer_plants_5mi_buffer', max_zoom: 6},
    pol_terminals_storage_facilities_tank_farms_geo: {order: 3, visible: false, wms_layer: 'ornl:pol_terminals_storage_facilities_tank_farms_5mi_buffer', max_zoom: 6},
    landslide_hazard_geo: {order: 3, visible: false, wms_layer: 'ornl:landslide_hazards', max_zoom: -Infinity},
    population_2018_2mi_geo: {order: 4, visible: false, wms_layer: 'ornl:population_2018_2mi', max_zoom: 6},
    population_2018_5mi_geo: {order: 4, visible: false, wms_layer: 'ornl:population_2018_5mi', max_zoom: 6},
    population_2018_10mi_geo: {order: 4, visible: false, wms_layer: 'ornl:population_2018_10mi', max_zoom: 6},
    population_2030_2mi_geo: {order: 5, visible: false, wms_layer: 'ornl:population_2030_2mi', max_zoom: 6},
    population_2030_5mi_geo: {order: 5, visible: false, wms_layer: 'ornl:population_2030_5mi', max_zoom: 6},
    population_2030_10mi_geo: {order: 5, visible: false, wms_layer: 'ornl:population_2030_10mi', max_zoom: 6},
    sse_3_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_03g', max_zoom: -Infinity},
    sse_4_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_04g', max_zoom: -Infinity},
    sse_5_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_05g', max_zoom: -Infinity},
    sse_6_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_06g', max_zoom: -Infinity},
    sse_65_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_6o5g', max_zoom: -Infinity},
    sse_10_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_10g', max_zoom: -Infinity},
    sse_15_geo: {order: 5, visible: false, wms_layer: 'ornl:safe_shutdown_earthquake_15g', max_zoom: -Infinity},
    floodplain_100yrs_geo: {order: 5, visible: false, wms_layer: 'ornl:floodplain_100yrs,ornl:HI_100yrFlood', max_zoom: 8},
    slope_gt12pct_geo: {order: 5, visible: false, wms_layer: 'ornl:slope_gt12pct,ornl:AK_EAST_slope12,ornl:AK_WEST_slope12,ornl:HI_slope12', max_zoom: 8},
    slope_gt18pct_geo: {order: 5, visible: false, wms_layer: 'ornl:slope_gt18pct,ornl:AK_EAST_slope18,ornl:AK_WEST_slope18,ornl:HI_slope18', max_zoom: 8},
    streamflow_lt15kgpm_geo: {order: 5, visible: false, wms_layer: 'ornl:streamflow_15k_gpm', max_zoom: 8},
    streamflow_lt50kgpm_geo: {order: 5, visible: false, wms_layer: 'ornl:streamflow_50k_gpm', max_zoom: 8},
    streamflow_lt65kgpm_geo: {order: 5, visible: false, wms_layer: 'ornl:streamflow_65k_gpm', max_zoom: 8},
    wetlands_openwaters_geo: {order: 5, visible: false, wms_layer: 'ornl:wetlands_openwaters', max_zoom: 6},
};

const getters = {
    getFeatureInfo: state => state.feature_info,
    getFeatureInfoKeys: state => state.feature_info ? Object.keys(state.feature_info).sort() : '',
    getLayerObject: state => layer => state[layer],
    getLayerString: state => layer => state[layer].wms_layer,
    getLayerVisibility: state => layer => state[layer].visible,
    isLayerDisabled: state => layer => state[layer].max_zoom > state.map_zoom,
    getCountVisible: state => {
        return Object.entries(state)
                        .filter(kv => /(?<!counties)_geo$/.test(kv[0]) && kv[1].visible)
                        .length;
    },
    getLayersString: state => {
        return Object.entries(state)
                        .filter(kv => /_geo$/.test(kv[0]))
                        .map(kv => kv[1])
                        .filter(d => d.visible && d.max_zoom <= state.map_zoom)
                        .sort((a, b) => b.order - a.order)
                        .map(d => d.wms_layer)
                        .join();
    },
    getInfoLayersString: state => {
        return Object.entries(state)
                        .filter(kv => kv[0] != 'map_zoom')
                        .filter(kv => [
                            'substations_geo', 'facility_generation_geo',
                            'transmission_lines_geo', 'ers_territories_geo',
                            'major_roads_geo', 'navigable_waterways_geo',
                            'rail_lines_geo', 'nerc_regions_geo',
                            'cejst_climate_geo','cejst_energy_geo',
                            'cejst_transit_geo','cejst_housing_geo','cejst_pollution_geo',
                            'cejst_water_geo','cejst_health_geo','cejst_workforce_geo'
                        ].includes(kv[0]))
                        .map(kv => kv[1])
                        .filter(d => d.visible && d.max_zoom <= state.map_zoom)
                        .sort((a, b) => b.order - a.order)
                        .map(d => d.wms_layer)
                        .join();
    },
};

const actions = {
    changeMapZoom: ({ commit }, level) => commit('setMapZoom', level),
    changeFeatureInfo: ({ commit }, data) => {
        if (!data || !data.features.length) {
            commit('setFeatureInfo', null);
        } else {
            const featureInfo = {};
            
            const energy = data.features.filter(d => /^energy/.test(d.id));
            if (energy.length) {
                featureInfo['Clean energy and energy efficiency'] = energy.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Energy Cost': d.properties['Energy Cost'],
                    'PM2.5 in the Air': d.properties['PM2.5 in the Air'],
                    'Low Income': d.properties['Low Income']
                }));
            }
            
            const transit = data.features.filter(d => /^transit/.test(d.id));
            if (transit.length) {
                featureInfo['Clean transit'] = transit.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Diesel Particulate Matter Exposure': d.properties['Diesel Particulate Matter Exposure'],
                    'Transportation Barriers': d.properties['Transportation Barriers'],
                    'Traffic Proximity and Volume': d.properties['Traffic Proximity and Volume'],
                    'Low Income': d.properties['Low Income']
                }));
            }
            
            const water = data.features.filter(d => /^water/.test(d.id));
            if (water.length) {
                featureInfo['Clean water and wastewater infrastructure'] = water.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Wastewater Discharge': d.properties['Wastewater Discharge'],
                    'Underground Storage Tanks and Releases': d.properties['Underground Storage Tanks and Releases'],
                    'Low Income': d.properties['Low Income']
                }));
            }
            
            const climate = data.features.filter(d => /^climate/.test(d.id));
            if (climate.length) {
                featureInfo['Climate change'] = climate.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Expected Agricultural Loss Rate': d.properties['Expected Agricultural Loss Rate'],
                    'Expected Building Loss Rate': d.properties['Expected Building Loss Rate'],
                    'Expected Population Loss Rate': d.properties['Expected Population Loss Rate'],
                    'Projected Wildfire Risk': d.properties['Projected Wildfire Risk'],
                    'Projected Flood Risk': d.properties['Projected Flood Risk'],
                    'Low Income': d.properties['Low Income'],
                }));
            }
            
            const facilities = data.features.filter(d => /^facility_generation/.test(d.id));
            if (facilities.length) {
                featureInfo['Electric Energy Generators'] = facilities.map(d => ({
                    "Facility": d.properties.facility_name,
                    "Utility": d.properties.utility_name,
                    "Energy Source": d.properties.energy_source,
                    "Total Nameplate Capacity (MW)": d.properties.total_nameplate_capacity_megawatts,
                }));
            }

            const serviceTerritories = data.features.filter(d => /^electric_retail_service_territories/.test(d.id));
            if (serviceTerritories.length) {
                featureInfo['Electric Retail Service Territories'] = serviceTerritories.map(d => ({
                    "Address": d.properties.address,
                    "City": d.properties.city,
                    "Control Area": d.properties.cntrl_area,
                    "Customers": d.properties.customers,
                    "Name": d.properties.name,
                    "Plan Area": d.properties.plan_area,
                    "Purchased": d.properties.purchased,
                    "Regulated": d.properties.regulated,
                    "Retail MWh": d.properties.retail_mwh,
                }));
            }

            const health = data.features.filter(d => /^health/.test(d.id));
            if (health.length) {
                featureInfo['Health burdens'] = health.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Asthma': d.properties['Asthma'],
                    'Diabetes': d.properties['Diabetes'],
                    'Heart Disease': d.properties['Heart Disease'],
                    'Low Life Expectancy': d.properties['Low Life Expectancy'],
                    'Low Income': d.properties['Low Income']
                }));
            }
            
            const pollution = data.features.filter(d => /^pollution/.test(d.id));
            if (pollution.length) {
                featureInfo['Legacy pollution'] = pollution.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Abandoned Mine Land': d.properties['Abandoned Mine Land'],
                    'Formerly Used Defense Sites': d.properties['Formerly Used Defense Sites'],
                    'Proximity to Hazardous Waste Facilities': d.properties['Proximity to Hazardous Waste Facilities'],
                    'Proximity to Risk Management Plan Facilities': d.properties['Proximity to Risk Management Plan Facilities'],
                    'Proximity to Superfund Sites': d.properties['Proximity to Superfund Sites'],
                    'Low Income': d.properties['Low Income']
                }));
            }
            
            const majorRoads = data.features.filter(d => /^major_roads/.test(d.id));
            if (majorRoads.length) {
                featureInfo['Major Roads'] = majorRoads.map(d => ({
                    "Name": d.properties.name,
                }));
            }

            const navigableWaterways = data.features.filter(d => /^navigable_waterways/.test(d.id));
            if (navigableWaterways.length) {
                featureInfo['Navigable Waterways'] = navigableWaterways.map(d => ({
                    "Name": d.properties.linkname,
                    "Type": d.properties.linktype,
                    "Heading": d.properties.heading,
                    "Controlling Depth": d.properties.ctrl_depth
                }));
            }

            const nercRegions = data.features.filter(d => /^nerc_regions/.test(d.id));
            if (nercRegions.length) {
                featureInfo['NERC Regions'] = nercRegions.map(d => ({
                    "Name": d.properties.nerc_label,
                }));
            }
            
            const railLines = data.features.filter(d => /^rail_lines/.test(d.id));
            if (railLines.length) {
                featureInfo['Rail Lines'] = railLines.map(d => ({
                    "Name": d.properties.name,
                }));
            }

            const substations = data.features.filter(d => /^substations/.test(d.id));
            if (substations.length) {
                featureInfo['Substations'] = substations.map(d => ({
                    "City": d.properties.city,
                    "County": d.properties.county,
                    "State": d.properties.state,
                    "Zip": d.properties.zip,
                    "Lines": d.properties.lines,
                    "Max Voltage": d.properties.max_volt,
                    "Min Voltage": d.properties.min_volt,
                    "Status": d.properties.status,
                    "Max Voltage Inferred": d.properties.max_infer,
                    "Min Voltage Inferred": d.properties.min_infer,
                }));
            }

            const housing = data.features.filter(d => /^housing/.test(d.id));
            if (housing.length) {
                featureInfo['Sustainable housing'] = housing.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Housing Cost': d.properties['Housing Cost'],
                    'Lack of Green Space': d.properties['Lack of Green Space'],
                    'Lack of Indoor Plumbing': d.properties['Lack of Indoor Plumbing'],
                    'Lead Paint': d.properties['Lead Paint'],
                    'Low Income': d.properties['Low Income']
                }));
            }
            
            const transmissionLines = data.features.filter(d => /^transmission_lines/.test(d.id));
            if (transmissionLines.length) {
                featureInfo['Transmission Lines'] = transmissionLines.map(d => ({
                    "Owner": d.properties.owner,
                    "Status": d.properties.status,
                    "Substation 1": d.properties.sub_1,
                    "Substation 2": d.properties.sub_2,
                    "Type": d.properties.type,
                    "Voltage Class": d.properties.volt_class,
                    "Voltage": d.properties.voltage,
                }));
            }

            const workforce = data.features.filter(d => /^workforce/.test(d.id));
            if (workforce.length) {
                featureInfo['Workforce development'] = workforce.map(d => ({
                    'Census Tract': d.properties['Census Tract'],
                    'State': d.properties.state,
                    'County': d.properties.county,
                    'Linguistic Isolation': d.properties['Linguistic Isolation'],
                    'Low Median Income': d.properties['Low Median Income'],
                    'Unemployment': d.properties['Unemployment'],
                    'Poverty': d.properties['Poverty'],
                    'High School Education': d.properties['High School Education']
                }));
            }

            commit('setFeatureInfo', featureInfo);
        }
    },
    changeLayerVisibility: ({ commit }, layer) => commit('setLayerVisibility', layer),
};

const mutations = {
    setMapZoom: (state, level) => state.map_zoom = level,
    setFeatureInfo: (state, data) => state.feature_info = data,
    setLayerVisibility: (state, layer) => {
        const visible = !state[layer].visible;
        const newLayerObject = {
            ...state[layer],
            visible
        };
        state[layer] = newLayerObject;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}