<template>
    <v-row justify="center">
        <v-dialog
            max-width="20vw"
            persistent
            :value="!description && isOpen"
        >
            <v-card>
                <v-card-text>
                    <div>Fetching {{ layerTitle }} info...</div>
                    <v-progress-linear
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            :value="description && isOpen"
            @click:outside="close"
            max-width="600px"
        >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ layerTitle }}</span>
        </v-card-title>
        <v-card-text>
            <div v-if="why_it_matters" class="text-subtitle-2 font-weight-bold text-uppercase">
                Why It Matters
            </div>
            <div v-if="why_it_matters" class="text-body-2">
                {{ why_it_matters }}
            </div>
            <div class="text-subtitle-2 font-weight-bold text-uppercase">
                Definition
            </div>
            <div class="text-body-2" style="white-space: break-spaces">{{description}}</div>
            <div class="text-overline font-weight-bold">
                References
            </div>
            <div v-for="(value, name) in references" :key="name">
                <a
                    class="text-caption text-decoration-none font-weight-bold"
                    :href="`${value}`"
                    target="_blank"
                >{{ name }}</a>
            </div>
            <v-divider></v-divider>
            <div v-if="data_currency" class="text-caption">
                {{ data_currency }}. Last update {{ last_update }}
            </div>
            <div v-else class="text-caption">
                No update plan for this dataset
            </div>
        </v-card-text>
      </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { requestLayerInfo } from '@api/layerInfo';
import { showSnackbar } from "@commons/Actions";
import EventBus, { ACTIONS } from '@commons/EventBus';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'LayerInfo',
    computed: {
        ...mapGetters(['getAccess'])
    },
    data() {
        return {
            isOpen: false,
            layerTitle: null,
            description: null,
            why_it_matters: null,
            references: null,
            data_currency: null,
            last_update: null
        }
    },
    mounted() {
        
    EventBus.$on(ACTIONS.LAYER_INFO, (layer, tool, segment, layerTitle) => {
      this.setLayerInfo(layer, tool, segment);
      this.layerTitle = layerTitle;
    });
      
    },
    methods: {
        ...mapActions(['restore']),
        close() {
            this.isOpen = false;
            this.description = null;
            this.why_it_matters = null;
            this.references = null;
            this.data_currency = null;
            this.last_update = null;
        },
        async setLayerInfo(layer, tool, segment) {
            this.isOpen = true;
            await this.restore();
            
            const response = await requestLayerInfo(this.getAccess, layer, tool);

            if (response.status === 200) {
                this.description = response.descriptions[segment] || response.descriptions.All;
                this.why_it_matters = response.why_it_matters;
                this.references = response.layer_references;
                this.data_currency = response.data_currency;
                this.last_update = response.last_update;
            } else {
                this.isOpen = false;
                showSnackbar('Error fetching layer info.', 'error', 3000);
            }
        }
    }
}
</script>

<style scoped>
    .text-subtitle-2, .text-overline, .v-divider {
        margin-top: 0.5rem;
    }
</style>