<template>
    <div>
        <v-card id="billboard" color="primary" dark flat tile>
            <v-card-title class="justify-center text-h4 font-weight-bold">Meet STAND</v-card-title>
            <v-card-subtitle class="text-center text-h6">Siting Tool for Advanced Nuclear Development</v-card-subtitle>
            <v-card-text class="text-center text-body-1">
                Use STAND to identify and examine potentially feasible sites where advanced nuclear facilities might
                be welcomed by host communities. STAND is designed to explore and provide insight on socioeconomic,
                proximity, and safety data, generate county reports, review regulatory data, and complete a comparative
                analysis across multiple sites. However, STAND is not a substitute for the in-depth studies required to
                qualify a site for hosting a nuclear facility, nor is it a substitute for the necessary community engagement
                to build trust and seek consent.
            </v-card-text>
        </v-card>
        <div class="home-container">
            <div class="home-header text-h5 font-weight-bold">Get Started</div>
            <v-row>
                <v-col>
                    <v-card color="blue lighten-5" class="get-started-card" to="/discovery/">
                        <v-img height="80" :contain="true" :src="`${publicPath}icon_discovery.svg`"></v-img>
                        <v-card-title class="justify-center font-weight-bold">Site Discovery</v-card-title>
                        <v-card-text class="text-center text-body-2">
                            Start here if you want to identify counties or states that may be
                            candidates for reactor deployment.
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card color="blue lighten-5" class="get-started-card" to="/exploration/">
                        <v-img height="80" :contain="true" :src="`${publicPath}icon_exploration.svg`"></v-img>
                        <v-card-title class="justify-center font-weight-bold">Site Exploration</v-card-title>
                        <v-card-text class="text-center text-body-2">
                            Start here if you have already identified general areas for deployment
                            but would like to explore regulatory data or drop points.
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card color="blue lighten-5" class="get-started-card" to="/comparison/">
                        <v-img height="80" :contain="true" :src="`${publicPath}icon_comparison.svg`"></v-img>
                        <v-card-title class="justify-center font-weight-bold">Site Comparison</v-card-title>
                        <v-card-text class="text-center text-body-2">
                            Start here if you have identified site coordinates for deployment and
                            would like to compare them against each other.
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="home-container">
            <div class="home-header">
                <span class="text-h5 font-weight-bold">Documentation</span>
                <span class="text-caption">Review these files for guidance on how to use STAND:</span>
            </div>
            <v-sheet id="downloads-sheet" outlined rounded>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col cols="1"><v-icon color="grey darken-4" right>mdi-download</v-icon></v-col>
                            <v-col>
                                <a download :href="`${publicPath}STAND Quickstart Guide.pdf`"
                                class="text-decoration-underline text-subtitle-1 font-weight-bold"
                                >
                                    Quickstart Guide (PDF)
                                </a>
                                <div class="text-body-2">
                                    Download this visual guide to begin using STAND immediately.
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-col cols="1"><v-icon color="grey darken-4" right>mdi-download</v-icon></v-col>
                            <v-col>
                                <!-- <a download href=""> -->
                                <a download :href="`${publicPath}STAND Slides For Usage.pdf`"
                                class="text-decoration-underline text-subtitle-1 font-weight-bold"
                                >
                                    Slides for Usage (PDF)
                                </a>
                                <div class="text-body-2">
                                    Step-by-step directions for how to use STAND.
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-col cols="1"><v-icon color="grey darken-4" right>mdi-download</v-icon></v-col>
                            <v-col>
                                <!-- <a download href=""> -->
                                <a download :href="`${publicPath}stand_release_notes.csv`"
                                class="text-decoration-underline text-subtitle-1 font-weight-bold"
                                >
                                    Release Notes
                                </a>
                                <div class="text-body-2">
                                    Notable changes to the appearance, functionality, or data of STAND.
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
    </div>
</template>

<script>
// import { requestQuickstartGuide } from '@api/star/home.js';
// import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'HomeScreen',
    data: () => ({
        publicPath: process.env.BASE_URL
    }),
    // computed: {
    //     ...mapGetters(['getAccess'])
    // },
    // methods: {
    //     ...mapActions(['restore']),
    //     async downloadQuickstart() {
    //         await this.restore()
    //         requestQuickstartGuide(this.getAccess);
    //     }
    // }
}
</script>


<style scoped>
    #billboard {
        padding: 40px 25%;
    }
    .home-container {
        padding: 20px 80px;
    }
    .home-header {
        padding-bottom: 20px;
    }
    .home-header>span {
        display:inline-block;
    }
    .home-header>span:first-child {
        padding-right: 10px;
    }
    .get-started-card {
        padding: 10%;
        min-width: 300px;
        height: 100%;
    }
    #downloads-sheet {
        padding: 50px;
    }
    #downloads-sheet>.row>.col {
        min-width: 250px;
    }
</style>