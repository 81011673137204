<template>
    <v-row justify="center">
        <v-dialog
            v-model="isOpen"
      max-width="800px"
      @click:outside="close"
    >
      <v-card v-if="match_data">
        <v-card-title>
          <span class="text-h5">
            {{ title }}
          </span>
        </v-card-title>
        <v-card-text>
          <div id="dev-score">
            <span class="text-subtitle-2 font-weight-bold text-uppercase">
                Development Rank: {{ rank }}
            </span>
          </div>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="match_data"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
            :value="isSelectedMatchCounty(county_id)"
            :disabled="maxCountySelectionReached && !isSelectedMatchCounty(county_id)"
            @click="selectCounty(item)"
            label="Select County"
          ></v-checkbox>
          <v-btn
            @click="downloadReport(county_id)"
          >
            <v-icon>mdi-download</v-icon>
            Download County Report
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>{{ title }} Deal Breakers</v-card-title>
        <v-card-text>
            <div class="deal-breaker"
              v-for="(item, i) in deal_breakers"
              :key="i"
            >
            <v-icon color="error">mdi-close-octagon</v-icon>
            <div>{{ item }}</div>
            </div>
        </v-card-text>
      </v-card>
        </v-dialog>
    </v-row>
</template>


<script>
import { requestLayerData } from "@api/layerData";
import {
    postDiscoverySelection,
    deleteDiscoverySelection,
    requestDiscoveryCountyReport
} from '@api/star/discovery';
import { showSnackbar } from "@commons/Actions";
import EventBus, { ACTIONS } from '@commons/EventBus';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'LayerInfo',
    computed: {
        ...mapGetters([
          'getAccess',
          'getEnergyPrice',
          'getMarketRegulation',
          'getPreferredMarket',
          'getNuclearInclusivePolicy',
          'getNuclearSentiment',
          'getOperatingNuclearFacilities',
          'getRetiringGeneratorTechnologies',
          'getMinYearsToRetirement',
          'getMaxYearsToRetirement',
          'getGeneratorRetirement',
          'getLaborRate',
          'getNuclearExperiencedUtility',
          'getNetElectricityImports',
          'getProximityToNuclearRAndD',
          'getPriorityMatchCounty',
          'getNuclearRestrictions',
          'isNuclearRestrictionDealBreaker',
          'getSelectedMatchCounties',
          'maxCountySelectionReached',
          'isSelectedMatchCounty',
        ])
    },
    data() {
        return {
            isOpen: false,
            title: null,
            rank: null,
            match_data: null,
            deal_breakers: null,
            county_id: null,
            headers: [
              {
                text: 'Attribute',
                value: 'attribute',
                sortable: false
              },
              {
                text: 'Priority',
                value: 'priority',
                sortable: false
              },
              {
                text: 'Value',
                value: 'value',
                sortable: false
              }
            ]
        }
    },
    mounted() {
        
      EventBus.$on(ACTIONS.REPORT_CARD, (id, dealBreakers) => {
        this.setReportCardData(id, dealBreakers);
      });
      
    },
    methods: {
        ...mapActions(['changeSelectedMatchCounties', 'restore']),
        close() {
            this.isOpen = false;
            this.match_data = null;
            this.deal_breakers = null;
        },
        async downloadReport(county) {
            await this.restore();
            requestDiscoveryCountyReport(this.getAccess, county);
        },
        async selectCounty() {
            await this.restore();
            if (this.isSelectedMatchCounty(this.county_id)) {
                deleteDiscoverySelection(this.getAccess, this.county_id);
                this.changeSelectedMatchCounties({id: this.county_id, county_title: this.title});
            } else if (!this.maxCountySelectionReached) {
                postDiscoverySelection(this.getAccess, this.county_id);
                this.changeSelectedMatchCounties({id: this.county_id, county_title: this.title});
                if (this.maxCountySelectionReached) {
                    showSnackbar('Max county selections reached', 'warning', 3000);
                }
            } else {
                return;
            }
        },
        async setReportCardData(id, dealBreakers) {
          if (dealBreakers) {
            await this.restore();
            const stateNuclearRestrictions = await requestLayerData(this.getAccess, 'nuclear-restriction-by-state', id);
            if (stateNuclearRestrictions.status == 200) {
              this.title = stateNuclearRestrictions.data.state_name;
              this.deal_breakers = stateNuclearRestrictions.data.nuclear_restrictions
                .filter((d, i) => this.isNuclearRestrictionDealBreaker(`${stateNuclearRestrictions.data.nuclear_restriction_ids[i]}`));
            } else {
              showSnackbar('Error downloading dealbreaker data', 'error', 3000);
            }
          } else {
              const countyMatch = this.getPriorityMatchCounty(id);
              this.title = `${countyMatch.county_name_}, ${countyMatch.state_usps_abbreviation_}`
              this.rank = countyMatch.ranking_;
              this.county_id = countyMatch.county_id_
              const priorityText = i => {
                switch (i) {
                  case 0:
                    return 'Not Important';
                  case 1:
                    return 'Very Low';
                  case 2:
                    return 'Low';
                  case 3:
                    return 'Medium';
                  case 4:
                    return 'High';
                  case 5:
                    return 'Very High';
                  default:
                    return 'ERROR';
                }
              }
              this.match_data = [
                {
                  attribute: 'Relatively high energy price',
                  priority: priorityText(this.getEnergyPrice),
                  value: `${countyMatch.cents_per_kilowatt_hour_.toFixed(3)}${String.fromCharCode(162)}/kwH`
                },
                {
                  attribute: `Preferred market regulation\n(${this.getPreferredMarket})`,
                  priority: priorityText(this.getMarketRegulation),
                  value: countyMatch.is_preferred_market_
                },
                {
                  attribute: 'Energy policy is nuclear inclusive',
                  priority: priorityText(this.getNuclearInclusivePolicy),
                  value: countyMatch.policy_is_nuclear_inclusive_
                },
                {
                  attribute: 'Positive nuclear sentiment',
                  priority: priorityText(this.getNuclearSentiment),
                  value: !countyMatch.support_for_new_reactors_ ? 'N/A': `${countyMatch.support_for_new_reactors_.toFixed(3)}%`,
                },
                {
                  attribute: 'Home to operating nuclear facilities',
                  priority: priorityText(this.getOperatingNuclearFacilities),
                  value: countyMatch.has_nuclear_facility_
                },
                {
                  attribute: `Home to retiring/retired generators
                              \n(${this.getRetiringGeneratorTechnologies.join(', ')} 
                              ${this.getMinYearsToRetirement}-${this.getMaxYearsToRetirement} years from now)`,
                  priority: priorityText(this.getGeneratorRetirement),
                  value: countyMatch.has_retiring_generators_
                },
                {
                  attribute: 'Low mean annual wage for construction labor (five year average)',
                  priority: priorityText(this.getLaborRate),
                  value: `$${countyMatch.construction_maw_}`
                },
                {
                  attribute: 'Served by a utility that has nuclear experience',
                  priority: priorityText(this.getNuclearExperiencedUtility),
                  value: countyMatch.has_nuclear_experienced_utility_
                },
                {
                  attribute: 'Net importer of electricity',
                  priority: priorityText(this.getNetElectricityImports),
                  value: countyMatch.is_net_importer_
                },
                {
                  attribute: 'Home to nuclear research and development',
                  priority: priorityText(this.getProximityToNuclearRAndD),
                  value: countyMatch.has_nuclear_r_and_d
                }
              ];
          }
          this.isOpen = true;
        }
    }
}
</script>

<style scoped>
  #dev-score {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .deal-breaker {
    display: flex;
    align-items: flex-start;
  }
  .v-card__actions {
    display: flex;
    justify-content: space-between;
  }
</style>