<template>
<v-col cols=2>
    <v-checkbox
        v-model="isChecked"
        dense
        :label="landType"
        :disabled="isDisabled"
    ></v-checkbox>
</v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { showSnackbar } from '@commons/Actions';

export default {
    name: 'ComparisonProtectedLand',
    props: {
        landType: String,
        noDataAreas: Array
    },
    computed: {
        ...mapGetters(['getProtectedLands','isProtectedLand','getSiteAreas']),
        isChecked: {
            get: function() {
                return this.isProtectedLand(this.landType);
            },
            set: function() {
                this.changeProtectedLands(this.landType);
            }
        },
        isDisabled() {
            let noDataSite = false;
            if (this.noDataAreas) {
                this.noDataAreas.forEach(area => {
                    if (this.getSiteAreas.includes(area)) {
                        noDataSite = true;
                        if (this.isProtectedLand(this.landType)) {
                            this.changeProtectedLands(this.landType);
                        }
                    }
                });
            }
            if (this.getProtectedLands.length === 1 && 
                this.isProtectedLand(this.landType)) {
                showSnackbar('At least one land type must be selected', 'warning', 3000);
                return true;
            } else {
                return noDataSite;
            }
        }
    },
    methods: {
        ...mapActions([
            'changeProtectedLands'
        ]),
    },
}
</script>
