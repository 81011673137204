var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                {text: 'Not Relevant', value: 'Not Relevant'},
                {text: 'Very Low', value: 'Very Low'},
                {text: 'Low', value: 'Low'},
                {text: 'Medium', value: 'Medium'},
                {text: 'High', value: 'High'},
                {text: 'Very High', value: 'Very High'}
            ],"value":_vm.relevanceBin,"placeholder":"Select"},on:{"change":_vm.changeRelevanceBin}})],1),_c('v-col',[_c('v-slider',{directives:[{name:"show",rawName:"v-show",value:(_vm.relevance > 0),expression:"relevance > 0"}],attrs:{"max":_vm.sliderMax,"min":_vm.sliderMin,"tick-labels":_vm.sliderTicks},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }