<template>
    <v-container class="white fill-height">
        <v-layout column class="fill-height">
        <v-card-title
            class="primary white--text"
        >
            Site List
            <v-spacer></v-spacer>
            <v-btn fab v-if="!addingSite" @click="locateSite">
                <v-icon class="primary--text">mdi-map-marker-plus</v-icon>
            </v-btn>
            <v-btn fab v-if="addingSite" @click="cancelSite">
                <v-icon class="error--text">mdi-map-marker-off</v-icon>
            </v-btn>
        </v-card-title>
        <v-flex flex overflow-auto>
            <v-card-text>
                Add up to twenty sites. When you are ready, proceed to comparing
                them for development potential.
                    <slot></slot>
            </v-card-text>
        </v-flex>
        <v-flex flex shrink>
            <v-vard-text>
                <router-link to="/comparison/" class="text-decoration-none">
                    <v-btn block class="success" rounded>Compare Sites</v-btn>
                </router-link>
            </v-vard-text>
        </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    name: "ExplorationSiteList",
    props: {
        addingSite: Boolean,
        locateSite: Function,
        cancelSite: Function
    }

}
</script>

<style scoped>
    .container {
        padding: 0;
    }
    button.success {
        margin: 15px 0;
    }
</style>