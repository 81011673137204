var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.relevant),expression:"relevant"}],staticClass:"significance-item"},[_c('v-col',{staticClass:"text-subtitle-2",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.layerTitle)+" "),_c('sup',[_c('v-icon',{on:{"click":_vm.showInfo}},[_vm._v(" mdi-information-outline ")])],1)]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.stats.best))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.units),expression:"units"}],staticClass:"text-caption"},[_vm._v(_vm._s(_vm.units))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.stats.worst))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.units),expression:"units"}],staticClass:"text-caption"},[_vm._v(_vm._s(_vm.units))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.stats.site_avg))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.units),expression:"units"}],staticClass:"text-caption"},[_vm._v(_vm._s(_vm.units))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.stats.nat_avg))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.units),expression:"units"}],staticClass:"text-caption"},[_vm._v(_vm._s(_vm.units))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.stats.range_diff))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.units),expression:"units"}],staticClass:"text-caption"},[_vm._v(_vm._s(_vm.units))])]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.noRange)?_c('div',{staticClass:"text-subtitle-2 error--text"},[_vm._v("No variation among sites")]):_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                {text: 'Not Significant', value: 'Not Significant'},
                {text: 'Very Low', value: 'Very Low'},
                {text: 'Low', value: 'Low'},
                {text: 'Medium', value: 'Medium'},
                {text: 'High', value: 'High'},
                {text: 'Very High', value: 'Very High'}
            ],"value":_vm.significanceBin,"placeholder":"Select"},on:{"change":_vm.changeSignificanceBin}}),_c('v-slider',{directives:[{name:"show",rawName:"v-show",value:(_vm.significance > 0),expression:"significance > 0"}],attrs:{"max":_vm.sliderMax,"min":_vm.sliderMin,"tick-labels":_vm.sliderTicks},model:{value:(_vm.significance),callback:function ($$v) {_vm.significance=$$v},expression:"significance"}}),(_vm.noRefMap)?_c('div',{staticClass:"text-caption text-start"},[_vm._v("Reference Map Not Available")]):_c('div',{staticClass:"ref-map-link text-caption text-decoration-underline text-start",on:{"click":_vm.showMap}},[_vm._v("View "+_vm._s(_vm.layerTitle)+" Reference Map")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }