import Vue from 'vue';

export const ACTIONS = {
    SNACKBAR: 'snackbar',
    REFERENCE_MAP: 'reference_map',
    FEATURE_DETAIL: 'feature_detail',
    LAYER_INFO: 'layer_info',
    REPORT_CARD: 'report_card',
    CHANGE_PASSWORD: 'change_password',
    RESET_PASSWORD: 'reset_password',
    TERMS_OF_USE: 'terms_of_use',
};

const EventBus = new Vue();

export default EventBus;