<template>
    <v-checkbox
        v-model="isChecked"
        dense
        :label="label"
    ></v-checkbox>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'PriorityNuclearRestriction',
    props: {
        restriction_id: String,
        label: String
    },
    computed: {
        ...mapGetters([
            'isNuclearRestrictionDealBreaker'
        ]),
        isChecked: {
            get: function() {
                return this.isNuclearRestrictionDealBreaker(this.restriction_id);
                },
            set: function() {
                return this.changeNuclearRestrictions(this.restriction_id);
            }
        }
    },
    methods: {
        ...mapActions([
            'changeNuclearRestrictions'
        ]),
    },
}
</script>
