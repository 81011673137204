<template>
    <div>
        <v-stepper flat tile v-model="activeStep"
            height="100%" width="100%" color="primary">
            <v-stepper-header>
                <v-stepper-step
                    :complete="activeStep > 1"
                    step=1
                >
                    Set Priorities
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    step=2
                >
                    County Analysis
                </v-stepper-step>
                <v-icon
                    class="page-info"
                    x-large
                    color="primary"
                    @click="() => pageInfo = true"
                >mdi-information</v-icon>
            </v-stepper-header>
            <div class="flex-break"></div>
            <v-stepper-items>
                <v-stepper-content step=1>
                    <discovery-questionnaire></discovery-questionnaire>
                </v-stepper-content>
                <v-stepper-content step=2>
                    <discovery-match></discovery-match>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-dialog
            max-width="1000px"
            :value="pageInfo"
        >
            <v-card v-if="activeStep == 1">
                <v-card-title>Set Priorities</v-card-title>
                <v-card-text>
                    <div>
                        Fill out the Priority Questionnaire to indicate the relative
                        importance of each parameter displayed. Parameters are numbered
                        from 1–12. For each numbered parameter, there may be several
                        associated tasks.
                    </div>
                    <div class="text-subtitle-2">How To Use This Section:</div>
                    <ol>
                        <li>Read the question located after each numbered parameter.</li>
                        <li>
                            Some indicators may allow for additional selection options.
                            If applicable, set these selection options.
                        </li>
                        <li>View reference maps and parameter level information if desired.</li>
                        <li>Use the dropdown to set the relative importance of each indicator.</li>
                        <li>
                            Once the user has completed the priority questionnaire,
                            click submit to proceed to County Analysis.
                        </li>
                    </ol>
                </v-card-text>
            </v-card>
            <v-card v-else>
                <v-card-title>County Analysis</v-card-title>
                <v-card-text>
                    <div>
                        County Analysis displays a national Priority Match Map and Top
                        Matches leaderboard calculated from user inputs gathered in the
                        Priority Questionnaire. Users can explore the Priority Match Map,
                        save counties for Site Exploration and download county reports.
                    </div>
                    <div class="text-subtitle-2">How To Use This Section:</div>
                    <ol>
                        <li>
                            Explore the Priority Match Map
                            <ol type="a">
                                <li>
                                    Adjust the zoom with the +/- icon on the left of the
                                    map. Reposition by clicking and dragging.
                                </li>
                                <li>
                                    Click on a county to display a pop-up report card,
                                    select a county for the Exploration Path
                                    (maximum of 5), or download a county report.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Explore Top Matches
                            <ol type="a">
                                <li>
                                    Sort counties (multi-level sorting is supported)
                                    by county name, state, and rank.
                                </li>
                                <li>
                                    Use pagination ( at the bottom of the top 10 list)
                                    browse ranked counties.
                                </li>
                                <li>
                                    Click on the boxes on the left side of the county
                                    name to select that county for the Exploration Path.
                                    The counties will then appear under Selected Counties
                                    (limit 5 counties).
                                </li>
                                <li>
                                    Click the down arrow next to the county rank to
                                    download the county report.
                                </li>
                            </ol>
                        </li>
                        <li>Click Modify Priorities to return to the Priority Questionnaire.</li>
                        <li>
                            If the user has selected counties, they can click the Analyze
                            Locations button to proceed to the Exploration path.
                        </li>
                    </ol>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DiscoveryQuestionnaire from './DiscoveryQuestionnaire.vue';
import DiscoveryMatch from './DiscoveryMatch.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'DiscoveryPath',
    components: {
        DiscoveryQuestionnaire,
        DiscoveryMatch
    },
    data() {
        return {
            pageInfo: false,
        }
    },
    computed: {
        ...mapGetters(['getPriorityMatchResults']),
        activeStep() {
            // If results are in the store, go to the map
            return this.getPriorityMatchResults ? 2 : 1;
        }
    },
    mounted() {
        this.restore();
    },
    methods: {
        ...mapActions([
            'restore',
            ]),
    }
}
</script>


<style scoped>
    .page-info {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
    .v-sheet.v-stepper {
        background-color: #E3F2FD;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
    }
    .v-stepper__header {
        max-width: 500px;
        min-width: 400px;
        box-shadow: none;
    }
    #questionnaire {
        padding: 25px;
    }
    .flex-break {
        flex-basis: 100%;
        height: 0;
    }
</style>