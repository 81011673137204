import axios from 'axios';

const API = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': `${window.location.protocol}//${window.location.host}`
}
});

export async function requestFeatureInfo(layersString, bbox) {
    const response = await API.get("https://geoserver.fptz.org/geoserver/wms?SERVICE=WMS" +
        "&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=application/json&STYLES&TRANSPARENT=true" +
        `&QUERY_LAYERS=${layersString}` +
        `&LAYERS=${layersString}` +
        "&exceptions=application/vnd.ogc.se_inimage&INFO_FORMAT=application/json" +
        "&FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101" +
        `&BBOX=${bbox}`)
        .then(res => ({
            status: res.status,
            data: res.data
        }))
        .catch(error => ({
            status: error.response.status,
        }));
    
    return response;
}