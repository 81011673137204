<template>
    <v-card v-if="activeStep == 1">
        <v-card-title>Review Sites</v-card-title>
        <v-card-text>
            <div>
                Add site names and coordinates to be used in the multi-objective evaluation model.
                In order to run the model there needs to be a minimum of two sites and a maximum
                of twenty sites. 
            </div>
            <div class="text-subtitle-2">How To Use This Section:</div>
            <ol>
                <li>
                    Add Sites:
                    <div>Sites can be added in three ways either exclusively or in combination:</div>
                    <ul>
                        <li>
                            Click on Map
                            <ul>
                                <li>
                                    This will connect the user to the map from Site Exploration.
                                    Read the page information box for instructions to drop points.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Upload CSV
                            <ul>
                                <li>
                                    Upload a csv file in the following format: site_name, x, y.
                                </li>
                                <li>
                                    X and y coordinates must be in decimal degrees.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Enter Coordinates
                            <ul>
                                <li>
                                    Manually enter each site name and coordinate pair (in decimal degrees).
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    As you add sites they will appear in blue. Use the trash can to remove a site,
                    or validate a site by clicking on the blue link. This link will show the location
                    of that site on a map.
                </li>
                <li>
                    Once all the sites have been added and validated click the green Compare Sites
                    button to move on to the Relevance Form.
                </li>
            </ol>
        </v-card-text>
    </v-card>
    <v-card v-else-if="activeStep == 2">
        <v-card-title>Attribute Relevance</v-card-title>
        <v-card-text>
            <div>
                Indicate how relevant (or, important) each attribute is to your choice of an optimum
                site. Consider how relevant each attribute is without regard to how well or poorly each
                of your alternative sites performs with respect to the attribute. If you care very little
                or not at all about any attribute, you can indicate it as not relevant to your choice of a
                site. Attributes indicated as Not Relevant will result in a weighting factor of zero.
            </div>
            <div class="text-subtitle-2">How To Use This Section:</div>
            <ol>
                <li>
                    On the left hand side click on each objective (Socioeconomic, Safety, Proximity) to
                    display a numbered list of attributes
                </li>
                <li>
                    For each attribute you can:
                    <ul>
                        <li>
                            Click on the <v-icon x-small>mdi-information-outline</v-icon> to display
                            parameter level details such as, a description of layer, data source, data
                            currency, and why it matters.
                        </li>
                        <li>
                            Click on view reference map to view a reference map of that parameter
                        </li>
                    </ul>
                </li>
                <li>
                    Make additional parameter level selections where applicable ( buffer distance, time range, etc).
                </li>
                <li>
                    Select an Attribute Relevance category and then adjust the sliding scale to refine
                    your indication. The categories and raw weight scales are:
                    <table>
                        <tr>
                            <th>Category</th>
                            <th>Raw Weight Scale Range</th>
                        </tr>
                        <tr>
                            <td>Very High</td>
                            <td>50 - 46</td>
                        </tr>
                        <tr>
                            <td>High</td>
                            <td>40 - 36</td>
                        </tr>
                        <tr>
                            <td>Medium</td>
                            <td>30 - 26</td>
                        </tr>
                        <tr>
                            <td>Low</td>
                            <td>20 - 16</td>
                        </tr>
                        <tr>
                            <td>Very Low</td>
                            <td>10 - 6</td>
                        </tr>
                        <tr>
                            <td>Not Relevant</td>
                            <td>0</td>
                        </tr>
                    </table>
                </li>
                <li>
                    When complete click the green submit button to proceed to the Significance Form.
                </li>
            </ol>
            <div class="text-subtitle-2">Comparison between the Contiguous U.S, and Alaska/Hawaii may be limited by lack of data availability</div>
        </v-card-text>
    </v-card>
    <v-card v-else-if="activeStep == 3">
        <v-card-title>Range Significance</v-card-title>
        <v-card-text>
            <div>
                This page shows the best and worst quantitative measures across all of your alternative
                sites for each attribute in the preference model. In addition to the best site and worst
                site measures, the difference between the best and worst measures and other analytics are
                presented to provide context for your assessment of Range Significance.
            </div>
            <div>
                Indicate how significant the difference is between the best and worst site measures is in
                your choice of an optimum site for your advanced reactor demonstration. Consider the range
                significance of each attribute measure without regard to how relevant you indicated the
                attribute is in the previous step. 
            </div>
            <div>
                There are two situations in which you should indicate that the best-to-worst range is not
                significant, and that attribute will receive a weighting factor of zero. In each situation,
                the attribute measures provide no information that helps to differentiate between the
                alternative sites.
            </div>
            <ul>
                <li>
                    When the best site measure equals the worst site measure.
                </li>
                <li>
                    When the best site measure and worst site measure are extreme values of similar
                    magnitude and much larger or smaller that a given threshold or out of range consideration.
                </li>
            </ul>
            <div>
                Example: Assume that a regulatory safety buffer from some installation is 5 miles.  All of your
                alternative sites are between 50 and 100 miles from the nearest installation of this type. Although
                the difference between the best site and the worst site is 50 miles, they all exceed the threshold
                by such a great amount that the difference between them is not significant. 
            </div>
            <div class="text-subtitle-2">How To Use This Section:</div>
            <ol>
                <li>
                    On the left hand side click each objective (Socioeconomic, Safety, Proximity)
                    to display a numbered list of attributes.
                </li>
                <li>
                    For each attribute you can:
                    <ul>
                        <li>
                            Click on the <v-icon x-small>mdi-information-outline</v-icon> to display
                            parameter level details such as, a description of layer, data source, data
                            currency, and why it matters.
                        </li>
                        <li>
                            Click on view reference map to view a reference map of that parameter
                        </li>
                    </ul>
                </li>
                <li>
                    Select a Range Significance category and then adjust the sliding scale to refine
                    your indication. The categories and raw weight scales are:
                    <table>
                        <tr>
                            <th>Category</th>
                            <th>Raw Weight Scale Range</th>
                        </tr>
                        <tr>
                            <td>Very High</td>
                            <td>50 - 46</td>
                        </tr>
                        <tr>
                            <td>High</td>
                            <td>40 - 36</td>
                        </tr>
                        <tr>
                            <td>Medium</td>
                            <td>30 - 26</td>
                        </tr>
                        <tr>
                            <td>Low</td>
                            <td>20 - 16</td>
                        </tr>
                        <tr>
                            <td>Very Low</td>
                            <td>10 - 6</td>
                        </tr>
                        <tr>
                            <td>Not Significant</td>
                            <td>0</td>
                        </tr>
                    </table>
                </li>
                <li>
                    When complete click the green submit button to proceed to the Attribute Relevance - Significance Matrix.
                </li>
            </ol>
            <div class="text-subtitle-2">Comparison between the Contiguous U.S, and Alaska/Hawaii may be limited by lack of data availability</div>
        </v-card-text>
    </v-card>
    <v-card v-else-if="activeStep == 4">
        <v-card-title>Attribute Relevance – Range Significance Matrix</v-card-title>
        <v-card-text>
            <div>
                This matrix presents each of the Preference Model attributes according to the attribute
                relevance and range significance that you indicated in the previous two steps.  Also shown
                are the normalized weight calculated for each of the attributes. Normalized weights sum to 100.
            </div>
            <div class="text-subtitle-2">How To Use This Section:</div>
            <ol>
                <li>
                    Carefully review the placement of each attribute within the matrix and its
                    normalized weight relative to all other weights.
                </li>
                <li>
                    If desired users can adjust any of the attributes by going back to either of the previous
                    two steps to change the attribute relevance and/or range significance for any of the attributes.
                    Return here to review the result of any modifications you make. Remember that the amount of
                    control you have over any attribute’s normalized weight is tempered by all of the other
                    attribute weights and the condition that normalized weights sum to 100.
                </li>
                <li>
                    When the user is satisfied with the normalized weights, click submit to view results.
                </li>
            </ol>
        </v-card-text>
    </v-card>
    <v-card v-else-if="activeStep == 5">
        <v-card-title>Results</v-card-title>
        <v-card-text>
            <div>
                View the results of the multi-objective evaluation model.
            </div>
            <div class="text-subtitle-2">How To Use This Section:</div>
            <ol>
                <li>
                    Click the blue link labeled Result Data (CSV) to download data.
                </li>
                <li>
                    Click the gray menu button on the upper right hand corner of
                    each image to download or print an image of that chart or graph.
                </li>
            </ol>
        </v-card-text>
    </v-card>
    <v-card v-else></v-card>
</template>

<script>
export default {
    name: 'ComparisonPageInfo',
    props: {
        activeStep: Number
    },
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}
td, th {
    border: 1px solid #ddd;
    text-align: center;
    padding: 2px;
}
</style>