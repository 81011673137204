<template>
    <v-container>
        <v-card class="results-item">
            <v-card-title>Download Result Data</v-card-title>
            <v-card-text>
                <div @click="downloadCSV" id="data-download">
                    <v-icon>mdi-download</v-icon>
                    <span class="primary--text text-subtitle-1 font-weight-bold text-decoration-underline">Result Data (CSV)</span>
                </div>
                <div>
                    Download the objective values for each of your sites.
                </div>
            </v-card-text>
        </v-card>
        <v-card id="map-card">
            <v-card-title>Site Locations</v-card-title>
            <div id="results-map"></div>
            <v-card-text>Your site locations labelled by rank according to their composite values. Click on a marker for more information.</v-card-text>
        </v-card>
        <v-card class="results-item">
            <v-card-title>Site Attributes</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="getSiteTableHeaders"
                    :items="getSiteTableItems"
                    :items-per-page="5"
                    sort-by="'rank'"
                    class="elevation-1"
                ></v-data-table>
                <div>The actual values used to calculate normalized values for each site</div>
            </v-card-text>
        </v-card>
        <highcharts
            class="results-item"
            :options="valueStackChart"
        ></highcharts>
        <highcharts
            class="results-item"
            :options="valueChart"
        ></highcharts>
        <highcharts
            class="results-item"
            :options="normWeightChart"
        ></highcharts>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Highcharts from 'highcharts';
import { requestComparisonResultsCsv } from '@api/star/comparison';

import { Chart } from "highcharts-vue";
import exportingInit from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";

exportingInit(Highcharts);
offlineExporting(Highcharts);

export default {
    name: 'ComparisonResults',
    components: {
        highcharts: Chart,
    },
    methods: {
        ...mapActions(['restore']),
        async downloadCSV() {
            await this.restore();
            requestComparisonResultsCsv(this.getAccess);
        },
    },
    async updated() {
        if (this.getComparisonResults.length > 0) {
            await this.restore();
            
            const SITES_CENTER = {
                lat: this.getSites.reduce((acc, d) => acc + d.lat_coord, 0) / this.getSites.length,
                lng: this.getSites.reduce((acc, d) => acc + d.lng_coord, 0) / this.getSites.length
            };
    
            const SITES_BOUNDS = {
                north: Math.max(...this.getSites.map(d => d.lat_coord)) + 3.0,
                south: Math.min(...this.getSites.map(d => d.lat_coord)) - 1.0,
                east: Math.max(...this.getSites.map(d => d.lng_coord)) + 1.0,
                west: Math.min(...this.getSites.map(d => d.lng_coord)) - 1.0,
            };
            
            this.map = new window.google.maps.Map(document.getElementById('results-map'), {
                center: SITES_CENTER,
                // restriction: {latLngBounds: SITES_BOUNDS, strictBounds: false},
                zoom: 0,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: true,
                draggableCursor: 'default'

            });

            this.map.fitBounds(SITES_BOUNDS);

            const infoWindow = new window.google.maps.InfoWindow({
                maxWidth: 300
            })

            this.getSites.forEach(s => {
                const siteMarker = new window.google.maps.Marker({
                    map: this.map,
                    position: {lat: s.lat_coord, lng: s.lng_coord},
                    id: s.id,
                    label: this.getComparisonResults.filter(d => d.site_name_ == s.site_name)[0].overall_rank_.toString(),
                    properties: this.getComparisonResults.filter(d => d.site_name_ == s.site_name)[0]
                });

                siteMarker.addListener('click', function() {
                    infoWindow.setContent(`<h4>${this.properties.site_name_}</h4>
                    <div>safety rank: <strong>${this.properties.safety_rank_}</strong></div>
                    <div>proximity rank: <strong>${this.properties.proximity_rank_}</strong></div>
                    <div>socioeconomic rank: <strong>${this.properties.socioeconomic_rank_}</strong></div>
                    `);
                    infoWindow.open({
                        anchor: this,
                        map: this.map,
                        shouldFocus: true
                    });
                })
            });
        }
    },
    computed: {
        ...mapGetters([
            'getSites','getComparisonResults','getWeights', 'getAccess',
            'getSiteTableHeaders', 'getSiteTableItems'
        ]),
        valueStackChart() {
            return {
                caption: {
                    text: 'Stacked bars show the contribution of each comparison objective to the total value of each potential site'
                },
                credits: { enabled: false },
                chart: { type: 'column' },
                exporting: {
                    chartOptions: {
                        plotOptions: {
                            series:{
                                dataLabels: {
                                    enabled: true,
                                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                                    style:{
                                        textOutline: 'none',
                                        color: 'white'
                                    }
                                }
                            }
                        }
                    }
                }, // labels look very busy, try to suppress
                title: { text: 'Contribution of Primary Objective to Composite Value' },
                xAxis: { categories: this.getComparisonResults.map(d => d.site_name_) },
                yAxis: {
                    min: 0,
                    title: { text: 'Relative Value' },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: ( // theme
                                Highcharts.defaultOptions.title.style &&
                                Highcharts.defaultOptions.title.style.color
                            ) || 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: -30,
                    verticalAlign: 'top',
                    y: 25,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [{
                    name: 'Safety',
                    data: this.getComparisonResults.map(d => d.safety_weighted_value_)
                }, {
                    name: 'Proximity',
                    data: this.getComparisonResults.map(d => d.proximity_weighted_value_)
                }, {
                    name: 'Socioeconomic',
                    data: this.getComparisonResults.map(d => d.socioeconomic_weighted_value_)
                }]
                }
            },
        valueChart() {
            return {
                caption: {
                    text: 'The total value of each potential site'
                },
                credits: { enabled: false },
                chart: { type: 'column' },
                title: { text: 'Relative Site Value' },
                xAxis: { type: 'category' },
                yAxis: {
                    min: 0,
                    title: { text: 'Relative Value' },
                },
                legend: { enabled: false },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{point.name}: {point.y:.2f}'
                },
                plotOptions: {
                    series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}'
                }
            }
            },
            series: [{
                name: 'Relative Value',
                data: this.getComparisonResults.map(d => ({
                    name: d.site_name_,
                    y: d.overall_weighted_value_
                }))
            }]
            }
        },
        normWeightChart() {
            return {
                caption: {
                    text:'The normalized weights of each attribute according to your relevance and significance settings'
                },
                chart: { type: 'bar', height: '80%' },
                title: { text: 'Normalized Weights' },
                xAxis: { type: 'category' },
                yAxis: {
                    min: 0,
                    labels: {
                        overflow: 'justify'
                    }
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{point.name}: {point.y:.2f}'
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                    format: '{point.y:.2f}'
                        }
                    }
                },
                legend: { enabled: false }, // Legend to be added once Objective Grouping is resolved
                // legend: {
                //     layout: 'vertical',
                //     align: 'right',
                //     verticalAlign: 'top',
                //     x: -40,
                //     y: 80,
                //     floating: true,
                //     borderWidth: 1,
                //     backgroundColor:
                //         Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                //     shadow: true
                // },
                credits: { enabled: false },
                series: [{
                    name: 'Normalized Weight',
                    data: Object.entries(this.getWeights(true)).map(d => ({
                        name: d[0],
                        y: d[1]
                    }))
                }]
}
        },
    }
}
</script>

<style scoped>
    .container {
        padding: 30px;
    }
    .results-item {
        border: 1px solid #ddd;
        border-radius: 10px;
        margin: 15px 30px;
    }
    #data-download {
        cursor: pointer;
    }
    #map-card {
        margin: 0 2em;
    }
    #results-map {
        height: 25em;
    }
</style>