<template>
    <div>
    <v-row align-content="start" :no-gutters="!infoLayer">
        <v-col>
            <v-checkbox
                v-model="isChecked"
                dense
                :disabled="isDisabled"
                :messages="(() => isMaxVisible ? 'Max. layers visible' : isDisabled ? 'Zoom in' : null)()"
            >
                <template v-slot:label>
                    {{label}}
                    <sup v-if="infoLayer">
                        <v-icon small @click="showInfo" @click.stop>
                            mdi-information-outline
                        </v-icon>
                    </sup>
                    <v-tooltip right v-if="clickable && !isDisabled">
                        <template v-slot:activator="{ on, attrs }">
                            <sup v-bind="attrs" v-on="on">
                                <v-icon small>
                                    mdi-cursor-default-click
                                </v-icon>
                            </sup>
                        </template>
                        <span>Click on map overlay for more info</span>
                    </v-tooltip>
                </template>
            </v-checkbox>
        </v-col>
    </v-row>
    <img :class="fullWidthLegend ? 'full-width-legend' : ''" v-if="isChecked" :src="legend" alt="key">
    <v-divider v-if="infoLayer && !hideDivider"></v-divider>
    </div>
</template>

<script>
import { showLayerInfo } from '@commons/Actions';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ExplorationLayerControl",
    props: {
        label: String,
        infoLayer: String,
        storeKey: String,
        clickable: {
            type: Boolean,
            default: false
        },
        hideDivider: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['getLayerVisibility', 'isLayerDisabled','getLayerString', 'getCountVisible']),
        isChecked: {
            get() {
                return this.getLayerVisibility(this.storeKey);
            },
            set() {
                this.changeLayerVisibility(this.storeKey);
            }
        },
        isDisabled() {
            return this.isLayerDisabled(this.storeKey) || (this.getCountVisible >= 5 && !this.isChecked);
        },
        isMaxVisible() {
            return this.getCountVisible >= 5;
        },
        legend() {
            return `https://geoserver.fptz.org/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=${this.getLayerString(this.storeKey).split(',')[0]}`;
        },
        fullWidthLegend() {
            return ['facility_generation_geo'].includes(this.storeKey)
        }
    },
    methods: {
        ...mapActions(['changeLayerVisibility']),
        showInfo() {
            showLayerInfo(this.infoLayer, 'stand', 'Exploration', this.layerTitle);
        },
    }
}
</script>

<style scoped>
    .full-width-legend {
        width: 100%;
    }
</style>