import { API } from './config';

export async function createToken(email, password) {

    const data = JSON.stringify({email, password});

    const response = await API.post('/auth/jwt/create/', data)
    .then(res => ({
        status: res.status,
        access: res.data.access,
        refresh: res.data.refresh,
        message: 'Authentication sucessful!',
    }))
    .catch(error => ({
        status: error.response.status,
        message: error.response.data.detail,
    }));

    return response;
}

export async function refreshToken(refresh) {

    const data = JSON.stringify({ refresh });

    const response = await API.post('/auth/jwt/refresh/', data)
    .then(res => {return {
        status: res.status,
        access: res.data.access,
    }})
    .catch(error => {return {
        status: error.response.status,
        message: error.response.data,
    }});

    return response;
}