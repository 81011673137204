<template>
    <v-row class="site-li" justify="space-between">
        <span
            class="text-body-2 primary--text site-name"
            @click="zoomToSite"
        >{{ name }}</span>
        <span class="text-caption blue-grey--text">{{ lat.toFixed(4) }}, {{ lng.toFixed(4) }}</span>
        <v-icon small @click="deleteSite">mdi-delete</v-icon>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { showSnackbar } from "@commons/Actions";
import {
    deleteSite
} from "@api/star/comparison";

export default {
    name: 'ComparisonReviewSitesListItem',
    props: {
        id: Number,
        name: String,
        lat: Number,
        lng: Number
    },
    computed: {
        ...mapGetters(['getAccess']),
    },
    methods: {
        ...mapActions(['restore','changeSites']),
        async deleteSite() {
            await this.restore();
            const res = await deleteSite(this.getAccess, this.id);
            if (res.status == 204) {
                this.changeSites({id: this.id});
                showSnackbar(`"${this.name}" has been deleted`, 'warning', 3000);
            } else {
                showSnackbar(`ERROR - "${this.name}" was NOT deleted`, 'error', 3000);
            }
        },
        zoomToSite() {
            this.$router.push({
                path: '/exploration/',
                query: {
                    lat: this.lat,
                    lng: this.lng
                }
                });
        }
    }
}
</script>

<style scoped>
    .site-li {
        margin-top: 16px;
        padding: 10px 2%;
    }
    .site-li:after {
        content: "";
        border-bottom: 1px solid #333;
        margin: 0 auto;
        padding-top: 10px;
        width: 100%;
        display: block;
    }
    .site-name {
        cursor: pointer;
    }
</style>