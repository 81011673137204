<template>
    <div>
    <v-row align-content="start">
        <v-col>
            <v-checkbox
                v-model="isChecked"
                dense
                :disabled="isCheckboxDisabled"
                :messages="(() => isCheckboxDisabled ? 'Zoom in' : null)()"
            >
                <template v-slot:label>
                    {{label}}
                    <sup>
                        <v-icon x-small @click="showInfo" @click.stop>
                            mdi-information-outline
                        </v-icon>
                    </sup>
                </template>
            </v-checkbox>
            <img v-if="isChecked" :src="legend" alt="key">
            <v-select
                v-model="select"
                :items="items"
                :disabled="isSelectDisabled"
            ></v-select>
        </v-col>
    </v-row>
    <v-divider></v-divider>
    </div>
</template>

<script>
import { showLayerInfo } from '@commons/Actions';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ExplorationVariableLayerControl",
    props: {
        label: String,
        infoLayer: String,
        items: Array,
    },
    data() {
        return {
            select: this.items[0].value
        }
    },
    watch: {
        select: function(newValue, oldSelect) {
            if (newValue != oldSelect.value) {
                this.changeLayerVisibility(oldSelect);
                this.changeLayerVisibility(newValue);
            }
        }
    },
    computed: {
        ...mapGetters(['getLayerString','getLayerVisibility', 'isLayerDisabled', 'getLayerObject']),
        isChecked: {
            get() {
                return this.getLayerVisibility(this.select);
            },
            set() {
                this.changeLayerVisibility(this.select);
            }
        },
        isCheckboxDisabled() {
            return this.isLayerDisabled(this.select);
        },
        isSelectDisabled() {
            return this.isCheckboxDisabled || !this.isChecked;
        },
        legend() {
            return `https://geoserver.fptz.org/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=${this.getLayerString(this.select).split(',')[0]}`;
        },
    },
    methods: {
        ...mapActions(['changeLayerVisibility']),
        showInfo() {
            showLayerInfo(this.infoLayer, 'stand', 'Exploration', this.layerTitle);
        },
    }
}
</script>
