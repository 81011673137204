import { API } from '../config';

export async function requestSites(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get('/api/stand/comparison-sites/', {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function postSite(access, site_name, lng_coord, lat_coord) {
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const data = { site_name, lng_coord, lat_coord }

    const response = await API.post('/api/stand/comparison-sites/', data, {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function deleteSite(access, id) {
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const data = {
        id
    };

    const response = await API.delete('/api/stand/comparison-sites/', {headers, data})
    .then(res => ({
        status: res.status
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestComparisonRelevance(access, user_id) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };
    
    const response = await API.get(`/api/stand/comparison-relevance/${user_id}`, {headers})
    .then(res => ({
        status: res.status,
        ...res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function updateComparisonRelevance(access, user_id, data) {
    const headers = {
        'Authorization': `JWT ${access}`
    };
    const response = await API.patch(`/api/stand/comparison-relevance/${user_id}`, data, {headers})
    .then(res => ({
        status: res.status
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestComparisonSignificance(access, user_id) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };
    
    const response = await API.get(`/api/stand/comparison-significance/${user_id}`, {headers})
    .then(res => ({
        status: res.status,
        ...res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function updateComparisonSignificance(access, user_id, data) {
    const headers = {
        'Authorization': `JWT ${access}`
    };
    const response = await API.patch(`/api/stand/comparison-significance/${user_id}`, data, {headers})
    .then(res => ({
        status: res.status
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestComparisonStats(access, layer_name) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get(`/api/stand/comparison-stats/${layer_name}`, {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestSiteAllData(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get('/api/stand/site-all-data/', {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestComparisonResults(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get('/api/stand/comparison-results/', {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestComparisonResultsCsv(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    API.get('/api/stand/comparison-results-csv/', {headers})
    .then(res => {
        const a = document.createElement("a");
        a.style = "display: none";
        const blob = new Blob([res.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "stand_data.csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    });
}