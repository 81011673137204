<template>
    <div>
        <v-row id="outer-row" align="stretch" no-gutters>
            <v-col cols="2">
                    <v-tabs vertical v-model="tab" background-color="#E3F2FD">
                        <v-tab key="Socioeconomic">Socioeconomic</v-tab>
                        <v-tab key="Safety" @click="safetyVisited = true">Safety</v-tab>
                        <v-tab key="Proximity" @click="proximityVisited = true">Proximity</v-tab>
                    </v-tabs>
                <v-row justify="space-around" class="submit-row">
                    <v-btn class="success" 
                        @click="verifySubmit">Submit</v-btn>
                </v-row>
            </v-col>
            <v-col cols="10">
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        key="Socioeconomic"
                    >
                        <comparison-restriction-relevance>
                            <template v-slot:restrictions>
                                <comparison-restriction-relevance-item
                                    :index="5"
                                    text="Moratorium"
                                ></comparison-restriction-relevance-item>
                                <comparison-restriction-relevance-item
                                    :index="1"
                                    text="Required approval by state legislature"
                                ></comparison-restriction-relevance-item>
                                <comparison-restriction-relevance-item
                                    :index="0"
                                    text="Required approval by the state Commissioner of Environmental Protection"
                                ></comparison-restriction-relevance-item>
                                <comparison-restriction-relevance-item
                                    :index="6"
                                    text="Voter approval"
                                ></comparison-restriction-relevance-item>
                                <comparison-restriction-relevance-item
                                    :index="2"
                                    text="Finding (i.e. proof) that the construction of a nuclear facility will be economically feasible for ratepayers"
                                ></comparison-restriction-relevance-item>
                                <comparison-restriction-relevance-item
                                    :index="3"
                                    text="Demonstrable technology or a means for high level waste disposal or reprocessing"
                                ></comparison-restriction-relevance-item>
                                <comparison-restriction-relevance-item
                                    :index="4"
                                    text="Finding that the proposed method for disposal of radioactive waste material (to be produced or generated by the facility) will be safe"
                                ></comparison-restriction-relevance-item>
                            </template>
                        </comparison-restriction-relevance>
                        <comparison-relevance-item 
                            storeKey="energy_price"
                            layer-title="Energy Price"
                            layer="retail-energy-price-by-state"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="net_electricity_imports"
                            layer-title="Net Electricity Imports"
                            layer="net-electricity-imports-by-state"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="nuclear_sentiment"
                            layer-title="Nuclear Sentiment"
                            layer="nuclear-sentiment-by-county"
                            :noDataAreas="['AK', 'HI']"
                        >
                            <template v-slot:options>
                                <div class="text-body-2">Buffer distance from site:</div>
                                <v-radio-group row v-model="sentimentBuffer">
                                    <v-radio label="10 miles" :value="10"></v-radio>
                                    <v-radio label="20 miles" :value="20"></v-radio>
                                    <v-radio label="30 miles" :value="30"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="nuclear_inclusive_policy"
                            layer-title="Nuclear Inclusive Policy"
                            layer="nuclear-inclusive-policy-by-state"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="market_regulation"
                            layer-title="Market Regulation"
                            layer="electricity-market-type-by-state"
                        >
                            <template v-slot:options>
                                <div class="text-body-2">Preferred market:</div>
                                <v-radio-group row v-model="preferredMarket">
                                    <v-radio label="Deregulated" value="Deregulated"></v-radio>
                                    <v-radio label="Traditionally Regulated" value="Traditionally Regulated"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="labor_rate"
                            layer-title="Construction Labor Rate"
                            layer="construction-mean-annual-wage-by-state"
                            units="USD/year"
                        ></comparison-relevance-item>
                    </v-tab-item>
                    <v-tab-item
                        key="Safety"
                    >
                        <!-- <comparison-relevance-item 
                            storeKey="cdc_svi"
                            layer-title="Social Vulnerability Index"
                            layer="social-vulnerability-index-by-county"
                        >
                            <template v-slot:options>
                                <div class="text-body-2">Buffer distance from site:</div>
                                <v-radio-group row v-model="sviBuffer">
                                    <v-radio label="10 miles" :value="10"></v-radio>
                                    <v-radio label="20 miles" :value="20"></v-radio>
                                    <v-radio label="30 miles" :value="30"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item> -->
                        <comparison-relevance-item
                            storeKey="protected_lands"
                            layer-title="Protected Lands"
                            layer="protected-lands"
                            :noRefMap="true"
                            :noDataAreas="['HI']"
                        >
                            <template v-slot:options>
                                <div class="text-subtitle-2">
                                    Which lands concern you?
                                </div>
                                <div 
                                    class="text-subtitle-2 red--text"
                                    v-if="getSiteAreas.includes('AK')"
                                >
                                    Some land types excluded for lack of coverage in AK.
                                </div>
                                <v-row justify="start" no-gutters>
                                    <comparison-protected-land
                                        landType="American Indian reservations"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="Correctional facilities"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="Critical habitat"
                                    ></comparison-protected-land>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-protected-land
                                        landType="Forests"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="Hospitals"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="National monuments"
                                    ></comparison-protected-land>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-protected-land
                                        landType="National, state, and local parks"
                                        :noDataAreas="['AK']"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="Schools/colleges"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="Wild and scenic rivers"
                                        :noDataAreas="['AK']"
                                    ></comparison-protected-land>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-protected-land
                                        landType="Wilderness areas"
                                        :noDataAreas="['AK']"
                                    ></comparison-protected-land>
                                    <comparison-protected-land
                                        landType="Wildlife refuges"
                                        :noDataAreas="['AK']"
                                    ></comparison-protected-land>
                                </v-row>
                            </template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="hazardous_facilities"
                            layer-title="Hazardous Facilities"
                            layer="hazardous-facilities"
                            :noRefMap="true"
                        >
                            <template v-slot:options>
                                <div class="text-subtitle-2">
                                    Which facilities concern you?
                                </div>
                                <div 
                                     class="text-subtitle-2 red--text"
                                    v-if="getSiteAreas.includes('AK') || getSiteAreas.includes('HI')"
                                >
                                    Some facility types excluded for lack of coverage 
                                    in {{getSiteAreas.filter( a => a !== 'CONUS').join(' or ')}}.
                                </div>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="Airports"
                                        :noDataAreas="['HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Biodiesel Plants"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Biological Products Manufacturing"
                                    ></comparison-hazardous-facility>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="Chemical Manufacturing"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Ethanol Plants"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Explosives Manufacturing"
                                    ></comparison-hazardous-facility>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="Liquified Natural Gas Import Terminals"
                                        :noDataAreas="['HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Lubricating Oils and Grease Plants"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Natural Gas Compressor Stations"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="Natural Gas Import/Export Locations"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Natural Gas Processing Plants"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Natural Gas Storage Facilities"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="Nitrogenous Fertilizer Plants"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Nuclear Fuel Plants"
                                        :noDataAreas="['AK', 'HI']"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Oil Refineries"
                                    ></comparison-hazardous-facility>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="Petroleum Pumping Stations"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Pharmaceutical Preparations Manufacturing"
                                    ></comparison-hazardous-facility>
                                    <comparison-hazardous-facility
                                        facilityType="Phosphatic Fertilizer Plants"
                                        :noDataAreas="['AK']"
                                    ></comparison-hazardous-facility>
                                </v-row>
                                <v-row justify="start" no-gutters>
                                    <comparison-hazardous-facility
                                        facilityType="POL Terminals, Storage Facilities, Tank Farms"
                                    ></comparison-hazardous-facility>
                                </v-row></template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="fault_lines"
                            layer-title="Fault Lines"
                            layer="fault-lines"
                            :noRefMap="true"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="landslide_hazard"
                            layer-title="Landslide Hazard"
                            layer="landslide-hazard"
                            :noRefMap="true"
                            :noDataAreas="['AK', 'HI']"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="safe_shutdown_earthquake"
                            layer-title="Safe Shutdown Earthquake"
                            layer="safe-shutdown-earthquake"
                            :noRefMap="true"
                        >
                            <template v-slot:options>
                                <div class="text-body-2">Peak ground acceleration:</div>
                                <v-radio-group row v-model="ssePGA">
                                    <v-radio label="0.3g" value="0.3g"></v-radio>
                                    <v-radio label="0.4g" value="0.4g"></v-radio>
                                    <v-radio label="0.5g" value="0.5g"></v-radio>
                                    <v-radio label="0.6g" value="0.6g"></v-radio>
                                    <v-radio label="6.5g (HI only)" value="6.5g"></v-radio>
                                    <v-radio label="10g (HI only)" value="10.0g"></v-radio>
                                    <v-radio label="15g (AK only)" value="15.0g"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="one_hundred_year_flood"
                            layer-title="100 Year Flood"
                            layer="one-hundred-year-flood"
                            :noRefMap="true"
                            :noDataAreas="['AK']"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="open_water_and_wetlands"
                            layer-title="Open Waters and Wetlands"
                            layer="open-water-and-wetlands"
                            :noRefMap="true"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="slope"
                            layer-title="Slope"
                            layer="slope"
                            :noRefMap="true"
                        >
                            <template v-slot:options>
                                <div class="text-body-2">Max. slope tolerance:</div>
                                <v-radio-group row v-model="slopePCT">
                                    <v-radio label="12%" value="12%"></v-radio>
                                    <v-radio label="18%" value="18%"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item>
                    </v-tab-item>
                    <v-tab-item
                        key="Proximity"
                    >
                        <comparison-relevance-item 
                            storeKey="population"
                            layer-title="Population"
                            layer="population"
                            :noRefMap="true"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="operating_nuclear"
                            layer-title="Operating Nuclear Facilities"
                            layer="nuclear-facility-summary-by-county"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="nuclear_r_and_d"
                            layer-title="Nuclear Research &amp; Development"
                            layer="nuclear-r-and-d-by-county"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="electrical_substations"
                            layer-title="Substations"
                            layer="electric-substations"
                            :noRefMap="true"
                        ></comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="generator_retirement"
                            layer-title="Generator Retirement"
                            layer="retiring-generator-summary-by-county"
                        >
                            <template v-slot:options>
                                <div class="text-subtitle-2">
                                    Which technologies interest you?
                                </div>
                                <v-row justify="start">
                                    <comparison-retiring-technology
                                        technology="Coal"
                                    ></comparison-retiring-technology>
                                    <comparison-retiring-technology
                                        technology="Natural Gas"
                                    ></comparison-retiring-technology>
                                    <comparison-retiring-technology
                                        technology="Nuclear"
                                    ></comparison-retiring-technology>
                                </v-row>
                                <div class="text-subtitle-2" id="retirement-years-prompt">
                                    In how many years should a generator be retiring?
                                </div>
                                <v-range-slider
                                    id="retirement-range"
                                    min="0"
                                    max="50"
                                    thumb-label="always"
                                    :tick-labels="retirementYearTicks"
                                    v-model="retirementYearRange"
                                ></v-range-slider></template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="transportation"
                            layer-title="Transportation"
                            layer="transportation"
                            :noRefMap="true"
                        >
                            <template v-slot:options>
                                <div class="text-subtitle-2">
                                    What is your most critical transportation method?
                                </div>
                                <v-radio-group row v-model="transportation">
                                    <v-radio label="Major Roads" value="Major Roads"></v-radio>
                                    <v-radio label="Navigable Waterways" value="Navigable Waterways"></v-radio>
                                    <v-radio label="Rail Lines" value="Rail Lines"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item>
                        <comparison-relevance-item 
                            storeKey="streamflow"
                            layer-title="Streamflow"
                            layer="streamflow"
                            :noRefMap="true"
                            :noDataAreas="['HI']"
                        >
                            <template v-slot:options>
                                <div class="text-body-2">Min. GPM tolerance:</div>
                                <v-radio-group row v-model="streamflowGPM">
                                    <v-radio label="15K (20K in AK)" value="15000"></v-radio>
                                    <v-radio label="50K" value="50000"></v-radio>
                                    <v-radio label="65K (84K in AK)" value="65000"></v-radio>
                                </v-radio-group>
                            </template>
                        </comparison-relevance-item>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog
            max-width="500px"
            persistent
            :value="(!safetyVisited || !proximityVisited) && submit"
        >
            <v-card>
                <v-card-title class="justify-center">
                    <v-icon x-large>mdi-alert-circle</v-icon>
                </v-card-title>
                <v-card-text>
                    <p>
                        You are submitting the Relevance Form without having visited all 
                        objective sections (Socioeconomic, Safety, and Proximity).
                    </p>
                    <p>
                        You are not required to have relevance values set for each objective,
                        however, it is recommended that you review each one before proceeding.
                        Any measures left as 'Not Relevant' will be excluded from the rest of
                        this analysis.
                    </p>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="cancelSubmit"
                >
                    Return to Form
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="submitRelevance"
                >
                    Submit Anyway
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="250px"
            persistent
            :value="calculating"
        >
            <v-card>
                <v-card-text>
                    <div>Calculating statistics...</div>
                    <div>This may take several minutes</div>
                    <div>{{ layerTitle }}</div>
                    <v-progress-linear
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ComparisonRelevanceItem from './ComparisonRelevanceItem.vue';
import ComparisonRestrictionRelevance from './ComparisonRestrictionRelevance.vue';
import ComparisonRestrictionRelevanceItem from './ComparisonRestrictionRelevanceItem.vue';
import ComparisonRetiringTechnology from './ComparisonRetiringTechnology.vue';
import { updateComparisonRelevance, requestComparisonStats } from '@api/star/comparison';
import { showSnackbar } from '@commons/Actions';
import { mapGetters, mapActions } from 'vuex';
import ComparisonHazardousFacility from './ComparisonHazardousFacility.vue';
// import ComparisonTransportationMethod from './ComparisonTransportationMethod.vue';
import ComparisonProtectedLand from './ComparisonProtectedLand.vue';

export default {
    name: 'ComparisonRelevanceForm',
    components: {
        ComparisonRelevanceItem,
        ComparisonRestrictionRelevance,
        ComparisonRestrictionRelevanceItem,
        ComparisonRetiringTechnology,
        ComparisonHazardousFacility,
        // ComparisonTransportationMethod,
        ComparisonProtectedLand,
    },
    props: {
        advance: Function,
    },
    data() {
        return {
            tab: null,
            safetyVisited: false,
            proximityVisited: false,
            submit: false,
            layerTitle: null,
            calculating: false,
        }
    },
    computed: {
        ...mapGetters([
            'getAccess','getUserId','getAllRelevance', //'getCdcSviBufferMiles',
            'getSentimentBufferMiles', 'getPreferredMarketComp',
            'getMinYearsToRetirementComp','getMaxYearsToRetirementComp',
            'getRetiringGeneratorTechnologiesComp', 'getHazardousFacilities',
            'getTransportation', 'getSafeShutdownEarthquakeThreshold',
            'getProtectedLands', 'getSlopeThreshold', 'getStreamflowThreshold',
            'getSiteAreas'
        ]),
        somethingIsRelevant() {
            return !!Object.values(this.getAllRelevance).reduce((sum, val) => sum + val, 0);
        },
        retirementYearTicks: () => [...Array(51).keys()].map(d => [10,25,40].includes(d) ? d : null),
        retirementYearRange: {
            get: function() {
                return [this.getMinYearsToRetirementComp, this.getMaxYearsToRetirementComp]
            },
            set: function(range) {
                return this.changeYearsToRetirementComp(range);
            }
        },
        transportation: {
            get() {
                return this.getTransportation;
            },
            set(val) {
                this.changeTransportation(val);
            }
        },
        sentimentBuffer: {
            get() {
                return this.getSentimentBufferMiles;
            },
            set(val) {
                this.changeSentimentBufferMiles(val);
            }
        },
        preferredMarket: {
            get() {
                return this.getPreferredMarketComp;
            },
            set(val) {
                this.changePreferredMarketComp(val);
            }
        },
        // sviBuffer: {
        //     get() {
        //         return this.getCdcSviBufferMiles;
        //     },
        //     set(val) {
        //         this.changeCdcSviBufferMiles(val);
        //     }
        // },
        ssePGA: {
            get() {
                return this.getSafeShutdownEarthquakeThreshold;
            },
            set(val) {
                this.changeSafeShutdownEarthquakeThreshold(val);
            }
        },
        slopePCT: {
            get() {
                return this.getSlopeThreshold;
            },
            set(val) {
                this.changeSlopeThreshold(val);
            }
        },
        streamflowGPM: {
            get() {
                return this.getStreamflowThreshold.toString();
            },
            set(val) {
                this.changeStreamflowThreshold(parseInt(val));
            }
        },
    },
    methods: {
        ...mapActions([
            'restore','loadComparisonRelevance', 'changeLayerStats',
            'changeSentimentBufferMiles', 'changePreferredMarketComp',
            // 'changeCdcSviBufferMiles',
            'changeYearsToRetirementComp',
            'changeSafeShutdownEarthquakeThreshold', 'changeSlopeThreshold',
            'changeSignificance', 'changeTransportation', 'changeStreamflowThreshold'
        ]),
        verifySubmit() {
            if (!this.somethingIsRelevant) {
                showSnackbar('Something has to be relevant to proceed', 'error', 3000);
                return;
            }
            this.submit = true;
            if (this.safetyVisited && this.proximityVisited) {
                this.submitRelevance();
            }
        },
        cancelSubmit() {
            this.submit = false;
        },
        async submitRelevance() {
            this.submit = false;
            this.calculating = true;
            await this.restore();
            let res = await updateComparisonRelevance(
                this.getAccess, this.getUserId, 
                {
                    ...this.getAllRelevance,
                    sentiment_buffer_miles: this.getSentimentBufferMiles,
                    preferred_market: this.getPreferredMarketComp,
                    // svi_buffer_miles: this.getCdcSviBufferMiles,
                    min_years_to_retirement: this.getMinYearsToRetirementComp,
                    max_years_to_retirement: this.getMaxYearsToRetirementComp,
                    retiring_generator_technologies: this.getRetiringGeneratorTechnologiesComp,
                    hazardous_facilities: this.getHazardousFacilities,
                    transportation: [this.getTransportation],
                    safe_shutdown_earthquake_threshold: this.getSafeShutdownEarthquakeThreshold,
                    protected_lands: this.getProtectedLands,
                    slope_threshold: this.getSlopeThreshold,
                    streamflow_threshold: this.getStreamflowThreshold,
                }
                );
            if (res.status == 200) {
                let statsError = false;
                for (const [key, value] of Object.entries(this.getAllRelevance)) {
                    if (value) {
                        let layerName = key.replace('_relevance','');
                        this.layerTitle = layerName.replace(/_/gi, ' ').toUpperCase();
                        let layerStats = await requestComparisonStats(this.getAccess, layerName)
                        if (layerStats.status == 200) {
                            this.changeLayerStats(layerStats.data);
                            if (parseFloat(layerStats.data[layerName].range_diff) == 0) {
                                this.changeSignificance({
                                    key: layerName,
                                    val: 0
                                });
                            }
                        } else {
                            showSnackbar('There was an error fetching layer data', 'error', 3000);
                            break;
                        }
                    }
                }
                if (!statsError) {
                    this.advance();
                }
            } else {
                showSnackbar('There was an error uploading your changes', 'error', 3000);
            }
            this.calculating = false;
            this.layerTitle = null;
        }
    },
    async mounted() {
        await this.loadComparisonRelevance();
    }
}
</script>

<style scoped>
    #outer-row {
        min-height: 86vh;
    }
    #outer-row>.col:first-child, .v-tab {
        background-color: #ccdfff;
    }
    .v-tab--active {
        background-color: #E3F2FD;
        border-left: 10px solid;
    }
    /* .submit-row {
        position: fixed;
        bottom: 0;
    } */
    .v-btn.success {
        /* padding: 2rem;
        margin-bottom: 30px;
        margin-left: 30px; */
        margin-top: 30px;
    }
    .v-tabs-items {
        background-color: #E3F2FD;
    }
    #retirement-years-prompt {
        margin-bottom: 40px;
    }
    .v-input--range-slider {
        width: 50%;
    }
    .relevance-item:first-child {
        margin: 0px 30px;
    }
    .v-tabs--vertical > .v-tabs-bar .v-tab {
        margin-bottom: 2px;
    }
</style>