<template>
<v-container class="white fill-height">
    <v-layout column class="fill-height">
            <v-card-title
                class="primary white--text"
            >Layers
                <v-spacer></v-spacer>
                    <v-icon class=" white--text">mdi-layers</v-icon>
            </v-card-title>
            <v-flex flex overflow-auto>
                <v-card-text>
                    <span>Toggle layer visibility to assess conditions for development.</span>
                    <v-expansion-panels tile accordion>
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                class="blue lighten-4 blue--text text--darken-4 font-weight-bold"
                            >Reference Layers
                                <template v-slot:actions>
                                    <v-icon color="blue darken-4">
                                    $expand
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <exploration-layer-control
                                    label="Electric Energy Generators"
                                    infoLayer="electric-energy-generators"
                                    storeKey="facility_generation_geo"
                                    clickable
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Transmission Lines"
                                    infoLayer="transmission-lines"
                                    storeKey="transmission_lines_geo"
                                    clickable
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Electric Retail Service Territories"
                                    infoLayer="electric-retail-service-territories"
                                    storeKey="ers_territories_geo"
                                    clickable
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="NERC Regions"
                                    infoLayer="nerc-regions"
                                    storeKey="nerc_regions_geo"
                                    clickable
                                ></exploration-layer-control>
                                <div class="primary--text text-overline">
                                    Energy Intensive Facilities
                                    <sup>
                                        <v-icon small
                                            @click="() => showInfo('energy-intensive-facilities', 'Energy Intensive Facilities')"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </sup>
                                </div>
                                <exploration-layer-control
                                    label="Food Industry"
                                    storeKey="food_industry_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Manufacturing"
                                    storeKey="manufacturing_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Mining"
                                    storeKey="mining_geo"
                                ></exploration-layer-control>
                                <v-divider></v-divider>
                                <div class="primary--text text-overline">
                                    Climate and Economic Justice
                                    <sup>
                                        <v-icon small
                                            @click="() => showInfo('ej40', 'Climate and Economic Justice')"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </sup>
                                </div>
                                <exploration-layer-control
                                    label="Climate change"
                                    infoLayer="cejst-climate"
                                    storeKey="cejst_climate_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Clean energy and energy efficiency"
                                    infoLayer="cejst-energy"
                                    storeKey="cejst_energy_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Clean transit"
                                    infoLayer="cejst-transit"
                                    storeKey="cejst_transit_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Sustainable housing"
                                    infoLayer="cejst-housing"
                                    storeKey="cejst_housing_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Legacy pollution"
                                    infoLayer="cejst-pollution"
                                    storeKey="cejst_pollution_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Clean water and wastewater infrastructure"
                                    infoLayer="cejst-water"
                                    storeKey="cejst_water_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Health burdens"
                                    infoLayer="cejst-health"
                                    storeKey="cejst_health_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Workforce development"
                                    infoLayer="cejst-workforce"
                                    storeKey="cejst_workforce_geo"
                                    clickable
                                    hideDivider
                                ></exploration-layer-control>
                                <v-divider></v-divider>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                class="blue lighten-4 blue--text text--darken-4 font-weight-bold"
                            >Proximity Layers
                                <template v-slot:actions>
                                    <v-icon color="blue darken-4">
                                    $expand
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <exploration-layer-control
                                    label="Electric Substations"
                                    infoLayer="electric-substations"
                                    storeKey="substations_geo"
                                    clickable
                                ></exploration-layer-control>
                                <div class="primary--text text-overline">
                                    Transportation
                                    <sup>
                                        <v-icon small
                                            @click="() => showInfo('transportation', 'Transportation')"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </sup>
                                </div>
                                <exploration-layer-control
                                    label="Major Roads"
                                    storeKey="major_roads_geo"
                                    clickable
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Navigable Waterways"
                                    storeKey="navigable_waterways_geo"
                                    clickable
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Rail Lines"
                                    storeKey="rail_lines_geo"
                                    clickable
                                ></exploration-layer-control>
                                <v-divider></v-divider>
                                <exploration-variable-layer-control
                                    label=">500 People/Sq. Mile (CONUS 2018, AK and HI 2020)"
                                    infoLayer="population"
                                    :items="[
                                        {text: '2 mile buffer', value: 'population_2018_2mi_geo'},
                                        {text: '5 mile buffer', value: 'population_2018_5mi_geo'},
                                        {text: '10 mile buffer (CONUS and HI)', value: 'population_2018_10mi_geo'}
                                    ]"
                                ></exploration-variable-layer-control>
                                <exploration-variable-layer-control
                                    label=">500 People/Sq. Mile (2030) (CONUS only)"
                                    infoLayer="population"
                                    :items="[
                                        {text: '2 mile buffer', value: 'population_2030_2mi_geo'},
                                        {text: '5 mile buffer', value: 'population_2030_5mi_geo'},
                                        {text: '10 mile buffer', value: 'population_2030_10mi_geo'}
                                    ]"
                                ></exploration-variable-layer-control>
                                <exploration-variable-layer-control
                                    label="Streamflow"
                                    infoLayer="streamflow"
                                    :items="[
                                        {text: '<15K (<20K in AK) GPM (CONUS and AK)', value: 'streamflow_lt15kgpm_geo'},
                                        {text: '<50K GPM (CONUS only)', value: 'streamflow_lt50kgpm_geo'},
                                        {text: '<65K (<84K in AK) GPM (CONUS and AK)', value: 'streamflow_lt65kgpm_geo'}
                                    ]"
                                ></exploration-variable-layer-control>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                class="blue lighten-4 blue--text text--darken-4 font-weight-bold"
                            >Safety Layers
                                <template v-slot:actions>
                                    <v-icon color="blue darken-4">
                                    $expand
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="primary--text text-overline">
                                    Protected Lands
                                    <sup>
                                        <v-icon small
                                            @click="() => showInfo('protected-lands', 'Protected Lands')"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </sup>
                                </div>
                                <exploration-layer-control
                                    label="American Indian reservations (CONUS and AK)"
                                    storeKey="american_indian_reservations_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Correctional Facilities (CONUS and AK)"
                                    storeKey="correctional_facilities_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Critical Habitat (CONUS and AK)"
                                    storeKey="critical_habitat_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Forests (CONUS and AK)"
                                    storeKey="forests_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Hospitals (CONUS and AK)"
                                    storeKey="hospitals_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="National monuments (CONUS and AK)"
                                    storeKey="national_monuments_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="National, state, and local parks (CONUS and AK)"
                                    storeKey="national_state_and_local_parks_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Schools/colleges (CONUS and AK)"
                                    storeKey="schools_colleges_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Wild and scenic rivers (CONUS only)"
                                    storeKey="wild_and_scenic_rivers_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Wilderness areas (CONUS only)"
                                    storeKey="wilderness_areas_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Wildlife refuges (CONUS only)"
                                    storeKey="wildlife_refuges_geo"
                                ></exploration-layer-control>
                                <v-divider></v-divider>
                                <div class="primary--text text-overline">
                                    Hazardous Facilities
                                    <sup>
                                        <v-icon small
                                            @click="() => showInfo('hazardous-facilities', 'Hazardous Facilities')"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </sup>
                                </div>
                                <exploration-layer-control
                                    label="Airports (CONUS and AK)"
                                    storeKey="airports_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Biodiesel Plants (CONUS only)"
                                    storeKey="biodiesel_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Biological Products Manufacturing"
                                    storeKey="biological_products_mfg_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Chemical Manufacturing"
                                    storeKey="chemical_mfg_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Ethanol Plants (CONUS only)"
                                    storeKey="ethanol_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Explosives Manufacturing"
                                    storeKey="explosives_mfg_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Liquified Natural Gas Import Terminals (CONUS and AK)"
                                    storeKey="liquified_ng_import_terminals_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Lubricating Oils and Grease Plants"
                                    storeKey="lubricating_oils_and_grease_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Natural Gas Compressor Stations (CONUS only)"
                                    storeKey="ng_compressor_stations_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Natural Gas Import/Export Locations (CONUS only)"
                                    storeKey="ng_import_export_locations_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Natural Gas Processing Plants (CONUS only)"
                                    storeKey="ng_processing_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Natural Gas Storage Facilities (CONUS only)"
                                    storeKey="ng_storage_facilities_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Nitrogenous Fertilizer Plants"
                                    storeKey="nitrogenous_fertilizer_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Nuclear Fuel Plants (CONUS only)"
                                    storeKey="nuclear_fuel_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Oil Refineries"
                                    storeKey="oil_refineries_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Petroleum Pumping Stations"
                                    storeKey="petroleum_pumping_stations_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Pharmaceutical Preparations Manufacturing"
                                    storeKey="pharmaceutical_preparations_mfg_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Phosphatic Fertilizer Plants"
                                    storeKey="phosphatic_fertilizer_plants_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="POL Terminals, Storage Facilities, Tank Farms"
                                    storeKey="pol_terminals_storage_facilities_tank_farms_geo"
                                ></exploration-layer-control>
                                <v-divider></v-divider>
                                <exploration-layer-control
                                    label="Fault Lines"
                                    infoLayer="fault-lines"
                                    storeKey="fault_lines_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="Landslide Hazard (CONUS only)"
                                    infoLayer="landslide-hazard"
                                    storeKey="landslide_hazard_geo"
                                ></exploration-layer-control>
                                <exploration-variable-layer-control
                                    label="Safe Shutdown Earthquake"
                                    infoLayer="safe-shutdown-earthquake"
                                    :items="[
                                        {text: '0.3g peak ground acceleration (CONUS only)', value: 'sse_3_geo'},
                                        {text: '0.4g peak ground acceleration (CONUS only)', value: 'sse_4_geo'},
                                        {text: '0.5g peak ground acceleration (CONUS only)', value: 'sse_5_geo'},
                                        {text: '0.6g peak ground acceleration (CONUS only)', value: 'sse_6_geo'},
                                        {text: '6.5g peak ground acceleration (HI only)', value: 'sse_65_geo'},
                                        {text: '10g peak ground acceleration (HI only)', value: 'sse_10_geo'},
                                        {text: '15g peak ground acceleration (AK only)', value: 'sse_15_geo'},
                                    ]"
                                ></exploration-variable-layer-control>
                                <exploration-variable-layer-control
                                    label="Slope"
                                    infoLayer="slope"
                                    :items="[
                                        {text: 'Greater than 12%', value: 'slope_gt12pct_geo'},
                                        {text: 'Greater than 18%', value: 'slope_gt18pct_geo'}
                                    ]"
                                ></exploration-variable-layer-control>
                                <exploration-layer-control
                                    label="Open Water and Wetlands"
                                    infoLayer="open-water-wetlands"
                                    storeKey="wetlands_openwaters_geo"
                                ></exploration-layer-control>
                                <exploration-layer-control
                                    label="100 Year Floodplain (CONUS and HI)"
                                    infoLayer="floodplain-100-year"
                                    storeKey="floodplain_100yrs_geo"
                                ></exploration-layer-control>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { showLayerInfo } from '@commons/Actions';
import ExplorationLayerControl from './ExplorationLayerControl.vue';
import ExplorationVariableLayerControl from './ExplorationVariableLayerControl.vue';

export default {
    name: "ExplorationLayers",
    components: {
        ExplorationLayerControl,
        ExplorationVariableLayerControl
    },
    methods: {
        showInfo(infoLayer, layerTitle) {
            showLayerInfo(infoLayer, 'stand', 'Exploration', layerTitle);
        },
    }

}
</script>

<style scoped>
    .v-card__title {
        height: 88px;
    }
    .v-card__text {
        padding: 0;
    }
    .v-card__text>span {
        padding: 16px;
    }
    .container {
        padding: 0;
    }
</style>