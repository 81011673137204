import { createToken, refreshToken } from "@api/auth";
import { showSnackbar } from "@commons/Actions";

const state = {
    access: null,
    refresh: window.localStorage.getItem('refresh')
};

const getters = {
    isLoggedIn: state => !!state.access,
    getRefresh: state => state.refresh,
    getAccess: state => state.access
};

const actions = {
    logout: ({ commit }) => {
        commit('setAccess' , null);
        commit('setRefresh', null);
    },
    login: async ({ commit, dispatch }, payload) => {
        const res = await createToken(payload.email, payload.password);
        if (res.status === 200) {
            showSnackbar(res.message, 'success', 3000);
            commit('setAccess' , res.access);
            commit('setRefresh', res.refresh);
            window.localStorage.setItem('refresh', res.refresh);
            dispatch('loadUser', null, { root: true });
        } else {
            showSnackbar(res.message, 'error', 3000);
        }
    },
    restore: async ({ commit, getters }) => {
        const res = await refreshToken(getters.getRefresh);
        if (res.status === 200) {
            commit('setAccess', res.access);
        } else {
            commit('setAccess', null);
            commit('setRefresh', null);
            window.localStorage.setItem('refresh', null);
        }
    }
};

const mutations = {
    setAccess: (state, token) => state.access = token,
    setRefresh: (state, token) => state.refresh = token,
};

export default {
    state,
    getters,
    actions,
    mutations
}