<template>
<v-col cols=2>
    <v-checkbox
        v-model="isChecked"
        dense
        :label="facilityType"
        :disabled="isDisabled"
    ></v-checkbox>
</v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { showSnackbar } from '@commons/Actions';

export default {
    name: 'ComparisonHazardousFacility',
    props: {
        facilityType: String,
        noDataAreas: Array
    },
    computed: {
        ...mapGetters(['getHazardousFacilities','isHazardousFacility', 'getSiteAreas']),
        isChecked: {
            get: function() {
                return this.isHazardousFacility(this.facilityType);
            },
            set: function() {
                this.changeHazardousFacilities(this.facilityType);
            }
        },
        isDisabled() {
            let noDataSite = false;
            if (this.noDataAreas) {
                this.noDataAreas.forEach(area => {
                    if (this.getSiteAreas.includes(area)) {
                        noDataSite = true;
                        if (this.isHazardousFacility(this.facilityType)) {
                            this.changeHazardousFacilities(this.facilityType);
                        }
                    }
                });
            }
            if (this.getHazardousFacilities.length === 1 && 
                this.isHazardousFacility(this.facilityType)) {
                showSnackbar('At least one facility type must be selected', 'warning', 3000);
                return true;
            }  else {
                return noDataSite;
            }
        }
    },
    methods: {
        ...mapActions([
            'changeHazardousFacilities'
        ]),
    },
}
</script>
