<template>
<div id="matrix-step">
    <div class="label grey darken-1 white--text">Measure Relevance</div>
    <div id="row-wrap">
    <span class="label vert-label grey darken-1 white--text" cols="1">Range Significance</span>
    <v-container fluid>
        <v-row>
            <v-col cols="1" class="grey lighten-4"></v-col>
            <v-col class="label green darken-4 white--text">Very High</v-col>
            <v-col class="label green white--text">High</v-col>
            <v-col class="label yellow">Medium</v-col>
            <v-col class="label amber">Low</v-col>
            <v-col class="label orange white--text">Very Low</v-col>
            <v-col class="label deep-orange darken-4 white--text">Not Relevant</v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="1" class="label green darken-4 white--text">
                        Very High
                    </v-col>
                    <v-col
                        v-for="pair in min_max"
                        :key="pair[0]"
                        class="cell-col"
                    >
                        <v-card outlined tile height="100%" width="100%">
                            <v-row no-gutters
                                v-for="item in getWeightsByRange(pair[0],pair[1],41,50,true)"
                                :key="item[0]"
                                class="objective-weight"
                            >
                                <v-col>{{ item[0] }}</v-col>
                                <v-col cols="3">{{ item[1] }}</v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="label green white--text">
                        High
                    </v-col>
                    <v-col
                        v-for="pair in min_max"
                        :key="pair[0]"
                        class="cell-col"
                    >
                        <v-card outlined tile height="100%" width="100%">
                            <v-row no-gutters
                                v-for="item in getWeightsByRange(pair[0],pair[1],31,40,true)"
                                :key="item[0]"
                                class="objective-weight"
                            >
                                <v-col>{{ item[0] }}</v-col>
                                <v-col cols="3">{{ item[1] }}</v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="label yellow">
                        Medium
                    </v-col>
                    <v-col
                        v-for="pair in min_max"
                        :key="pair[0]"
                        class="cell-col"
                    >
                        <v-card outlined tile height="100%" width="100%">
                            <v-row no-gutters
                                v-for="item in getWeightsByRange(pair[0],pair[1],21,30,true)"
                                :key="item[0]"
                                class="objective-weight"
                            >
                                <v-col>{{ item[0] }}</v-col>
                                <v-col cols="3">{{ item[1] }}</v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="label amber">
                        Low
                    </v-col>
                    <v-col
                        v-for="pair in min_max"
                        :key="pair[0]"
                        class="cell-col"
                    >
                        <v-card outlined tile height="100%" width="100%">
                            <v-row no-gutters
                                v-for="item in getWeightsByRange(pair[0],pair[1],11,20,true)"
                                :key="item[0]"
                                class="objective-weight"
                            >
                                <v-col>{{ item[0] }}</v-col>
                                <v-col cols="3">{{ item[1] }}</v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="label orange white--text">
                        Very Low
                    </v-col>
                    <v-col
                        v-for="pair in min_max"
                        :key="pair[0]"
                        class="cell-col"
                    >
                        <v-card outlined tile height="100%" width="100%">
                            <v-row no-gutters
                                v-for="item in getWeightsByRange(pair[0],pair[1],1,10,true)"
                                :key="item[0]"
                                class="objective-weight"
                            >
                                <v-col>{{ item[0] }}</v-col>
                                <v-col cols="3">{{ item[1] }}</v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="label deep-orange darken-4 white--text">
                        Not Significant
                    </v-col>
                    <v-col
                        v-for="pair in min_max"
                        :key="pair[0]"
                        class="cell-col"
                    >
                        <v-card outlined tile height="100%" width="100%">
                            <v-row no-gutters
                                v-for="item in getWeightsByRange(pair[0],pair[1],0,0,true)"
                                :key="item[0]"
                                class="objective-weight"
                            >
                                <v-col>{{ item[0] }}</v-col>
                                <v-col cols="3">{{ item[1] }}</v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    </div>
    <v-btn class="success" @click="advance">Submit</v-btn>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ComparisonMatrix',
    props: {
        shown: Boolean, 
        advance: Function
    },
    data() {
        return {
            min_max: [
                [41,50], [31,40], [21,30], [11,20], [1,10], [0,0]
            ],
        }
    },
    computed: {
        ...mapGetters(['getWeightsByRange'])
    },
}
</script>

<style scoped>
    #matrix-step {
        min-height: 86vh;
        max-width: 98vw;
        padding-left: 1vw;
    }
    #row-wrap {
        display: flex;
    }
    .vert-label {
        writing-mode: vertical-rl;
        text-orientation: upright;
    }
    .label {
        text-align: center;
    }
    .cell-col {
        padding: 0;
    }
    .objective-weight {
        background-color: #fff;
    }
    .v-card {
        padding: 2px;
    }
    button {
        margin-top: 1%;
    }
</style>