import EventBus, { ACTIONS } from "./EventBus";

export const showSnackbar = (message, color=undefined, timeout=2500) => {
    EventBus.$emit(ACTIONS.SNACKBAR, message, color, timeout);
};

export const showReferenceMap = (layer, layerTitle) => {
    EventBus.$emit(ACTIONS.REFERENCE_MAP, layer, layerTitle);
};

export const showLayerInfo = (layer, tool, segment, layerTitle) => {
    EventBus.$emit(ACTIONS.LAYER_INFO, layer, tool, segment, layerTitle);
};

export const showFeatureDetail = (layer, slug) => {
    EventBus.$emit(ACTIONS.FEATURE_DETAIL, layer, slug);
}

export const showReportCard = (id, dealBreakers=false) => {
    EventBus.$emit(ACTIONS.REPORT_CARD, id, dealBreakers);
}

export const showChangePassword = () => {
    EventBus.$emit(ACTIONS.CHANGE_PASSWORD);
}

export const showResetPassword = () => {
    EventBus.$emit(ACTIONS.RESET_PASSWORD);
}

export const showTermsOfUse = () => {
    EventBus.$emit(ACTIONS.TERMS_OF_USE);
}