<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "ExplorationSidePanel",
}
</script>

<style scoped>
    @media print {
        div {
            display: none;
        }
    }
</style>