<template>
    <v-row justify="center">
        <v-dialog
            max-width="20vw"
            persistent
            :value="!Object.keys(mapOptions).length && isOpen"
        >
            <v-card>
                <v-card-text>
                    <div>Fetching {{ layerTitle }} data...</div>
                    <v-progress-linear
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            :value="Object.keys(mapOptions).length && isOpen"
            width="60vw"
            @click:outside="close"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ layerTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <highcharts
                        v-if="isOpen"
                        :constructor-type="'mapChart'"
                        :options="mapOptions"
                        :deepCopyOnUpdate="false"
                        ref="refmap"
                        class="map"
                    ></highcharts>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { requestLayerData } from '@api/layerData';
import { showSnackbar } from "@commons/Actions";
import EventBus, { ACTIONS } from '@commons/EventBus';
import { setMapOptionsByLayer } from '@commons/HighchartsMaps';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ModalReferenceMap',
    computed: {
        ...mapGetters(['getAccess']),
    },
    watch: {
        layer: {
            async handler(newLayer) {
                if (newLayer) {
                    this.isOpen = true;
                    await this.restore();
                    
                    let response = await requestLayerData(this.getAccess, newLayer);
                    
        
                    if(response.status === 200) {
                        this.mapOptions = setMapOptionsByLayer(newLayer, response.data);
                    } else {
                        this.isOpen = false;
                        showSnackbar('Error fetching map data.', 'error', 3000);
                    }
                }
            }
        }
    },
    data() {
        return {
            isOpen: false,
            layerTitle: null,
            layer: null,
            mapOptions: {},
        }
    },
    mounted() {
        EventBus.$on(ACTIONS.REFERENCE_MAP, (layer, layerTitle) => {
            this.layerTitle = layerTitle;
            this.layer = layer;
        });
    },
    methods: {
        ...mapActions(['restore']),
        close() {
            this.isOpen = false;
            this.layerTitle = null;
            this.layer = null;
            this.mapOptions = {};
        },
    }
}
</script>

<style scoped>
    .map {
        height: 70vh;
    }
</style>