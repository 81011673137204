import { API } from '../config';

export async function requestDiscoveryCountyReport(access, county_id) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    API.get(`/api/stand/discovery-county-report/${county_id}`, {headers})
    .then(res => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    });
}

export async function requestDiscoveryPriorities(access, user_id) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };
    
    const response = await API.get(`/api/stand/discovery-priorities/${user_id}`, {headers})
    .then(res => ({
        status: res.status,
        ...res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function updateDiscoveryPriorities(access, user_id, data) {
    const headers = {
        'Authorization': `JWT ${access}`
    };
    const response = await API.patch(`/api/stand/discovery-priorities/${user_id}`, data, {headers})
    .then(res => ({
        status: res.status
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestDiscoveryScores(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get('/api/stand/discovery-score/', {headers})
    .then(res => ({
        status: res.status,
        data: [...res.data]
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestDiscoverySelections(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get('/api/stand/discovery-selections/', {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function postDiscoverySelection(access, county_id) {
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const data = {
        county_id
    };

    const response = await API.post('/api/stand/discovery-selections/', data, {headers})
    .then(res => ({
        status: res.status
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}

export async function deleteDiscoverySelection(access, county_id) {
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const data = {
        county_id
    };

    const response = await API.delete('/api/stand/discovery-selections/', {headers, data})
    .then(res => ({
        status: res.status
    }))
    .catch(error => ({
        status: error.response.status,
    }));

    return response;
}
