<template>
    <div>
        <div class="text-subtitle-2 q-text">
            {{ qText }}
            <sup>
                <v-icon @click="showInfo">
                    mdi-information-outline
                </v-icon>
            </sup>
        </div>
        <slot name="primary">
            <v-select
                outlined
                dense
                :items="[
                    {text: 'Not important', value: 0},
                    {text: 'Very Low', value: 1},
                    {text: 'Low', value: 2},
                    {text: 'Medium', value: 3},
                    {text: 'High', value: 4},
                    {text: 'Very High', value: 5}
                ]"
                :value="getPriority"
                @change="onChange"
                placeholder="Select"
            ></v-select>
        </slot>
        <slot name="secondary"></slot>
        <div v-if="layerTitle"
            class="ref-map-link text-caption text-decoration-underline text-end"
            @click="showMap"
        >View {{ layerTitle }} Reference Map</div>
        <v-divider></v-divider>
    </div>
</template>

<script>
import { showLayerInfo, showReferenceMap } from '@commons/Actions';

export default {
    name: 'PriorityQuestion',
    props: {
        qText: String,
        priority: Number,
        onChange: Function,
        layerTitle: String,
        layer: String
    },
    computed: {
        getPriority() {
            return this.priority;
        }
    },
    methods: {
        showInfo() {
            showLayerInfo(this.layer, 'stand', 'Discovery', this.layerTitle);
        },
        showMap() {
            showReferenceMap(this.layer, this.layerTitle);
        }
    }

}
</script>

<style scoped>
.v-divider {
         margin: 25px 0;
     }
.q-text {
    margin-bottom: 10px;
}
.ref-map-link {
    cursor: pointer;
}
</style>