<template>
    <v-row>
        <v-col cols=8>
            <v-row>
                <v-col>
                    <div class="text-h5 font-weight-bold">Priority Match Map</div>
                    <div class="text-body-2">
                        This custom heat map shows the Development Rank for each county
                        based on your priorities. Click a county to see ranking details,
                        select a county to analyze, or download a report.
                    </div>
                </v-col>
                <v-col>
                    <v-btn
                        id="modify-priorities"
                        @click="modifyMatch()"
                    >Modify Priorities</v-btn>
                </v-col>
            </v-row>
            <v-row class="map-row">
                <v-col>
                    <discovery-match-map></discovery-match-map>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols=4>
            <discovery-match-list></discovery-match-list>
        </v-col>
    </v-row>
</template>

<script>
import DiscoveryMatchMap from './DiscoveryMatchMap.vue';
import DiscoveryMatchList from './DiscoveryMatchList.vue';
import { mapActions } from 'vuex';

export default {
    name: 'DiscoveryMatch',
    components: {
        DiscoveryMatchMap,
        DiscoveryMatchList
    },
    methods: {
        ...mapActions(['changePriorityMatchResults']),
        modifyMatch() {
            // Clear results from store, setting parent stepper to 1 (questionnaire)
            this.changePriorityMatchResults(null);
        }
    }
}
</script>


<style scoped>
    #modify-priorities {
        float: right;
    }
    .text-h5 {
        margin-bottom: 10px;
    }
    .map-row {
        height: 68vh;
    }
</style>