<template>
    <v-bottom-sheet
        inset
        v-model="isOpen"
        @click:outside="close"
    >
        <v-toolbar
            color="primary"
            dark
        >
            <template vslot:extension>
                <v-tabs v-model="activeTab">
                    <v-tab
                        v-for="k in getFeatureInfoKeys"
                        :key="k"
                    >{{ k }}</v-tab>
                </v-tabs>
            </template>
        </v-toolbar>
        <v-tabs-items v-model="activeTab" v-if="getFeatureInfo">
            <v-tab-item
                v-for="kv in Object.entries(getFeatureInfo)"
                :key="kv[0]"
            >
                <v-data-table
                    :headers="Object.keys(kv[1][0]).map(k => ({
                        text: k,
                        value: k,
                    }))"
                    :items="kv[1]"
                ></v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </v-bottom-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ExplorationFeatureInfo",
    data() {
        return {
            activeTab: ''
        }
    },
    computed: {
        ...mapGetters(['getFeatureInfo','getFeatureInfoKeys']),
        isOpen: {
            get() {
                return !!this.getFeatureInfo;
            },
            set(val) {
                this.changeFeatureInfo(val);
            }
        },

    },
    methods: {
        ...mapActions(['changeFeatureInfo']),
        close() {
            this.isOpen = null;
            this.activeTab = '';
        },
    }
}
</script>

