import { API } from './config';

export async function requestLayerInfo(access, layer, tool) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get(`/api/${tool}/layer-information/${layer}`, {headers})
    .then(res => ({
        status: res.status,
        layer_name: res.data.layer_name,
        data_currency: res.data.data_currency,
        why_it_matters: res.data.why_it_matters,
        last_update: res.data.last_update,
        descriptions: res.data.descriptions,
        layer_references: res.data.layer_references
    }))
    .catch(async error => ({
        status: error.response.status,
    }));

    return response;
}