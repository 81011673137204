import { requestUserInfo } from "@api/user";

const state = {
    user_id: null,
    full_name: null,
    email: null,
    organization: null,
};

const getters = {
    getUserId: state => state.user_id,
    getFullName: state => state.full_name,
    getEmail: state => state.email,
    getOrganization: state => state.organization
};

const actions = {
    loadUser: async ({ commit, rootGetters, dispatch }) => {
        
        const response = await requestUserInfo(rootGetters.getAccess);

        if (response.status === 200) {
            commit('setUserId', response.id);
            commit('setFullName', response.full_name);
            commit('setEmail', response.email);
            commit('setOrganization', response.organization);
        } else {
            dispatch('restore', null, { root: true });
            dispatch('loadUser');
        }
    }
};

const mutations = {
    setUserId: (state, user_id) => state.user_id = user_id,
    setFullName: (state, full_name) => state.full_name = full_name,
    setEmail: (state, email) => state.email = email,
    setOrganization: (state, organization) => state.organization = organization
};

export default {
    state,
    getters,
    actions,
    mutations
}