<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Login</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="email"
                  label="Email*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="password"
                  label="Password*"
                  type="password"
                  @keydown="passwordKeydown"
                  required
                ></v-text-field>
                <!-- <div id="fp-link" @click="forgotPassword" class="primary--text text-decoration-underline">Forgot Password?</div> -->
                <div id="fp-link" class="primary--text text-decoration-underline"><a href="https://fptz.org/accounts/reset-password/">Forgot Password?</a></div>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required <b>case-sensitive</b> field</small>
          <v-divider></v-divider>
          <div>By logging in, you agree to the Fastest Path/STAND <span id="tou-link" @click="termsOfUse" class="primary--text text-decoration-underline">Terms of Use and Privacy Statement</span>.</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-1"
            text
            @click="register"
          >
            Request Access
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onLogin"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { showTermsOfUse } from '@commons/Actions';
import { showResetPassword } from '@commons/Actions';
  export default {
    name: 'LoginWindow',
    data: () => ({
        dialog: true,
        email: '',
        password: ''
    }),
    methods: {
      termsOfUse() {
        showTermsOfUse();
      },
      forgotPassword() {
        showResetPassword();
      },
        passwordKeydown(event) {
            if (event.keyCode ===13) {
                this.onLogin();
            }
        },
        onLogin() {
            let email = this.email;
            let password = this.password;
            this.$emit('loginClick', { email, password });
        },
        register() {
          window.location.href = 'https://fptz.org/accounts/register';
        }
    }
  }
</script>

<style scoped>
  #tou-link,#fp-link {
    cursor: pointer;
  }
</style>