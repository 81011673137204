<template>
<div class="map-wrapper">
  <highcharts
    :constructor-type="'mapChart'"
    :options="mapOptions"
    class="map"
></highcharts>
<v-dialog v-model="dealBreakers">
<v-card>
  <v-card-text>hello</v-card-text>
</v-card>
</v-dialog>
</div>
</template>

<script>

import {
        usStates,
        usCounties,
        usStateBorders,
        mapOptionsBoilerPlate
    } from '@commons/HighchartsMaps';
import { mapGetters, mapActions } from 'vuex';
import { showReportCard } from '@commons/Actions';
import {
    requestDiscoverySelections,
    deleteDiscoverySelection
} from '@api/star/discovery';
    
export default {
  computed: {
    ...mapGetters(['getPriorityMatchResults','isSelectedMatchCounty','getAccess', 'routeParams', 'getUserId']),
  },
  data() {
      return {
        dealBreakers: false,
        mapOptions: {
          ...mapOptionsBoilerPlate,
          legend: {
            align: 'right',
            verticalAlign: 'top',
            x: -2,
            title: { text: null }
          },
          exporting: { enabled: false },
          colorAxis: {
            minColor: '#005036', maxColor: '#fff',
            min: 1, max: null, type: 'linear',
            labels: {
                formatter: function() {
                  if (this.isFirst) {
                    return 'High';
                  } else if (this.isLast) {
                    return 'Low';
                  } else {
                    return '';
                  }
                }
            },
        },
          series: []
      }
    }
  },
  methods: {
    ...mapActions(['changeSelectedMatchCounties']),
    drawMap(priorityData) {
      this.mapOptions.series = [];
      if (priorityData) {
        this.mapOptions.colorAxis.max = Math.max(...priorityData[1].map(d => d.ranking_));
          if (priorityData[0]) {
            // if '10' in deal breaker list
            this.mapOptions.series.push({
                type: 'map',
                name: 'Deal Breaker',
                mapData: usStates,
                data: priorityData[0].map(d => ({fips: `US${d}`})),
                dataLabels: {
                    enabled: true,
                    formatter: function(){
                      return this.point.properties['postal-code']
                    }
                },
                showInLegend: true,
                allAreas: false,
                borderColor: null,
                joinBy: "fips",
                colorAxis: false,
                color: '#fff',
                events: {
                  click: function(e) {
                    showReportCard(e.point.fips.substring(2), true); // dealBreakers flag set to true
                  }
                },
                tooltip: {
                  headerFormat: '{point.key}',
                  pointFormat: null
                },
                states: {
                    hover: {
                        brightness: 0.1,
                        borderColor: "#000",
                        borderWidth: 2
                    }
                }
            });
            // this.mapOptions.series.push({
            //     type: 'map',
            //     name: 'Deal Breaker',
            //     mapData: usCounties,
            //     data: priorityData[0].map(d => ({fips: `US${d}`})),
            //     dataLabels: {
            //         enabled: true,
            //         formatter: function(){
            //           return this.point.properties['postal-code']
            //         }
            //     },
            //     showInLegend: true,
            //     allAreas: false,
            //     borderColor: null,
            //     joinBy: "fips",
            //     colorAxis: false,
            //     color: '#fff',
            //     events: {
            //       click: function(e) {
            //         showReportCard(e.point.fips.substring(2), true); // dealBreakers flag set to true
            //       }
            //     },
            //     tooltip: {
            //       headerFormat: '{point.key}',
            //       pointFormat: null
            //     },
            //     states: {
            //         hover: {
            //             brightness: 0.1,
            //             borderColor: "#000",
            //             borderWidth: 2
            //         }
            //     }
            // });
          }

        this.mapOptions.series.push({
            type: 'map',
            name: 'Match Rank',
            cursor: 'pointer',
            mapData: usCounties,
            data: priorityData[1].map(d => ({
                                  fips: d.county_id_,
                                  value: d.ranking_
                                })),
            showInLegend: false,
            allAreas: false,
            borderColor: '#333',
            joinBy: "fips",
            events: {
              click: function(e) {
                showReportCard(e.point.fips);
              }
            },
            tooltip: {
              headerFormat: '{point.key}',
              pointFormat: '\n{point.value}',
              valueDecimals: 0,
            },
            states: {
                hover: {
                    brightness: 0.1,
                    borderColor: "#000",
                    borderWidth: 2
                }
            }
        });

        this.mapOptions.series.push({
                  type: 'mapline',
                  name: 'State borders',
                  mapData: usStateBorders,
                  showInLegend: false,
                  nullColor: '#000',
                  borderWidth: 2,
                  shadow: false,
                  colorAxis: false,
        });
      }
    }
  },
  mounted(){
    if (this.getPriorityMatchResults) {
      this.drawMap(this.getPriorityMatchResults);
    }
  },
  watch: {
    getPriorityMatchResults: function(priorityData) {
      this.drawMap(null);
      requestDiscoverySelections(this.getAccess).then(res => {
        const dealBreakers = priorityData[0] || [];
        if (res.status == 200) {
          res.data.forEach(d => {
              // Remove any selected counties from server and data store
              // based on presence in deal breaker list
              if (dealBreakers.includes(d.county_id.substring(0,2))) {
                deleteDiscoverySelection(this.getAccess, d.county_id);
                if (this.isSelectedMatchCounty(d.county_id)) {
                  this.changeSelectedMatchCounties({id: d.county_id});
                }
              // Add any other counties to the data store if they aren't
              // already there
              } else if (!this.isSelectedMatchCounty(d.county_id)){
                this.changeSelectedMatchCounties(priorityData[1]
                    .filter(p => p.county_id_ == d.county_id)
                    .map(p => ({
                      id: p.county_id_,
                      county_title: `${p.county_name_}, ${p.state_usps_abbreviation_}`,
                    }))[0]
                );
              }
            });
          this.drawMap(priorityData);
        }
      })
    }
  },
}
</script>

<style scoped>
  .map {
    height: 100%;
  }
  .map-wrapper {
    height: 100%;
    width: 97%;
  }
</style>