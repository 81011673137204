import Vue from 'vue'
import App from './App.vue'
import Home from './components/Home';
import Discovery from './components/Discovery';
import Exploration from './components/Exploration';
import Comparison from './components/Comparison';
import vuetify from '../plugins/vuetify'
import VueRouter from 'vue-router';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import mapInit from 'highcharts/modules/map';
import store from './store';

mapInit(Highcharts);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(HighchartsVue);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/', component: Home, name: 'home'},
    {path: '/discovery/', component: Discovery, name: 'discovery'},
    {path: '/exploration/', component: Exploration, name: 'exploration'},
    {path: '/comparison/', component: Comparison, name: 'comparison'},
  ]
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
