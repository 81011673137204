<template>
    <div class="relevance-item">
        <div class="text-subtitle-2 layer-title">
            {{ layerTitle }}
            <sup>
                <v-icon @click="showInfo">
                    mdi-information-outline
                </v-icon>
            </sup>
        </div>
        <slot name="restrictions"></slot>
        <div v-if="layerTitle && layer"
            class="ref-map-link text-caption text-decoration-underline text-start"
            @click="showMap"
        >View {{ layerTitle }} Reference Map</div>
        <div
            v-else
            class="text-caption text-start"
        >Reference Map Not Available</div>
    </div>
</template>

<script>
import { showLayerInfo, showReferenceMap } from '@commons/Actions';

export default {
    name: "ComparisonRestrictionRelevance",
    data() {
        return {
            storeKey: 'nuclear_restrictions',
            layerTitle: 'Nuclear Restrictions',
            layer: 'nuclear-restriction-by-state',
        }
    },
    methods: {
        showInfo() {
            showLayerInfo(this.layer, 'stand', 'Comparison', this.layerTitle);
        },
        showMap() {
            showReferenceMap(this.layer, this.layerTitle);
        },
    }
}
</script>


<style scoped>
    .relevance-item {
        background-color: #fff;
        border: 1px solid #999;
        border-radius: 10px;
        margin: 15px 30px;
        padding: 30px;
    }
    .ref-map-link {
        cursor: pointer;
    }
    .layer-title {
        margin-bottom: 10px;
    }
</style>