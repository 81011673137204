<template>
    <v-row class="significance-item" v-show="relevant">
        <v-col cols="3" class="text-subtitle-2">
            {{ layerTitle }}
            <sup>
                <v-icon @click="showInfo">
                    mdi-information-outline
                </v-icon>
            </sup>
        </v-col>
        <v-col cols="1">
            <div class="text-body-2">{{ stats.best }}</div>
            <div v-show="units" class="text-caption">{{ units }}</div>
        </v-col>
        <v-col cols="1">
            <div class="text-body-2">{{ stats.worst }}</div>
            <div v-show="units" class="text-caption">{{ units }}</div>
        </v-col>
        <v-col cols="1">
            <div class="text-body-2">{{ stats.site_avg }}</div>
            <div v-show="units" class="text-caption">{{ units }}</div>
        </v-col>
        <v-col cols="1">
            <div class="text-body-2">{{ stats.nat_avg }}</div>
            <div v-show="units" class="text-caption">{{ units }}</div>
        </v-col>
        <v-col cols="1">
            <div class="text-body-2">{{ stats.range_diff }}</div>
            <div v-show="units" class="text-caption">{{ units }}</div>
        </v-col>
        <v-col cols="4">
            <div
                v-if="noRange"
                class="text-subtitle-2 error--text"
            >No variation among sites</div>
            <v-select v-else
                outlined
                dense
                :items="[
                    {text: 'Not Significant', value: 'Not Significant'},
                    {text: 'Very Low', value: 'Very Low'},
                    {text: 'Low', value: 'Low'},
                    {text: 'Medium', value: 'Medium'},
                    {text: 'High', value: 'High'},
                    {text: 'Very High', value: 'Very High'}
                ]"
                :value="significanceBin"
                @change="changeSignificanceBin"
                placeholder="Select"
            ></v-select>
            <v-slider
                v-show="significance > 0"
                v-model="significance"
                :max="sliderMax"
                :min="sliderMin"
                :tick-labels="sliderTicks"
            ></v-slider>
            <div
                v-if="noRefMap"
                class="text-caption text-start"
            >Reference Map Not Available</div>
            <div v-else
                class="ref-map-link text-caption text-decoration-underline text-start"
                @click="showMap"
            >View {{ layerTitle }} Reference Map</div>
        </v-col>
    </v-row>
</template>

<script>
import { showLayerInfo, showReferenceMap } from '@commons/Actions';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ComparisonSignificanceItem",
    props: {
        storeKey: String,
        units: String,
        layerTitle: String,
        layer: String,
        noRefMap: Boolean,
    },
    updated() {
        if (!this.relevant || this.noRange) {
                this.changeSignificance({key: this.storeKey, val: 0});
            }
    },
    computed: {
        ...mapGetters([
                'getSignificance', 'getLayerStats',
                'getRelevance', 'getRestrictionsOverallRelevance',
            ]),
            relevant() {
                if(this.storeKey == 'nuclear_restrictions') {
                    return !!this.getRestrictionsOverallRelevance;
                } else {
                    return !!this.getRelevance(this.storeKey);
                }
            },
            significance: {
                get() {
                    return this.getSignificance(this.storeKey);
                },
                set(val) {
                    this.changeSignificance({key: this.storeKey, val});
                }
            },
            stats() {
                return this.getLayerStats(this.storeKey);
            },
            noRange() {
                if (this.stats) {
                    let rangeDiff = parseFloat(this.stats.range_diff);
                    if (isNaN(rangeDiff)) {
                        return this.stats.best == this.stats.worst;
                    } else {
                        return rangeDiff == 0;
                    }
                }
                return this.stats ? parseFloat(this.stats.range_diff) == 0 : false;
            },
            significanceBin() {
                if (this.significance > 40) {
                    return 'Very High';
                } else if (this.significance > 30) {
                    return 'High';
                } else if (this.significance > 20) {
                    return 'Medium';
                } else if (this.significance > 10) {
                    return 'Low';
                } else if (this.significance > 0) {
                    return 'Very Low';
                } else {
                    return 'Not Significant';
                }
            },
            sliderMin() {
                if (this.significance > 40) {
                    return 46;
                } else if (this.significance > 30) {
                    return 36;
                } else if (this.significance > 20) {
                    return 26;
                } else if (this.significance > 10) {
                    return 16;
                } else if (this.significance > 0) {
                    return 6;
                } else {
                    return 0;
                }
            },
            sliderMax() {
                if (this.significance > 40) {
                    return 50;
                } else if (this.significance > 30) {
                    return 40;
                } else if (this.significance > 20) {
                    return 30;
                } else if (this.significance > 10) {
                    return 20;
                } else if (this.significance > 0) {
                    return 10;
                } else {
                    return 0;
                }
            },
        sliderTicks() {
            const ticks = [];
            for (let i=this.sliderMin; i <= this.sliderMax; i++) {
                if (i == this.sliderMin || i % 5 == 0) {
                    ticks.push(i);
                } else {
                    ticks.push(null);
                }
            }
            return ticks;
        }
    },
    methods: {
        ...mapActions(['changeSignificance']),
        showInfo() {
            showLayerInfo(this.layer, 'stand', 'Comparison', this.layerTitle);
        },
        showMap() {
            showReferenceMap(this.layer, this.layerTitle);
        },
        changeSignificanceBin(value) {
            switch(value){
                case 'Very High':
                    this.significance = 48;
                    break;
                case 'High':
                    this.significance = 38;
                    break;
                case 'Medium':
                    this.significance = 28;
                    break;
                case 'Low':
                    this.significance = 18;
                    break;
                case 'Very Low':
                    this.significance = 8;
                    break;
                default:
                    this.significance = 0;
                    break;
            }
        }
    }
}
</script>


<style scoped>
    .ref-map-link {
        cursor: pointer;
    }
</style>