var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-subtitle-2 q-text"},[_vm._v(" "+_vm._s(_vm.qText)+" "),_c('sup',[_c('v-icon',{on:{"click":_vm.showInfo}},[_vm._v(" mdi-information-outline ")])],1)]),_vm._t("primary",function(){return [_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                {text: 'Not important', value: 0},
                {text: 'Very Low', value: 1},
                {text: 'Low', value: 2},
                {text: 'Medium', value: 3},
                {text: 'High', value: 4},
                {text: 'Very High', value: 5}
            ],"value":_vm.getPriority,"placeholder":"Select"},on:{"change":_vm.onChange}})]}),_vm._t("secondary"),(_vm.layerTitle)?_c('div',{staticClass:"ref-map-link text-caption text-decoration-underline text-end",on:{"click":_vm.showMap}},[_vm._v("View "+_vm._s(_vm.layerTitle)+" Reference Map")]):_vm._e(),_c('v-divider')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }