<template>
    <div>
        <v-row id="outer-row" align="stretch" no-gutters>
            <v-col cols="2">
                <v-tabs vertical v-model="tab" background-color="#E3F2FD">
                    <v-tab key="Socioeconomic">Socioeconomic</v-tab>
                    <v-tab key="Safety" @click="safetyVisited = true">Safety</v-tab>
                    <v-tab key="Proximity" @click="proximityVisited = true">Proximity</v-tab>
                </v-tabs>
                <v-row justify="space-around" class="submit-row">
                    <v-btn class="success" width="66%"
                        @click="verifySubmit">Submit</v-btn>
                </v-row>
            </v-col>
            <v-col cols="10">
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        key="Socioeconomic"
                    >
                    <v-row>
                        <v-col cols="3"></v-col>
                        <v-col cols="1" class="text-subtitle-2">Best</v-col>
                        <v-col cols="1" class="text-subtitle-2">Worst</v-col>
                        <v-col cols="1" class="text-subtitle-2">Site Avg</v-col>
                        <v-col cols="1" class="text-subtitle-2">National Avg</v-col>
                        <v-col cols="1" class="text-subtitle-2">|Best - Worst|</v-col>
                        <v-col cols="4"></v-col>
                    </v-row>
                        <comparison-significance-item 
                            storeKey="nuclear_restrictions"
                            layer-title="Nuclear Restrictions"
                            layer="nuclear-restriction-by-state"
                            units="relevance sum"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="energy_price"
                            layer-title="Energy Price"
                            layer="retail-energy-price-by-state"
                            units="cents/kWh"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="net_electricity_imports"
                            layer-title="Net Electricity Imports"
                            layer="net-electricity-imports-by-state"
                            units="million kWh/year"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="nuclear_sentiment"
                            layer-title="Nuclear Sentiment"
                            layer="nuclear-sentiment-by-county"
                            units="percentile"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="nuclear_inclusive_policy"
                            layer-title="Nuclear Inclusive Policy"
                            layer="nuclear-inclusive-policy-by-state"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="market_regulation"
                            layer-title="Market Regulation"
                            layer="electricity-market-type-by-state"
                            units="preference match"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="labor_rate"
                            layer-title="Construction Labor Rate"
                            layer="construction-mean-annual-wage-by-state"
                            units="USD/year"
                        ></comparison-significance-item>
                    </v-tab-item>
                    <v-tab-item
                        key="Safety"
                    >
                    <v-row>
                        <v-col cols="3"></v-col>
                        <v-col cols="1" class="text-subtitle-2">Best</v-col>
                        <v-col cols="1" class="text-subtitle-2">Worst</v-col>
                        <v-col cols="1" class="text-subtitle-2">Site Avg</v-col>
                        <v-col cols="1" class="text-subtitle-2">National Avg</v-col>
                        <v-col cols="1" class="text-subtitle-2">|Best - Worst|</v-col>
                        <v-col cols="4"></v-col>
                    </v-row>
                        <!-- <comparison-significance-item 
                            storeKey="cdc_svi"
                            layer-title="Social Vulnerability Index"
                            layer="social-vulnerability-index-by-county"
                            units="percentile"
                        ></comparison-significance-item> -->
                        <comparison-significance-item 
                            storeKey="protected_lands"
                            layer-title="Protected Lands"
                            layer="protected-lands"
                            :noRefMap="true"
                            units="count intersecting"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="hazardous_facilities"
                            layer-title="Hazardous Facilities"
                            layer="hazardous-facilities"
                            :noRefMap="true"
                            units="count within 5 miles"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="fault_lines"
                            layer-title="Fault Lines"
                            layer="fault-lines"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="landslide_hazard"
                            layer-title="Landslide Hazard"
                            layer="landslide-hazard"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="safe_shutdown_earthquake"
                            layer-title="Safe Shutdown Earthquake"
                            layer="safe-shutdown-earthquake"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="one_hundred_year_flood"
                            layer-title="100 Year Flood"
                            layer="one-hundred-year-flood"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="open_water_and_wetlands"
                            layer-title="Open Waters and Wetlands"
                            layer="open-water-and-wetlands"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="slope"
                            layer-title="Slope"
                            layer="slope"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                    </v-tab-item>
                    <v-tab-item
                        key="Proximity"
                    >
                    <v-row>
                        <v-col cols="3"></v-col>
                        <v-col cols="1" class="text-subtitle-2">Best</v-col>
                        <v-col cols="1" class="text-subtitle-2">Worst</v-col>
                        <v-col cols="1" class="text-subtitle-2">Site Avg</v-col>
                        <v-col cols="1" class="text-subtitle-2">National Avg</v-col>
                        <v-col cols="1" class="text-subtitle-2">|Best - Worst|</v-col>
                        <v-col cols="4"></v-col>
                    </v-row>
                        <!-- <comparison-significance-item 
                            storeKey="airports"
                            layer-title="Airports"
                            layer=""
                            units="miles"
                        ></comparison-significance-item> -->
                        <comparison-significance-item 
                            storeKey="population"
                            layer-title="Population"
                            layer="population"
                            units="miles"
                            :noRefMap="true"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="operating_nuclear"
                            layer-title="Operating Nuclear Facilities"
                            layer="nuclear-facility-summary-by-county"
                            units="miles"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="nuclear_r_and_d"
                            layer-title="Nuclear Research &amp; Development"
                            layer="nuclear-r-and-d-by-county"
                            units="count within 100 miles"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="electrical_substations"
                            layer-title="Substations"
                            layer="electric-substations"
                            units="miles"
                            :noRefMap="true"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="generator_retirement"
                            layer-title="Generator Retirement"
                            layer="retiring-generator-summary-by-county"
                            units="miles"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="transportation"
                            layer-title="Transportation"
                            layer="transportation"
                            units="miles"
                            :noRefMap="true"
                        ></comparison-significance-item>
                        <comparison-significance-item 
                            storeKey="streamflow"
                            layer-title="Streamflow"
                            layer="streamflow"
                            :noRefMap="true"
                            units="Yes/No"
                        ></comparison-significance-item>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog
            max-width="500px"
            persistent
            :value="(!safetyVisited || !proximityVisited) && submit"
        >
            <v-card>
                <v-card-title class="justify-center">
                    <v-icon x-large>mdi-alert-circle</v-icon>
                </v-card-title>
                <v-card-text>
                    <p>
                        You are submitting the Significance Form without having visited all 
                        objective sections (Socioeconomic, Safety, and Proximity).
                    </p>
                    <p>
                        You are not required to have significance values set for each objective,
                        however, it is recommended that you review each one before proceeding.
                        Any measures left as 'Not Significant' will be excluded from the rest of
                        this analysis.
                    </p>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="cancelSubmit"
                >
                    Return to Form
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="submitSignificance"
                >
                    Submit Anyway
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="250px"
            persistent
            :value="calculating"
        >
            <v-card>
                <v-card-text>
                    <div>Calculating results...</div>
                    <v-progress-linear
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ComparisonSignificanceItem from './ComparisonSignificanceItem.vue';
import { updateComparisonSignificance, requestComparisonResults } from '@api/star/comparison';
import { showSnackbar } from '@commons/Actions';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ComparisonSignificanceForm',
    components: {
        ComparisonSignificanceItem
    },
    props: {
        advance: Function
    },
    data() {
        return {
            tab: null,
            safetyVisited: false,
            proximityVisited: false,
            submit: false,
            calculating: false,
        }
    },
    computed: {
        ...mapGetters([
            'getAccess','getUserId','getAllSignificance',
            'getRelevance', 'getRestrictionsOverallRelevance',
        ]),
        somethingIsSignificant() {
            return !!Object.entries(this.getAllSignificance)
                        .reduce((sum, [key, val]) => {
                            let relevance;
                            if (/^nuclear_restrictions/.test(key)) {
                                relevance = this.getRestrictionsOverallRelevance;
                            } else {
                                relevance = this.getRelevance(key.replace('_significance', ''));
                            }
                            return sum + (val && relevance)
                        }, 0);
        },
    },
    methods: {
        ...mapActions(['restore','loadComparisonSignificance', 'changeComparisonResults']),
        verifySubmit() {
            if (!this.somethingIsSignificant) {
                showSnackbar('Something has to be significant to proceed', 'error', 3000);
                return;
            }
            this.submit = true;
            if (this.safetyVisited && this.proximityVisited) {
                this.submitSignificance();
            }
        },
        cancelSubmit() {
            this.submit = false;
        },
        async submitSignificance() {
            this.submit = false;
            this.calculating = true;
            await this.restore();
            let res = await updateComparisonSignificance(this.getAccess, this.getUserId, this.getAllSignificance);
            if (res.status == 200) {
                res = await requestComparisonResults(this.getAccess);
            } else {
                showSnackbar('There was an error uploading your changes', 'error', 3000);
                return;
            }
            if (res.status == 200) {
                this.changeComparisonResults(res.data);
                this.advance();
            }
            else {
                showSnackbar('There was an error fetching result data', 'error', 3000);
            }
            this.calculating = false;
        }
    },
    async mounted() {
        await this.loadComparisonSignificance();
    }
}
</script>

<style scoped>
    #outer-row {
        min-height: 86vh;
    }
    #outer-row>.col:first-child, .v-tab {
        background-color: #ccdfff;
    }
    .v-tab--active {
        background-color: #E3F2FD;
        border-left: 10px solid;
    }
    .v-btn.success {
        margin-top: 30px;
    }
    .v-tabs-items {
        background-color: #E3F2FD;
    }
    .v-tabs-items .row {
        background-color: #fff;
        border: 1px solid #999;
        margin: 0 30px;
    }
    .v-tabs-items .row:not(:first-of-type) {
        border-top: none;
    }
    .v-tabs-items .row:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .v-tabs-items .row:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .v-tabs--vertical > .v-tabs-bar .v-tab {
        margin-bottom: 2px;
    }
</style>