import { API } from './config';

export async function requestLayerData(access, layer, slug, queryString) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    let apiBranch;

    switch (layer) {
        case 'construction-mean-annual-wage-by-state':
        case 'labor-rate-by-county':
            apiBranch = 'bls';
            break;
        case 'social-vulnerability-index-by-county':
            apiBranch = 'cdc';
            break;
        case 'net-electricity-imports-by-state':
        case 'retail-energy-price-by-state':
        case 'energy-source-by-state':
        case 'utility-nuclear-experience-by-county':
        case 'nuclear-facility-summary-by-county':
        case 'retiring-generator-summary-by-county':
        case 'facility':
        case 'select-generator-retirement':
            apiBranch = 'eia';
            break;
        case 'electricity-market-type-by-state':
            apiBranch = 'epa';
            break;
        case 'nuclear-r-and-d-by-county':
        case 'school-with-nuclear-program':
        case 'national-lab':
            apiBranch = 'fptz'
            break;
        case 'nuclear-restriction-by-state':
        case 'nuclear-inclusive-policy-by-state':
            apiBranch = 'ncsl';
            break;
        case 'nuclear-sentiment-by-county':
        case 'nuclear-sentiment-by-state':
            apiBranch = 'nirr';
            break;
        default:
            return {status: 404};
    }

    const uri = `api/${apiBranch}/${layer}${slug ? `/${slug}` : ''}${queryString ? `?${queryString}` : ''}`;

    const response = await API.get(uri, {headers})
    .then(res => ({
        status: res.status,
        data: res.data
    }))
    .catch(async error => ({
        status: error.response.status,
    }));

    return response;
}