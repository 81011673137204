import { requestDiscoveryPriorities } from "@api/star/discovery";

const state = {
    nuclear_restrictions: [],
    energy_price: null,
    nuclear_inclusive_policy: null,
    net_electricity_imports: null,
    market_regulation: null,
    preferred_market: null,
    nuclear_sentiment: null,
    generator_retirement: null,
    min_years_to_retirement: null,
    max_years_to_retirement: null,
    retiring_generator_technologies: [],
    operating_nuclear_facilities: null,
    proximity_to_nuclear_r_and_d: null,
    nuclear_experienced_utility: null,
    labor_rate: null,
    priority_match_results: null,
    selected_match_counties: [],

};

const getters = {
    getNuclearRestrictions: state => state.nuclear_restrictions,
    isNuclearRestrictionDealBreaker: state => restriction_id => state.nuclear_restrictions.includes(restriction_id),
    getEnergyPrice: state => state.energy_price,
    getNuclearInclusivePolicy: state => state.nuclear_inclusive_policy,
    getNetElectricityImports: state => state.net_electricity_imports,
    getMarketRegulation: state => state.market_regulation,
    getPreferredMarket: state => state.preferred_market,
    getNuclearSentiment: state => state.nuclear_sentiment,
    getGeneratorRetirement: state => state.generator_retirement,
    getMinYearsToRetirement: state => state.min_years_to_retirement,
    getMaxYearsToRetirement: state => state.max_years_to_retirement,
    getRetiringGeneratorTechnologies: state => state.retiring_generator_technologies,
    isRetiringTechnology: state => technology => state.retiring_generator_technologies.includes(technology),
    getOperatingNuclearFacilities: state => state.operating_nuclear_facilities,
    getProximityToNuclearRAndD: state => state.proximity_to_nuclear_r_and_d,
    getNuclearExperiencedUtility: state => state.nuclear_experienced_utility,
    getLaborRate: state => state.labor_rate,
    getPriorityMatchResults: state => state.priority_match_results,
    getPriorityMatchCounty: state => county_id => state.priority_match_results[1].filter(d => d.county_id_ === county_id)[0],
    getSelectedMatchCounties: state => state.selected_match_counties,
    maxCountySelectionReached: state => state.selected_match_counties.length > 4,
    isSelectedMatchCounty: state => county_id => state.selected_match_counties.map(d => d.id).includes(county_id),
};

const actions = {
    
    loadDiscoveryPriorities: async ({ commit, rootGetters, dispatch }) => {
        const response = await requestDiscoveryPriorities(rootGetters.getAccess, rootGetters.getUserId);

        if(response.status === 200) {
            commit('setNuclearRestrictions', response.nuclear_restrictions);
            commit('setEnergyPrice', response.energy_price);
            commit('setNuclearInclusivePolicy', response.nuclear_inclusive_policy);
            commit('setNetElectricityImports', response.net_electricity_imports);
            commit('setMarketRegulation', response.market_regulation);
            commit('setPreferredMarket', response.preferred_market);
            commit('setNuclearSentiment', response.nuclear_sentiment);
            commit('setGeneratorRetirement', response.generator_retirement);
            commit('setMinYearsToRetirement', response.min_years_to_retirement);
            commit('setMaxYearsToRetirement', response.max_years_to_retirement);
            if (response.retiring_generator_technologies.length) {
                commit('setRetiringGeneratorTechnologies', response.retiring_generator_technologies);
            } else {
                commit('setRetiringGeneratorTechnologies', ['Coal','Natural Gas','Nuclear'])
            }
            commit('setOperatingNuclearFacilities', response.operating_nuclear_facilities);
            commit('setProximityToNuclearRAndD', response.proximity_to_nuclear_r_and_d);
            commit('setNuclearExperiencedUtility', response.nuclear_experienced_utility);
            commit('setLaborRate', response.labor_rate);
        } else {
            dispatch('restore', null, { root: true });
            dispatch('loadDiscoveryPriorities');
        }
    },

    changeNuclearRestrictions: ({ commit, state }, value) => {
        let nuclearRestrictions = [...state.nuclear_restrictions];
        
        nuclearRestrictions = nuclearRestrictions.includes(value) ?
            nuclearRestrictions.filter(d => d != value) : nuclearRestrictions.concat(value);

        if (value == '6') {
            nuclearRestrictions = nuclearRestrictions.includes('6') ?
            nuclearRestrictions.concat('10') : nuclearRestrictions.filter(d => d != '10');
        }

        commit('setNuclearRestrictions', nuclearRestrictions);
    },

    changeRetiringGeneratorTechnologies: ({ commit, state }, value) => {
        let retiringGeneratorTechnologies = [...state.retiring_generator_technologies];
        
        retiringGeneratorTechnologies = retiringGeneratorTechnologies.includes(value) ?
            retiringGeneratorTechnologies.filter(d => d != value) : retiringGeneratorTechnologies.concat(value);

        commit('setRetiringGeneratorTechnologies', retiringGeneratorTechnologies);
    },

    changeSelectedMatchCounties: ({ commit, state }, value) => {
        let selectedMatchCounties= [...state.selected_match_counties];

        let selectMatchCountyIDs = selectedMatchCounties.map(d => d.id);
        
        selectedMatchCounties = selectMatchCountyIDs.includes(value.id) ?
            selectedMatchCounties.filter(d => d.id != value.id) : selectedMatchCounties.concat(value);

        commit('setSelectedMatchCounties', selectedMatchCounties);
    },

    changeEnergyPrice: ({ commit }, value) => commit('setEnergyPrice', value),
    changeMarketRegulation: ({ commit }, value) => commit('setMarketRegulation', value),
    changePreferredMarket: ({ commit }, value) => commit('setPreferredMarket', value),
    changeNuclearInclusivePolicy: ({ commit }, value) => commit('setNuclearInclusivePolicy', value),
    changeNuclearSentiment: ({ commit }, value) => commit('setNuclearSentiment', value),
    changeOperatingNuclearFacilities: ({ commit }, value) => commit('setOperatingNuclearFacilities', value),
    changeGeneratorRetirement: ({ commit }, value) => commit('setGeneratorRetirement', value),
    changeLaborRate: ({ commit }, value) => commit('setLaborRate', value),
    changeNuclearExperiencedUtility: ({ commit }, value) => commit('setNuclearExperiencedUtility', value),
    changeNetElectricityImports: ({ commit }, value) => commit('setNetElectricityImports', value),
    changeProximityToNuclearRAndD: ({ commit }, value) => commit('setProximityToNuclearRAndD', value),
    changeYearsToRetirement: ({ commit }, range) => {
        commit('setMinYearsToRetirement', range[0]);
        commit('setMaxYearsToRetirement', range[1]);
    },
    changePriorityMatchResults: ({ commit }, data) => commit('setPriorityMatchResults', data),
};

const mutations = {
    setNuclearRestrictions: (state, nuclear_restrictions) => state.nuclear_restrictions = nuclear_restrictions,
    setEnergyPrice: (state, energy_price) => state.energy_price = energy_price,
    setNuclearInclusivePolicy: (state, nuclear_inclusive_policy) => state.nuclear_inclusive_policy = nuclear_inclusive_policy,
    setNetElectricityImports: (state, net_electricity_imports) => state.net_electricity_imports = net_electricity_imports,
    setMarketRegulation: (state, market_regulation) => state.market_regulation = market_regulation,
    setPreferredMarket: (state, preferred_market) => state.preferred_market = preferred_market,
    setNuclearSentiment: (state, nuclear_sentiment) => state.nuclear_sentiment = nuclear_sentiment,
    setGeneratorRetirement: (state, generator_retirement) => state.generator_retirement = generator_retirement,
    setMinYearsToRetirement: (state, min_years_to_retirement) => state.min_years_to_retirement = min_years_to_retirement,
    setMaxYearsToRetirement: (state, max_years_to_retirement) => state.max_years_to_retirement = max_years_to_retirement,
    setRetiringGeneratorTechnologies: (state, retiring_generator_technologies) => state.retiring_generator_technologies = retiring_generator_technologies,
    setOperatingNuclearFacilities: (state, operating_nuclear_facilities) => state.operating_nuclear_facilities = operating_nuclear_facilities,
    setProximityToNuclearRAndD: (state, proximity_to_nuclear_r_and_d) => state.proximity_to_nuclear_r_and_d = proximity_to_nuclear_r_and_d,
    setNuclearExperiencedUtility: (state, nuclear_experienced_utility) => state.nuclear_experienced_utility = nuclear_experienced_utility,
    setLaborRate: (state, labor_rate) => state.labor_rate = labor_rate,
    setPriorityMatchResults: (state, priority_match_results) => state.priority_match_results = priority_match_results,
    setSelectedMatchCounties: (state, selected_match_counties) => state.selected_match_counties = selected_match_counties,
};

export default {
    state,
    getters,
    actions,
    mutations
};