<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      max-width="600px"
      @click:outside="close"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Terms of Use</span>
        </v-card-title>
        <v-card-text>
            <ul>
              <li>Use the STAND tool solely for research or comparative purposes and not for final decision-making purposes.</li>
              <li>Make no use of the identity of any personal or identity related information, discovered intentionally or inadvertently, and to advise the Fastest Path to Zero at the University of Michigan of any such discovery</li>
              <li>Cite the University of Michigan and all data sources and documentation in your work that makes use of the data and documentation. Authors of publications based on the STAND tool should send citations of their published works to the Fastest Path to Zero at the University of Michigan for inclusion in our list of related publications.</li>
              <li>You acknowledge that the original collector of the data and developer of the tool, Fastest Path to Zero, the University of Michigan, and the relevant funding agency/agencies bear no responsibility for use of the tool and data upon which it is based, or for interpretations or inferences based upon such uses.</li>
            </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import EventBus, { ACTIONS } from '@commons/EventBus';
  export default {
    name: 'TermsOfUse',
    data: () => ({
        isOpen: false,
    }),
    mounted() {
        EventBus.$on(ACTIONS.TERMS_OF_USE, () => {
            this.isOpen = true;
        });
    },
    methods: {
        close() {
          this.isOpen = false;
        }
    }
  }
</script>