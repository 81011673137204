<template>
    <v-sheet
            id="loading"
            v-if="loading"
            height="100vh"
            color="rgba(0,0,0,0)"
    >
        <v-progress-circular
            indeterminate
            color="primary"
        >
        </v-progress-circular>
    </v-sheet>
    <div v-else>
    <v-container>
        <div>You have {{ siteCount }} site{{ siteCount == 1 ? '' : 's'}} selected.</div>
        <div v-show="siteCount < 2">Select between 2-20 sites to compare.</div>
        <v-row v-show="siteCount > 0" justify="start" class="site-list">
            <comparison-review-sites-list-item class="col-3"
                v-for="(site, index) in getSites" :key="site.id" :id="site.id"
                :name="site.site_name" :lat="site.lat_coord" :lng="site.lng_coord"
                :class="{'top-site' : index < 4}"
            ></comparison-review-sites-list-item>
            <v-spacer></v-spacer>
        </v-row>
        <v-row justify="space-around">
            <v-btn v-show="siteCount > 1" class="success" width="300px"
                @click="compareSites">Compare Sites</v-btn>
        </v-row>
        <v-spacer></v-spacer>
        <div v-show="siteCount < 20" class="add-sites">
            <div>Add Sites:</div>
            <div>
                <v-spacer></v-spacer>
                <router-link to="/exploration" class="text-decoration-none">
                    <v-btn rounded>
                        <v-icon>mdi-map-marker-outline</v-icon>
                        Click on Map</v-btn>
                </router-link>
                <v-btn @click="() => this.addingSitesByFile = true" rounded>
                    <v-icon>mdi-map-marker-outline</v-icon>
                    Upload a CSV</v-btn>
                <v-btn @click="() => this.addingSiteByCoords = true" rounded>
                        <v-icon>mdi-map-marker-outline</v-icon>
                    Enter Coordinates</v-btn>
                <v-spacer></v-spacer>
            </div>
        </div>
    </v-container>
        <v-dialog
            v-model="addingSiteByCoords"
            @click:outside="cancelSite"
            width="500"
        >
            <v-card>
                <v-toolbar color="primary" dark> Add a Site</v-toolbar>
                <v-card-text>
                    <v-text-field
                        label="Name of Site"
                        v-model="newSiteName"
                        :rules="siteRules.name"
                        required
                    ></v-text-field>
                    <v-text-field
                        label="Latitude"
                        v-model="newSiteLatitude"
                        :rules="siteRules.latitude"
                        required
                    ></v-text-field>
                    <v-text-field
                        label="Longitude"
                        v-model="newSiteLongitude"
                        :rules="siteRules.longitude"
                        required
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="justify-start">
                    <v-btn text :disabled="isRuleBreaker"
                        color="primary" @click="() => addSite(false)"
                    >
                        Save Site
                    </v-btn>
                    <v-btn text :disabled="isRuleBreaker"
                        color="primary" @click="() => addSite(true)"
                    >
                        Save Site and Add Another
                    </v-btn>
                    <v-btn
                        text
                        color="blue-grey lighten-2"
                        @click="cancelSite"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="addingSitesByFile"
            @click:outside="cancelSite"
            width="500"
        >
            <v-card>
                <v-toolbar color="primary" dark>Upload a CSV of Sites</v-toolbar>
                <v-card-text>
                    <div>No sites will be uploaded if any errors are encountered.</div>
                    <v-file-input
                        :rules="fileRules"
                        accept=".csv"
                        placeholder="Click to pick a file"
                        prepend-icon="mdi-file-upload-outline"
                        label="CSV of sites"
                        v-model="file"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions class="justify-start">
                    <v-btn text :disabled="!fileReady"
                        color="primary" @click="() => uploadSites()"
                    >
                        Upload File
                    </v-btn>
                    <v-btn
                        text
                        color="blue-grey lighten-2"
                        @click="cancelSite"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="250px"
            persistent
            :value="reviewing"
        >
            <v-card>
                <v-card-text>
                    <div>Reviewing sites...</div>
                    <v-progress-linear
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { showSnackbar } from "@commons/Actions";
import {
    requestSites,
    postSite
} from "@api/star/comparison";
import ComparisonReviewSitesListItem from './ComparisonReviewSitesListItem.vue';
import { requestSiteAllData } from '@api/star/comparison';

export default {
    name: 'ComparisonReviewSites',
    components: {
        ComparisonReviewSitesListItem
    },
    props: {
        advance: Function
    },
    async mounted() {
        if (!this.getSites.length) {
            await this.restore();
            await requestSites(this.getAccess).then(res => {
                if (res.status == 200 && res.data.length) {
                    res.data.forEach(d => {
                        this.changeSites({
                            id: d.id, site_name: d.site_name,
                            lng_coord: d.lng_coord, lat_coord: d.lat_coord
                        });
                    });
                }
            });
        }
        this.loading = false;
    }, 
    data() {
        return {
            loading: true,
            reviewing: false,
            addingSiteByCoords: false,
            addingSitesByFile: false,
            newSiteName: '',
            newSiteLatitude: null,
            newSiteLongitude: null,
            file: null,
            fileRules: [
                value => !value || value.size < 3000 || 'File size should be less than 3KB'
            ],
            siteRules: {
                name : [
                    val => !!val.trim().length || 'Invalid site name',
                    val => !this.getSites.map(d => d.site_name).includes(val.trim()) || 'Site name already exists'
                ],
                latitude: [
                    val => /^-?\d+(\.\d+)?$/.test(val) || 'Not a valid number',
                    val => {
                        const numVal = parseFloat(val);
                        return numVal > 18.91 && numVal < 71.38 || 'Out of range'
                    }
                ],
                longitude: [
                    val => /^-?\d+(\.\d+)?$/.test(val) || 'Not a valid number',
                    val => {
                        const numVal = parseFloat(val);
                        return numVal > -168 && numVal < -66.973 || 'Out of range'
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters(['getAccess','getSites']),
        siteCount() {
            return this.getSites.length;
        },
        isRuleBreaker() {
            return !this.newSiteName.trim().length || !!this.getSites.map(d => d.site_name).includes(this.newSiteName.trim()) ||
                    !/^-?\d+(\.\d+)?$/.test(this.newSiteLatitude) || !/^-?\d+(\.\d+)?$/.test(this.newSiteLongitude) ||
                    this.newSiteLatitude < 18.91 || this.newSiteLatitude > 71.38 || this.newSiteLongitude < -168 ||
                    this.newSiteLongitude > -66.973;
        },
        fileReady() {
            return !!this.file;
        }
    },
    methods: {
        ...mapActions(['restore', 'changeSites', 'changeNoDataSite','changeRelevance', 'changeSiteAreas']),
        async compareSites() {
            this.reviewing = true;
            await this.restore();
            const res = await requestSiteAllData(this.getAccess);

            if (res.status == 200) {
                const site_areas = res.data.site_areas;
                this.changeSiteAreas(site_areas);
                if (site_areas.includes('HI')){
                    // Set 0 relevance for all layers that are not available in HI
                    this.changeRelevance({key: 'nuclear_sentiment', val: 0});
                    this.changeRelevance({key: 'protected_lands', val: 0});
                    this.changeRelevance({key: 'landslide_hazard', val: 0});
                    this.changeRelevance({key: 'streamflow', val: 0});
    
                }
                if (site_areas.includes('AK')) {
                    // Set 0 relevance for all layers that are not available in AK
                    this.changeRelevance({key: 'nuclear_sentiment', val: 0});
                    this.changeRelevance({key: 'landslide_hazard', val: 0});
                    this.changeRelevance({key: 'one_hundred_year_flood', val: 0});

                }
                this.advance();
            } else {
                showSnackbar('Connection Error. Please try again.', 'error', 3000);
            }
            this.reviewing = false;
        },
        async addSite(addAnother) {
            await this.restore();
            const res = await postSite(this.getAccess, this.newSiteName, this.newSiteLongitude, this.newSiteLatitude);
            if (res.status == 201) {
                showSnackbar('Site added!', 'success', 3000);
                this.changeSites({
                    id: res.data.id,
                    site_name: res.data.site_name,
                    lng_coord: res.data.lng_coord,
                    lat_coord: res.data.lat_coord
                });
            } else if(res.status == 406) {
                showSnackbar('Site rejected; no data coverage', 'error', 3000)
            } else {
                showSnackbar('ERROR - site was not added', 'error', 3000);
            }
            if (!addAnother) {
                this.cancelSite()
            }
        },
        cancelSite() {
            this.addingSiteByCoords = false;
            this.addingSitesByFile = false;
            this.newSiteName = '';
            this.newSiteLatitude = null;
            this.newSiteLongitude = null;
        },
        uploadSites() {

            const coordIsBad = (coord, min, max) => {
                return !(/^-?\d+(\.\d+)?$/.test(coord)) || parseFloat(coord) < min || parseFloat(coord) > max;
            }

            const callback = async (result) => {
                const sites = [...result.trim().split(/\r?\n/).map(d => d.split(',').map(d => d.trim()))];
                const header = sites.shift();
                const siteNameIndex = header.indexOf('site_name');
                const xIndex = header.indexOf('x');
                const yIndex = header.indexOf('y');
                
                if (siteNameIndex < 0) {
                    showSnackbar('Could not find \'site_name\' in header', 'error', 3000);
                    this.file = null;
                    return;
                }
                const siteNames = sites.map(d => d[siteNameIndex]);
                const existingSiteNames = this.getSites.map(d => d.site_name);
                
                if (!!(siteNames.filter((d, i, a) => a.indexOf(d) === i).length != siteNames.length) ||
                    !!siteNames.filter(d => existingSiteNames.includes(d)).length) {
                    showSnackbar('A site name is duplicated', 'error', 3000);
                    this.file = null;
                    return;
                }
                if (xIndex < 0) {
                    showSnackbar('Could not find \'x\' in header', 'error', 3000);
                    this.file = null;
                    return;
                }
                const lngs = sites.map(d => d[xIndex]);
                console.log(lngs)
                if (lngs.filter(d => coordIsBad(d, -168, -66.973)).length) {
                    showSnackbar('A site longitude is out of range', 'error', 3000);
                    this.file = null;
                    return;
                }
                if (yIndex < 0) {
                    showSnackbar('Could not find \'y\' in header', 'error', 3000);
                    this.file = null;
                    return;
                }
                const lats = sites.map(d => d[yIndex]);
                if (lats.filter(d => coordIsBad(d, 18.91, 71.38)).length) {
                    showSnackbar('A site latitude is out of range', 'error', 3000);
                    this.file = null;
                    return;
                }
                if (sites.length + this.siteCount > 20) {
                    const remaining = 20 - this.siteCount;
                    showSnackbar(`You can only add ${ remaining > 1 ? 'up to ' : ''}${remaining} site${ remaining > 1 ? 's' : ''}`, 'error', 3000);
                    this.file = null;
                    return;
                }
                await this.restore();
                sites.forEach(async d => {
                    const res = await postSite(this.getAccess, d[siteNameIndex], d[xIndex], d[yIndex]);
                    if (res.status == 201) {
                        showSnackbar('Site added!', 'success', 3000);
                        this.changeSites({
                            id: res.data.id,
                            site_name: res.data.site_name,
                            lng_coord: res.data.lng_coord,
                            lat_coord: res.data.lat_coord
                        });
                    } else if(res.status == 406) {
                        showSnackbar('Site rejected; no data coverage', 'error', 3000)
                    } else {
                        showSnackbar('ERROR - site was not added', 'error', 3000);
                    }
                });
                this.cancelSite();
            };

            const reader = new FileReader();
            reader.onload = function() {
                callback(reader.result);
            }
            
            reader.readAsText(this.file);
        }
    }
}
</script>

<style scoped>
    #loading {
        padding: 40vh 50vw;
    }
    .container {
        width: 100vw;
        min-height: 86vh;
    }
    .container div {
        text-align: center;
    }
    .container div button {
        margin: 10px 15px;
    }
    .site-list {
        margin: 25px 0;
    }
    .top-site:before {
        content: "";
        border-top: 1px solid #333;
        margin: 0 auto;
        padding-bottom: 24px;
        width: 100%;
        display: block;
    }
    .add-sites {
        margin-top: 20px;
    }
</style>