<template>
<v-sheet
        v-if="loading"
        height="100vh"
        color="rgba(0,0,0,0)"
>
    <v-progress-circular
        indeterminate
        color="primary"
    >
    </v-progress-circular>
</v-sheet>
    <v-sheet v-else id="questionnaire" rounded outlined max-width="700px">
        <div class="text-h5 font-weight-bold primary--text">Priority Questionnaire</div>
        <p class="text-body-2">
            Answer the questions below to identify counties with the best conditions
            for deploying your advanced nuclear reactor technology. Results will be
            based on your priorities.
        </p>
        <priority-question
            q-text="1. Which state nuclear restrictions would you consider dealbreakers for your project?"
            layer-title="Nuclear Restrictions"
            layer="nuclear-restriction-by-state"
        >
            <template v-slot:primary>
                <v-row>
                    <v-col>
                        <priority-nuclear-restriction restriction_id="6"
                            label="Moratorium (i.e. ban)"
                        ></priority-nuclear-restriction>
                        <!-- <priority-nuclear-restriction restriction_id="10"
                            label="Geographically limited moratorium"
                        ></priority-nuclear-restriction> -->
                        <priority-nuclear-restriction restriction_id="2"
                            label="Required approval by state legislature"
                        ></priority-nuclear-restriction>
                        <priority-nuclear-restriction restriction_id="1"
                            label="Required approval by the state Commissioner of Environmental Protection"
                        ></priority-nuclear-restriction>
                        <priority-nuclear-restriction restriction_id="7"
                            label="Voter approval"
                        ></priority-nuclear-restriction>
                        <priority-nuclear-restriction restriction_id="3"
                            label="Finding (i.e. proof) that the construction of a nuclear facility will be economically feasible for ratepayers"
                        ></priority-nuclear-restriction>
                        <priority-nuclear-restriction restriction_id="4"
                            label="Demonstrable technology or a means for high level waste disposal or reprocessing"
                        ></priority-nuclear-restriction>
                        <priority-nuclear-restriction restriction_id="5"
                            label="Finding that the proposed method for disposal of radioactive waste material (to be produced or generated by the facility) will be safe"
                        ></priority-nuclear-restriction>
                    </v-col>
                </v-row>
            </template>
        </priority-question>
        <priority-question
            q-text="2. How important is high state energy price?"
            :priority="getEnergyPrice"
            :on-change="changeEnergyPrice"
            layer-title="Energy Price"
            layer="retail-energy-price-by-state"
        ></priority-question>
        <priority-question
            q-text="3. How important is state electricity market regulation? User can select deregulated or traditionally regulated."
            :priority="getMarketRegulation"
            :on-change="changeMarketRegulation"
            layer-title="Market Regulation"
            layer="electricity-market-type-by-state"
        >
            <template v-slot:secondary
                v-if="getMarketRegulation > 0"
            >
                <div class="text-subtitle-2">
                    What type of energy market do you prefer?
                </div>
                <v-radio-group row
                    v-model="getPreferredMarket"
                    @change="changePreferredMarket"
                >
                    <v-radio
                        label="Deregulated"
                        value="Deregulated"
                    ></v-radio>
                    <v-radio
                        label="Traditionally regulated"
                        value="Traditionally Regulated"
                    ></v-radio>
                </v-radio-group>
            </template>
        </priority-question>
        <priority-question
            q-text="4. How important is nuclear inclusive state energy legislation?"
            :priority="getNuclearInclusivePolicy"
            :on-change="changeNuclearInclusivePolicy"
            layer-title="Nuclear Inclusive Policy"
            layer="nuclear-inclusive-policy-by-state"
        ></priority-question>
        <priority-question
            q-text="5. How important is positive sentiment towards building new reactors in a county?"
            :priority="getNuclearSentiment"
            :on-change="changeNuclearSentiment"
            layer-title="Nuclear Sentiment"
            layer="nuclear-sentiment-by-county"
        ></priority-question>
        <priority-question
            q-text="6. How important is it that a county is already home to operating nuclear facilities?"
            :priority="getOperatingNuclearFacilities"
            :on-change="changeOperatingNuclearFacilities"
            layer-title="Nuclear Facilities"
            layer="nuclear-facility-summary-by-county"
        ></priority-question>
        <priority-question
            q-text="7. How important is it that generators within a county are entering retirement?"
            :priority="getGeneratorRetirement"
            :on-change="changeGeneratorRetirement"
            layer-title="Generator Retirement"
            layer="retiring-generator-summary-by-county"
        >
            <template v-slot:secondary
                v-if="getGeneratorRetirement > 0"
            >
                <div class="text-subtitle-2">
                    Which technologies interest you?
                </div>
                <v-row>
                    <priority-retiring-technology
                        technology="Coal"
                    ></priority-retiring-technology>
                    <priority-retiring-technology
                        technology="Natural Gas"
                    ></priority-retiring-technology>
                    <priority-retiring-technology
                        technology="Nuclear"
                    ></priority-retiring-technology>
                </v-row>
                <div class="text-subtitle-2" id="retirement-years-prompt">
                    In how many years should a generator be retiring?
                </div>
                <v-range-slider
                    min="0"
                    max="50"
                    thumb-label="always"
                    :tick-labels="retirementYearTicks"
                    v-model="retirementYearRange"
                ></v-range-slider>
            </template>
        </priority-question>
        <priority-question
            q-text="8. How important is a low cost of labor?"
            :priority="getLaborRate"
            :on-change="changeLaborRate"
            layer-title="Labor Rate"
            layer="construction-mean-annual-wage-by-state"
        ></priority-question>
        <priority-question
            q-text="9. How important is it that a county is served by a utility that has nuclear experience?"
            :priority="getNuclearExperiencedUtility"
            :on-change="changeNuclearExperiencedUtility"
            layer-title="Nuclear Experience"
            layer="utility-nuclear-experience-by-county"
        ></priority-question>
        <priority-question
            q-text="10. How important is it that a state is a net importer of electricity?"
            :priority="getNetElectricityImports"
            :on-change="changeNetElectricityImports"
            layer-title="Net Electricity Imports"
            layer="net-electricity-imports-by-state"
        ></priority-question>
        <priority-question
            q-text="11. How important is it that a county is home to nuclear research and development?"
            :priority="getProximityToNuclearRAndD"
            :on-change="changeProximityToNuclearRAndD"
            layer-title="Nuclear Research &amp; Development"
            layer="nuclear-r-and-d-by-county"
        ></priority-question>
        <v-btn
            dark
            class="green"
            width="100%"
            @click="onSubmit()"
        >Submit Priorities</v-btn>
        <v-dialog
            max-width="250px"
            persistent
            :value="calculating"
        >
            <v-card>
                <v-card-text>
                    <div>Calculating match rank...</div>
                    <v-progress-linear
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>


<script>
import PriorityQuestion from './PriorityQuestion.vue';
import PriorityNuclearRestriction from './PriorityNuclearRestriction.vue';
import PriorityRetiringTechnology from './PriorityRetiringTechnology.vue';
import { mapGetters, mapActions } from 'vuex';
import { showSnackbar } from '@commons/Actions';
import { updateDiscoveryPriorities, requestDiscoveryScores } from '@api/star/discovery';

export default {
    name: 'DiscoveryQuestionnaire',
    components: {
        PriorityQuestion,
        PriorityNuclearRestriction,
        PriorityRetiringTechnology
    },
    computed: {
        ...mapGetters([
            'getAccess',
            'getUserId',
            'getNuclearRestrictions',
            'getEnergyPrice',
            'getNuclearInclusivePolicy',
            'getNetElectricityImports',
            'getMarketRegulation',
            'getPreferredMarket',
            'getNuclearSentiment',
            'getGeneratorRetirement',
            'getMinYearsToRetirement',
            'getMaxYearsToRetirement',
            'getRetiringGeneratorTechnologies',
            'getOperatingNuclearFacilities',
            'getProximityToNuclearRAndD',
            'getNuclearExperiencedUtility',
            'getLaborRate',
        ]),
        retirementYearTicks: () => [...Array(51).keys()].map(d => [10,25,40].includes(d) ? d : null),
        retirementYearRange: {
            get: function() {
                return [this.getMinYearsToRetirement, this.getMaxYearsToRetirement]
            },
            set: function(range) {
                return this.changeYearsToRetirement(range);
            }
        },
        loading: {
            get() {
                // Don't show the form until user data is loaded
                return !this.getPreferredMarket;
            },
            set() {

            }
        }
    },
    data() {
        return {
            calculating: false
        }
    },
    mounted() {
        this.loadDiscoveryPriorities();
    },
    methods: {
        ...mapActions([
            'restore',
            'loadDiscoveryPriorities',
            'changeEnergyPrice',
            'changeNuclearInclusivePolicy',
            'changeNetElectricityImports',
            'changeMarketRegulation',
            'changePreferredMarket',
            'changeNuclearSentiment',
            'changeGeneratorRetirement',
            'changeYearsToRetirement',
            'changeOperatingNuclearFacilities',
            'changeProximityToNuclearRAndD',
            'changeNuclearExperiencedUtility',
            'changeLaborRate',
            'changePriorityMatchResults',
            ]),
        hasAtLeastOnePriority() {
            return this.getEnergyPrice +
                this.getNuclearInclusivePolicy +
                this.getNetElectricityImports +
                this.getMarketRegulation +
                this.getNuclearSentiment +
                this.getGeneratorRetirement +
                this.getProximityToNuclearRAndD +
                this.getNuclearExperiencedUtility +
                this.getLaborRate > 0;
        },
        async onSubmit() {
            if (!this.hasAtLeastOnePriority()){
                showSnackbar('At least one priority must be set above "Not Important"', 'error', 3000);
            } else {
                this.calculating = true;
                await this.restore();
                const response = await updateDiscoveryPriorities(this.getAccess, this.getUserId, {
                    nuclear_restrictions: this.getNuclearRestrictions,
                    energy_price: this.getEnergyPrice,
                    nuclear_inclusive_policy: this.getNuclearInclusivePolicy,
                    net_electricity_imports: this.getNetElectricityImports,
                    market_regulation: this.getMarketRegulation,
                    preferred_market: this.getPreferredMarket,
                    nuclear_sentiment: this.getNuclearSentiment,
                    generator_retirement: this.getGeneratorRetirement,
                    min_years_to_retirement: this.getMinYearsToRetirement,
                    max_years_to_retirement: this.getMaxYearsToRetirement,
                    retiring_generator_technologies: this.getRetiringGeneratorTechnologies,
                    operating_nuclear_facilities: this.getOperatingNuclearFacilities,
                    proximity_to_nuclear_r_and_d: this.getProximityToNuclearRAndD,
                    nuclear_experienced_utility: this.getNuclearExperiencedUtility,
                    labor_rate: this.getLaborRate
                });

                if (response.status === 200) {
                    const response = await requestDiscoveryScores(this.getAccess);
                    if (response.status === 200) {
                        this.changePriorityMatchResults(response.data);
                    } else {
                        showSnackbar('There was an error downloading your scores.', 'error', 3000);
                    }
                } else {
                    showSnackbar('There was an error uploading your changes.', 'error', 3000);
                }

                this.calculating = false;
            }
        }
    }
}
</script>

<style scoped>
    .v-progress-circular {
        margin-top: 35vh;
    }
    .text-h5 {
        margin-bottom: 10px;
    }
    #retirement-years-prompt {
        margin-bottom: 40px;
    }
</style>