<template>
    <div class="relevance-item">
        <div class="text-subtitle-2 layer-title">
            {{ layerTitle }}
            <sup>
                <v-icon @click="showInfo">
                    mdi-information-outline
                </v-icon>
            </sup>
        </div>
        <v-row>
            <v-col>
            <div
                v-if="hasNoDataSite"
                class="text-subtitle-2 error--text"
            >
                Incomplete data coverage; layer unavailable.
                <sup>
                    <v-icon class="error--text" x-small @click="() => showNoDataInfo(true)">
                        mdi-information-outline
                    </v-icon>
                </sup>
            </div>
                <v-select v-else
                    outlined
                    dense
                    :items="[
                        {text: 'Not Relevant', value: 'Not Relevant'},
                        {text: 'Very Low', value: 'Very Low'},
                        {text: 'Low', value: 'Low'},
                        {text: 'Medium', value: 'Medium'},
                        {text: 'High', value: 'High'},
                        {text: 'Very High', value: 'Very High'}
                    ]"
                    :value="relevanceBin"
                    @change="changeRelevanceBin"
                    placeholder="Select"
                ></v-select>
            </v-col>
            <v-col>
                <v-slider
                    v-show="relevance > 0"
                    v-model="relevance"
                    :max="sliderMax"
                    :min="sliderMin"
                    :tick-labels="sliderTicks"
                ></v-slider>
            </v-col>
        </v-row>
        <slot name="options" v-if="!hasNoDataSite"></slot>
        <div
            v-if="noRefMap"
            class="text-caption text-start"
        >Reference Map Not Available</div>
        <div v-else
            class="ref-map-link text-caption text-decoration-underline text-start"
            @click="showMap"
        >View {{ layerTitle }} Reference Map</div>
        <v-dialog
            v-model="noDataInfo"
            @click:outside="() => () => showNoDataInfo(false)"
            width="500"
        >
            <v-card>
                <v-card-title class="error--text" dark>Incomplete Data Coverage</v-card-title>
                <v-card-text>
                    This layer does not cover {{noDataAreas ? noDataAreas.join(' or ') : null}} and has been removed to
                    maintain balanced weighting across all sites. To include this layer for comparison,
                    delete all sites in {{noDataAreas ? noDataAreas.join(' and ') : null}}.
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { showLayerInfo, showReferenceMap } from '@commons/Actions';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ComparisonRelevanceItem",
    props: {
        storeKey: String,
        layerTitle: String,
        layer: String,
        noRefMap: Boolean,
        noDataAreas: Array
    },
    data() {
        return {
            noDataInfo: false
        }
    },
    computed: {
        ...mapGetters(['getRelevance', 'getSiteAreas']),
            hasNoDataSite() {
                let noDataSite = false;
                if (this.noDataAreas) {
                    this.noDataAreas.forEach(area => {
                        if (this.getSiteAreas.includes(area)) {
                            noDataSite = true;
                        }
                    })
                }
                return noDataSite;
            },
            relevance: {
                get() {
                    return this.getRelevance(this.storeKey);
                },
                set(val){
                    this.changeRelevance({key: this.storeKey, val});
                }
            },
            relevanceBin() {
                if (this.relevance > 40) {
                    return 'Very High';
                } else if (this.relevance > 30) {
                    return 'High';
                } else if (this.relevance > 20) {
                    return 'Medium';
                } else if (this.relevance > 10) {
                    return 'Low';
                } else if (this.relevance > 0) {
                    return 'Very Low';
                } else {
                    return 'Not Relevant';
                }
            },
            sliderMin() {
                if (this.relevance > 40) {
                    return 46;
                } else if (this.relevance > 30) {
                    return 36;
                } else if (this.relevance > 20) {
                    return 26;
                } else if (this.relevance > 10) {
                    return 16;
                } else if (this.relevance > 0) {
                    return 6;
                } else {
                    return 0;
                }
            },
            sliderMax() {
                if (this.relevance > 40) {
                    return 50;
                } else if (this.relevance > 30) {
                    return 40;
                } else if (this.relevance > 20) {
                    return 30;
                } else if (this.relevance > 10) {
                    return 20;
                } else if (this.relevance > 0) {
                    return 10;
                } else {
                    return 0;
                }
            },
        sliderTicks() {
            const ticks = [];
            for (let i=this.sliderMin; i <= this.sliderMax; i++) {
                if (i == this.sliderMin || i % 5 == 0) {
                    ticks.push(i);
                } else {
                    ticks.push(null);
                }
            }
            return ticks;
        }
    },
    methods: {
        ...mapActions(['changeRelevance']),
        showInfo() {
            showLayerInfo(this.layer, 'stand', 'Comparison', this.layerTitle);
        },
        showMap() {
            showReferenceMap(this.layer, this.layerTitle);
        },
        showNoDataInfo(val) {
            this.noDataInfo = val;
        },
        changeRelevanceBin(value) {
            switch(value){
                case 'Very High':
                    this.relevance = 48;
                    break;
                case 'High':
                    this.relevance = 38;
                    break;
                case 'Medium':
                    this.relevance = 28;
                    break;
                case 'Low':
                    this.relevance = 18;
                    break;
                case 'Very Low':
                    this.relevance = 8;
                    break;
                default:
                    this.relevance = 0;
                    break;
            }
        }
    }
}
</script>


<style scoped>
    .relevance-item {
        background-color: #fff;
        border: 1px solid #999;
        border-radius: 10px;
        margin: 15px 30px;
        padding: 30px;
    }
    .ref-map-link {
        cursor: pointer;
    }
    .layer-title {
        margin-bottom: 10px;
    }
</style>