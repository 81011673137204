import { render, staticRenderFns } from "./ExplorationSiteList.vue?vue&type=template&id=23856eb9&scoped=true"
import script from "./ExplorationSiteList.vue?vue&type=script&lang=js"
export * from "./ExplorationSiteList.vue?vue&type=script&lang=js"
import style0 from "./ExplorationSiteList.vue?vue&type=style&index=0&id=23856eb9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23856eb9",
  null
  
)

export default component.exports