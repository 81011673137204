import { deepCopy } from './utility';
import { showFeatureDetail, showSnackbar } from './Actions';
import { median, std } from 'mathjs';
// import { median, mean, std } from 'mathjs';

export const usStates = require("@highcharts/map-collection/countries/us/us-all.geo.json");

export const usCounties = require("@highcharts/map-collection/countries/us/us-all-all.geo.json");
usCounties.features.forEach(f => {
    if (f.id === 'US.SD.113') {
        f.properties.fips = '46102';
        f.properties.name = 'Oglala Lakota'
    }
})

export const usStateBorders = {
    ...usCounties,
    features: usCounties.features
        .filter(d => d.properties['hc-group'] === '__border_lines__')
};

export const mapOptionsBoilerPlate = {
    title: { text: null },
    chart: {
        type: 'map',
        backgroundColor: 'rgba(0,0,0,0)',
        animation: false,
        spacing: [2, 2, 2, 2],
        resetZoomButton: {theme: {display: 'none'}},
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    mapNavigation: {
        enableButtons: true,
        buttonOptions: { verticalAlign: 'top'},
        enableMouseWheelZoom: true,
        enableTouchZoom: true
    },
    legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        labelFormatter: function() {
            return '<span title="' + this.name + '">' + this.name + '</span>';
        }
    },
}

export const mapOptionsStateData = {
    ...mapOptionsBoilerPlate,
    chart: {...mapOptionsBoilerPlate.chart, map: usStates},
    plotOptions:{
        map:{
            allAreas: false,
            joinBy: ["fips", "code"],
            dataLabels: {
                enabled: true,
                formatter: function(){return this.point.properties['postal-code']}
            },
            events: {legendItemClick: e => e.preventDefault()},
            states: {
                hover: {
                    brightness: 0.1,
                    borderColor: "#000",
                    borderWidth: 2
                }
            }
        },
        mapline: {
            showInLegend: false,
            enableMouseTracking: false
        },
        series: {
            allAreas: true,
            cursor: 'pointer'
        }
    },
}

export const mapOptionsCountyData = {
    ...mapOptionsBoilerPlate,
    chart: {...mapOptionsBoilerPlate.chart, map: usCounties},
    plotOptions:{
        map:{
            allAreas: false,
            joinBy: ["fips", "code"],
            dataLabels: { enabled: false },
            events: {legendItemClick: e => e.preventDefault()},
            states: {
                hover: {
                    brightness: 0.1,
                    borderColor: "#000",
                    borderWidth: 2
                }
            }
        },
        series: {
            allAreas: true,
            cursor: 'pointer'
        }
    },
    series: [
        {
            type: 'map',
            name: 'Counties',
            mapData: usCounties,
            showInLegend: false,
            allAreas: false,
            joinBy: "fips",
            events: {legendItemClick: e => e.preventDefault()},
            states: {
                hover: {
                    brightness: 0.1,
                    borderColor: "#000",
                    borderWidth: 2
                }
            }
        }, 
        {
            type: 'mapline',
            name: 'State borders',
            mapData: usStateBorders,
            showInLegend: false,
            nullColor: '#000',
            shadow: false,
            colorAxis: false
        },
    ]
}
function getColorAxis(data, labelFormatFunc) {
    const values = Object.values(data).map(d => d.value);
    const medianValue = median(values);
    
    return{
        min: Math.min(...values),
        max: medianValue + std(...values),
        type: 'linear',
        // stops: [
        //     [Math.min(...values)/ Math.max(...values), '#fff'],
        //     [medianValue / Math.max(...values), '#9aca3c'],
        //     [medianValue / mean(...values), '#008358'],
        //     [1.0, '#005036']
        // ],
        minColor: '#f7f7f7',
        maxColor: '#008358',
        labels: {
            overflow: "allow",
            formatter: labelFormatFunc
        }
    };
}

export function setMapOptionsByLayer(layer, rawData) {
    let mapOptions;

    const data = formatMapDataByLayer(layer, rawData);

    if (/(-by-state)$/.test(layer)) {
        mapOptions = deepCopy(mapOptionsStateData);
    }
    else if (/(-by-county)$/.test(layer)) {
        mapOptions = deepCopy(mapOptionsCountyData);
    } else {
        mapOptions = deepCopy(mapOptionsBoilerPlate);
    }

    switch (layer) {
        case 'nuclear-inclusive-policy-by-state':
        case 'nuclear-restriction-by-state':
        case 'electricity-market-type-by-state': {
            let nullCategory;
            switch (layer) {
                case 'nuclear-restriction-by-state':
                    mapOptions.colors = ['#e9eaea', '#f58221', '#98281f'];
                    nullCategory = /^0 Restrictions$/;
                    break;
                case 'nuclear-inclusive-policy-by-state':
                    mapOptions.colors = ['#008358', '#f7f7f7'];
                    nullCategory = /^Not Nuclear Inclusive$/;
                    break;
                case 'electricity-market-type-by-state':
                    mapOptions.colors = ['#008358', '#f7f7f7'];
                    nullCategory = /^.+$/
                    break;
            }
        
            mapOptions.series = data;
            mapOptions.tooltip = {
                headerFormat: null,
                pointFormatter: function() {
                    return `${this.name}<br/><b>${this.series.userOptions.name}</b>`;
                }
            };
            mapOptions.plotOptions.map.point = {
                events: {
                    click: function() {
                        if (nullCategory.test(this.series.userOptions.name)) {
                            showSnackbar('No detail to show', 'warning', 3000);
                        } else {
                            showFeatureDetail(layer, this.fips.substring(2));
                        }
                    }
                }
            };
            break;
        }
        case 'construction-mean-annual-wage-by-state':
        case 'retail-energy-price-by-state':
        case 'net-electricity-imports-by-state':
            mapOptions.series = [{
                ...mapOptions.series,
                mapData: usStates,
                type: 'map',
                joinBy: 'fips',
                data,
                borderColor: '#30393c',
                events: {
                    click: function(e) {
                        showFeatureDetail(layer, e.point.fips.substring(2));
                    }
                }
            }];
            switch (layer) {
                case 'retail-energy-price-by-state':
                    mapOptions.colorAxis = getColorAxis(data);
                    mapOptions.series[0].name = 'Retail Energy Price';
                    mapOptions.legend.title = { text: 'Cents per kWh' }
        
                    mapOptions.tooltip = {
                        headerFormat: null,
                        pointFormatter: function() {
                            if (this.value >= 0) {
                                return `${this.name}<br/><b>${parseFloat(this.value).toFixed(3)}${String.fromCharCode(162)}/kwH</b>`
                            } else {
                                return `${this.name}<br/><b>No Data</b>`
                            }
                        }
                    };
                    break;
                case 'net-electricity-imports-by-state':
                    mapOptions.colorAxis = getColorAxis(data, function(){
                        if (this.isFirst) {
                            return this.value
                        } else if (this.isLast) {
                            return `${this.value}+`
                        }
                    });
                    mapOptions.colorAxis.labels.x = -12;
                    mapOptions.legend.title = { text: 'million kWh' };
                    mapOptions.series[0].name = 'Net Electricty Imports';
        
                    mapOptions.tooltip = {
                        headerFormat: null,
                        pointFormatter: function() {
                            if (this.value != null) {
                                return `${this.name}<br/><b>${this.value} million kWh</b>`;
                            } else {
                                return `${this.name}<br/><b>No Data</b>`;
                            }
                        }
                    };
                    break;
                case 'construction-mean-annual-wage-by-state':
                    mapOptions.colorAxis = getColorAxis(data);
                    mapOptions.colorAxis.labels.x = -5;
                    mapOptions.series[0].name = 'Construction Mean Annual Wage';
                    mapOptions.legend.title = { text: 'US Dollars' };
        
                    mapOptions.tooltip = {
                        headerFormat: null,
                        pointFormatter: function() {
                            if (this.value >= 0) {
                                return `${this.name}<br/><b>$${this.value}</b>`
                            } else {
                                return `${this.name}<br/><b>No Data</b>`
                            }
                        }
                    };
                    break;
            }
            break;
        case 'nuclear-facility-summary-by-county':
        case 'utility-nuclear-experience-by-county':
        case 'nuclear-r-and-d-by-county':{
            let nullCategory = /^No R&D$/;
            mapOptions.colors = ['#008358', '#f7f7f7'];
            // switch (layer) {
            //     case 'nuclear-facility-summary-by-county':
            //         nullCategory = /^0 Nuclear Facilities$/;
            //         break;
            //     case 'utility-nuclear-experience-by-county':
            //         nullCategory = /^No Nuclear Experience$/;
            //         break;
            //     case 'nuclear-r-and-d-by-county':
            //         nullCategory = /^No R&D$/
            //         break;
            // }
        
            mapOptions.series = data;
            mapOptions.tooltip = {
                headerFormat: null,
                pointFormatter: function() {
                    return `${this.name}<br/><b>${this.series.userOptions.name}</b>`;
                }
            };
            mapOptions.plotOptions.map.point = {
                events: {
                    click: function() {
                        if (nullCategory.test(this.series.userOptions.name)) {
                            showSnackbar('No detail to show', 'warning', 3000);
                        } else {
                            showFeatureDetail(layer, this.fips);
                        }
                    }
                }
            };
            break;
        }
        case 'retiring-generator-summary-by-county':
        case 'social-vulnerability-index-by-county':
        case 'nuclear-sentiment-by-county':
            mapOptions.colorAxis = getColorAxis(data);
            mapOptions.series[0].data = data;
            mapOptions.series[0].borderColor = '#30393c';
            switch (layer) {
                case 'retiring-generator-summary-by-county':
                    mapOptions.series[0].events = {
                        click: function(e) {
                            if (e.point.value > 0) {
                                showFeatureDetail(layer, e.point.fips);
                            } else {
                                showSnackbar('No retiring generators', 'warning', 3000);
                            }
                        }
                    };
                    break;
                case 'nuclear-sentiment-by-county':
                    mapOptions.series[0].events = {
                        click: function(e) {
                            showFeatureDetail(layer, e.point.fips);
                        }
                    };
                    break;
                case 'social-vulnerability-index-by-county':
                    mapOptions.series[0].events = {
                        click: function() {
                            showSnackbar('No details to show', 'warning', 3000);
                        }
                    };
                    break;
                default:
                    mapOptions.series[0].events = {
                        click: function() {
                            showSnackbar('Feature detail not configured', 'warning', 3000);
                        }
                    };
                    break;
            }
            switch (layer) {
                case 'retiring-generator-summary-by-county':
                    mapOptions.colorAxis.labels.formatter = function() {
                        return this.isLast ? `${this.value}+` : this.value;
                    }
                    mapOptions.tooltip = {
                        headerFormat: null,
                        pointFormatter: function() {
                            if (this.value >= 0) {
                                return `${this.name}<br/><b>${this.value}</b>`
                            } else {
                                return `${this.name}<br/><b>No Data</b>`
                            }
                        }
                    };
                    break;
                case 'social-vulnerability-index-by-county':
                    mapOptions.tooltip = {
                        headerFormat: null,
                        pointFormatter: function() {
                            if (this.value >= 0) {
                                return `${this.name}<br/><b>${parseFloat(this.value).toFixed(3)}</b>`
                            } else {
                                return `${this.name}<br/><b>No Data</b>`
                            }
                        }
                    };
                    break;
                case 'nuclear-sentiment-by-county':
                    mapOptions.colorAxis.labels.formatter = function() {
                        return parseInt(this.value * 100);
                    }
                    mapOptions.tooltip = {
                        headerFormat: null,
                        pointFormatter: function() {
                            if (this.value >= 0) {
                                return `${this.name}<br/><b>${parseInt(this.value * 100)}%</b>`
                            } else {
                                return `${this.name}<br/><b>No Data</b>`
                            }
                        }
                    };
                    break;
            }
            switch (layer) {
                case 'social-vulnerability-index-by-county':
                    mapOptions.series[0].name = 'Social Vulnerability Index';
                    mapOptions.legend.title = { text: 'SVI Rating' }
                    break;
                case 'retiring-generator-summary-by-county':
                    mapOptions.series[0].name = 'Retiring Generator Count';
                    mapOptions.legend.title = { text: 'Count' }
                    break;
                case 'nuclear-sentiment-by-county':
                    mapOptions.series[0].name = 'Support for New Reactors';
                    mapOptions.legend.title = { text: 'Support for New Reactors (%)' }
                    break;
            }
            break;
    }
    return mapOptions;
}

function formatMapDataByLayer(layer, data) {
    switch (layer) {
        case 'nuclear-inclusive-policy-by-state':{
            const zeroData = usStates.features.map(d => d.properties.fips).filter(fips => fips);

            return [{
                name: 'Nuclear Inclusive',
                data: data.map(d => ({code: `US${d.state_id}`}))
            },{
                name: 'Not Nuclear Inclusive',
                data: zeroData.filter(d => data.map(d => `US${d.state_id}`).indexOf(d) == -1).map(d => ({code: d}))
            }];
        }
        case 'nuclear-restriction-by-state': {
            const zeroData = usStates.features.map(d => d.properties.fips).filter(fips => fips);

            const counts = data.reduce((acc, d) => {
                const key = `${d.nuclear_restrictions.length} Restriction${d.nuclear_restrictions.length === 1 ? '' : 's'}`
                const val = `US${d.state_id}`
        
                zeroData.splice(zeroData.indexOf(val), 1);
                
                if (!acc[key]) {
                    acc[key] = [val];
                } else {
                    acc[key].push(val)
                }
                return acc;
            }, []);
        
            counts['0 Restrictions'] = zeroData;

            return Object.keys(counts).sort().map(d => {
                return {
                        name: d,
                        data: counts[d].map(state_id => {
                            return {code: state_id};
                        })
                    };
                });
        }
        case 'retail-energy-price-by-state':
            return data.map(d => ({
                fips: `US${d.state_id}`,
                value: d.retail_energy_prices.filter(d => d.sector == 'All')[0].cents_per_kilowatt_hour
            }));
        case 'electricity-market-type-by-state':{
            const categories = data.reduce((acc, d) => {
                const key = d.market_type;
                const val = `US${d.state_id}`;
        
                if (!acc[key]) {
                    acc[key] = [val];
                } else {
                    acc[key].push(val)
                }
                return acc;
            }, []);
        
            return Object.keys(categories).sort().map(d => {
                return {
                        name: d,
                        data: categories[d].map(fips => {
                            return {code: fips};
                        })
                    };
                });
        }
        case 'utility-nuclear-experience-by-county':
        case 'nuclear-facility-summary-by-county':
        case 'nuclear-r-and-d-by-county': {
            let results, resultsName, nullName;
            switch (layer) {
                case 'utility-nuclear-experience-by-county':
                    results =  data.filter(d => d.nuclear_entity_count > 0).map(d => d.county_id);
                    resultsName = 'Served by Utility with Nuclear Experience';
                    nullName = 'No Nuclear Experience';
                    break;
                case 'nuclear-facility-summary-by-county':
                    results =  data.filter(d => d.nuclear_facility_count > 0).map(d => d.county_id);
                    resultsName = 'Home to Nuclear Facility';
                    nullName = 'No Nuclear Facilities';
                    break;
                case 'nuclear-r-and-d-by-county':
                    results =  data.map(d => d.county_id);
                    resultsName = 'Home to Nuclear R&D';
                    nullName = 'No Nuclear R&D';
                    break;
            }
            const countyFIPS = usCounties.features.map(d => d.properties.fips);
            const nullCounties = countyFIPS.filter(d => results.indexOf(d) == -1);
            return [
                {name: resultsName, data: results.map(code => ({code}))},
                {name: nullName, data: nullCounties.map(code => ({code}))}
            ];
        }
        case 'net-electricity-imports-by-state':
            return data.map(d => ({fips: `US${d.state_id}`, value: parseInt(d.total_million_kilowatt_hours)}));
        case 'construction-mean-annual-wage-by-state':
            return data.map(d => ({fips: `US${d.state_id}`, value: d.maw_5_year_average})).filter(d => d.fips != 'US72');
        case 'retiring-generator-summary-by-county':
            return data.map(d => ({fips: d.county_id, value: d.retiring_generator_count}));
        case 'nuclear-sentiment-by-county':
            return data.map(d => ({fips: d.county_id, value: parseFloat(d.support_for_new_reactors_proportion)})).filter(d => !isNaN(d.value));
        case 'social-vulnerability-index-by-county':
            return data.map(d => ({fips: d.county_id, value: parseFloat(d.ranking)})).filter(d => !isNaN(d.value));
    }
}