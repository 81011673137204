<template>
<v-container class="site-li">
    <v-row justify="space-between">
        <v-col>
            <v-row>
                <v-col
                    class="text-body-1 font-weight-bold primary--text site-name"
                    @click="() => zoom(lat, lng)"
                >
                    {{ name }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="text-caption blue-grey--text">
                    {{ lat.toFixed(4) }}, {{ lng.toFixed(4) }}
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="2" align-self="center">
            <v-icon @click="() => deleteSite(id, name)">mdi-delete</v-icon>
        </v-col>
    </v-row>
</v-container>
</template>

<script>

export default {
    name: 'ComparisonReviewSitesListItem',
    props: {
        id: Number,
        name: String,
        lat: Number,
        lng: Number,
        zoom: Function,
        deleteSite: Function,
    },
}
</script>

<style scoped>
    .site-li {
        margin-top: 16px;
    }
    .site-name {
        cursor: pointer;
    }
</style>