<template>
<v-col cols=2>
    <v-checkbox
        v-model="isChecked"
        dense
        :label="technology"
        :disabled="isDisabled"
    ></v-checkbox>
</v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { showSnackbar } from '@commons/Actions';

export default {
    name: 'ComparisonRetiringTechnology',
    props: {
        technology: String
    },
    computed: {
        ...mapGetters(['getRetiringGeneratorTechnologiesComp','isRetiringTechnologyComp']),
        isChecked: {
            get: function() {
                return this.isRetiringTechnologyComp(this.technology)
            },
            set: function() {
                this.changeRetiringGeneratorTechnologiesComp(this.technology);
            }
        },
        isDisabled() {
            if (this.getRetiringGeneratorTechnologiesComp.length === 1 && 
                this.isRetiringTechnologyComp(this.technology)) {
                showSnackbar('At least one technology must be selected', 'warning', 3000);
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapActions([
            'changeRetiringGeneratorTechnologiesComp'
        ]),
    },
}
</script>
