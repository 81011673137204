<template>
    <div>
        <div id="map" ref="map"></div>
        <v-container id="map-toolbar">
            <v-row justify="space-between" align="start">
                <v-col>
                    <v-select
                        dense
                        v-model="select"
                        :items="getSelectedMatchCounties"
                        item-text="county_title"
                        item-value="county_title"
                        label="Selected Counties"
                        outlined
                        filled
                        background-color="#fff"
                        @change="mapSelectCounty"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        dense
                        id="location-search"
                        placeholder="Find location"
                        clearable
                        outlined
                        solo
                        background-color="#fff"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-row justify="end">
                            <v-btn fab color="primary" @click="showInfo">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                    </v-row>
                    <v-row v-if="!addingSite" justify="end">
                            <v-btn
                                fab
                                color="primary"
                                @click="locateSite"
                            >
                                <v-icon>mdi-map-marker-plus</v-icon>
                                <template v-slot:loader>
                                    <v-icon>mdi-map-marker-off</v-icon>
                                </template>
                            </v-btn>
                    </v-row>
                    <v-row v-if="addingSite" justify="end">
                            <v-btn
                                fab
                                color="error"
                                @click="cancelSite"
                            >
                                <v-icon>mdi-map-marker-off</v-icon>
                            </v-btn>
                    </v-row>
                    <!-- <v-row justify="end">
                            <v-btn fab color="primary" @click="printMap">
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                    </v-row> -->
                </v-col>
            </v-row>
        </v-container>
        <div id="side-menu" class="d-flex">
            <transition name="slide">
                <exploration-side-panel
                    class="slidein"
                    v-if="panelOpen"
                >
                    <template v-slot>
                        <exploration-site-list
                            v-if="selectedPanel == 'site-list'"
                            :addingSite="addingSite"
                            :locateSite="locateSite"
                            :cancelSite="cancelSite"
                        >
                            <template v-slot>
                                <exploration-site-list-item
                                    v-for="(site) in getSites"
                                    :key="site.id" :id="site.id"
                                    :name="site.site_name"
                                    :lat="site.lat_coord"
                                    :lng="site.lng_coord"
                                    :zoom="zoomToPoint"
                                    :deleteSite="deleteSite"
                                ></exploration-site-list-item>
                            </template>
                        </exploration-site-list>
                        <exploration-layers
                            v-if="selectedPanel == 'layers'"
                        >
                        </exploration-layers>
                    </template>
                </exploration-side-panel>
            </transition>
            <div id="btn-bar" class="primary"></div>
            <div id="btn-grp" class="d-flex flex-column">
                <div
                    :class="`${activePanel == 'layers' ? 'primary' : 'indigo darken-4'} d-flex flex-column align-center white--text text-button`"
                    @click="() => selectPanel('layers')"
                >
                    <v-icon
                        x-large
                        class="white--text"
                    >mdi-layers</v-icon>
                    Layers
                </div>
                <div
                    :class="`${activePanel == 'site-list' ? 'primary' : 'indigo darken-4'} d-flex flex-column align-center white--text text-button`"
                    @click="() => selectPanel('site-list')"
                >
                    <v-icon
                        x-large
                        class="white--text"
                    >mdi-map-marker-multiple</v-icon>
                    Site List
                </div>
            </div>
        </div>
        <exploration-feature-info></exploration-feature-info>
        <v-dialog
            v-model="namingSite"
            @click:outside="cancelSite"
            width="500"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >
                    Add a Site
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                        label="Name of Site"
                        v-model="newSiteName"
                        :rules="siteNameRules"
                        required
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="justify-start">
                    <v-btn
                        text
                        :disabled="isInvalidName"
                        color="primary"
                        @click="addSite"
                    >
                        Save to Site List
                    </v-btn>
                    <v-btn
                        text
                        color="blue-grey lighten-2"
                        @click="cancelSite"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="siteInfoOpen"
            @click:outside="clearClickedSite"
            width="500"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >
                    {{ clickedSite.site_name || '' }}
                </v-toolbar>
                <v-card-actions class="justify-start">
                    <v-btn
                        text
                        color="error"
                        @click="() => deleteSite(clickedSite.id, clickedSite.site_name)"
                    >
                        Delete Site
                    </v-btn>
                    <v-btn
                        text
                        color="blue-grey lighten-2"
                        @click="clearClickedSite"
                    >
                        Back to Map
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="1000px"
            v-model="pageInfo"
        >
            <v-card>
                <v-card-title>Site Exploration</v-card-title>
                <v-card-text>
                    <div>
                        Site Exploration displays a web GIS which can be used to explore regulatory
                        and/or infrastructure data and select locations for Site Comparison.
                    </div>
                    <div class="text-subtitle-2">How To Use This Section:</div>
                    <ol>
                        <li>Choose locations for exploration:
                            <ol type="a">
                                <li>
                                    If counties have been selected in Site Discovery, these counties
                                    can be accessed in the dropdown titled “Selected Counties” in the
                                    upper right of the map frame. This will zoom the map to the selected
                                    counties.
                                </li>
                                <li>
                                    The “Find Location” box in the upper right of the map frame will
                                    zoom to any user defined location. User defined locations can be;
                                    states, counties,cities or addresses.
                                </li>
                                <li>
                                    Adjust the zoom with the +/- icon on the left of the map. Reposition
                                    by clicking and dragging.
                                </li>
                            </ol>
                        </li>
                        <li>
                            View data layers:
                            <ol type="a">
                                <li>
                                    Click on the “Layers” tab on the left side of the screen to open the
                                    layers drawer. Use the dropdowns to display the available layers for
                                    each category.
                                    <ol type="i">
                                        <li>
                                            Use checkboxes to display layers in the map. Multiple layers
                                            can be selected and viewed simultaneously. Layers that display 
                                            "Zoom in” below the layer name require the map to be zoomed
                                            further in in order to be displayed.
                                        </li>
                                        <li>
                                            Click the information boxes to display layer information.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Add sites for Site Comparison:
                            <ol type="a">
                                <li>
                                    Click the add sites icon on the right side of the map frame to add sites:
                                    <ol type="i">
                                        <li>
                                            Once the icon has been clicked, it will turn red to indicate it
                                            is active. Click it again to deactivate or the next click on the map will add a new site.
                                        </li>
                                        <li>
                                            If a new site is added, a dialog box will appear. Input the name of the site and submit
                                            by clicking save to list.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Once sites have been added, click on the “Site List” tab on the left side
                                    of the screen to open the site list drawer.
                                    <ol type="i">
                                        <li>
                                            Click the blue site name to zoom to that site in the map pane.
                                        </li>
                                        <li>
                                            Use the garbage can icon to remove the site from the list.
                                        </li>
                                        <li>
                                            Once all sites have been added, click the green “Compare Sites”
                                            button to proceed to Site Comparison where all sites added will
                                            be automatically displayed.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-overlay :value="tilesLoading">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { showSnackbar } from "@commons/Actions";
import { mapGetters, mapActions } from "vuex";
import ExplorationSidePanel from './ExplorationSidePanel.vue';
import ExplorationSiteList from './ExplorationSiteList.vue';
import ExplorationSiteListItem from './ExplorationSiteListItem.vue';
import ExplorationLayers from './ExplorationLayers.vue';
import ExplorationFeatureInfo from './ExplorationFeatureInfo.vue';
import { requestLayerData } from "@api/layerData";
import { requestDiscoverySelections } from "@api/star/discovery";
import { requestFeatureInfo } from "@api/star/exploration";
import {
    requestSites,
    postSite,
    deleteSite
} from "@api/star/comparison";

export default {
    name: 'ExplorationPath',
    components: {
        ExplorationSidePanel,
        ExplorationSiteList,
        ExplorationSiteListItem,
        ExplorationLayers,
        ExplorationFeatureInfo,
    },
    data() {
        return {
            map: null,
            mapCenter: {
                lat: 39,
                lng: -95
            },
            searchBox: null,
            geocoder: null,
            address: '',
            select: {},
            addingSite: false,
            newSiteCoords: null,
            newSiteName: '',
            siteNameRules: [
                val => !!val.trim().length || 'Invalid site name',
                val => !this.getSites.map(d => d.site_name).includes(val.trim()) || 'Site name already exists'
            ],
            mapClickListener: null,
            clickedSite: {
                id: null,
                site_name: null
            },
            selectedPanel: null,
            activePanel: 'layers',
            pageInfo: false,
            tilesLoading: false,
        }
    },
    computed: {
        ...mapGetters(['getInfoLayersString', 'isLayerDisabled', 'getLayerVisibility', 'getSelectedMatchCounties', 'getCountVisible', 'getAccess', 'getSites', 'getLayersString']),
        namingSite() {
            return this.newSiteCoords != null;
        },
        isInvalidName() {
            return !this.newSiteName.trim().length || this.getSites.map(d => d.site_name).includes(this.newSiteName.trim());
        },
        siteInfoOpen() {
            return this.clickedSite.id != null;
        },
        panelOpen() {
            return !!this.selectedPanel;
        },
    },
    async mounted() {
        await this.restore();

        this.map = new window.google.maps.Map(document.getElementById('map'), {
            center: this.$route.query.lat && this.$route.query.lng ?
                {
                    lat: parseFloat(this.$route.query.lat),
                    lng: parseFloat(this.$route.query.lng)
                } : this.mapCenter,
            zoom: this.$route.query.lat && this.$route.query.lng ? 12 : 4,
            maxZoom: 20,
            minZoom: 3,
            streetViewControl: false,
            mapTypeControl: true,
            fullscreenControl: false,
            zoomControl: true,
            draggableCursor: 'default'
        });
        
        if (!this.getSelectedMatchCounties.length) {
            // request selected counties from server
            await requestDiscoverySelections(this.getAccess).then(res => {
                if (res.status == 200 && res.data.length) {
                    let countyIDs = res.data.map(d => d.county_id);
                    if (!this.getLayerVisibility('counties_geo')){
                        this.changeLayerVisibility('counties_geo')
                    }
                    // request county titles from server
                    requestLayerData(this.getAccess,
                        'nuclear-facility-summary-by-county',null,
                        `fields=county_id,county_name,state_usps_abbreviation&county_id__in=${countyIDs.join(',')}`)
                        .then(res => {
                            if (res.status == 200) {
                                res.data.forEach(d => {
                                    this.changeSelectedMatchCounties({
                                        id: d.county_id,
                                        county_title: `${d.county_name}, ${d.state_usps_abbreviation}`,
                                        });
                                    });
                            }
                        });
                } else {
                    if (this.getLayerVisibility('counties_geo')){
                        this.changeLayerVisibility('counties_geo')
                    }
                }
            });
        }

        this.changeMapZoom(this.map.zoom);

        this.infoWindow = new window.google.maps.InfoWindow({});

        this.searchBox = new window.google.maps.places.Autocomplete(document.getElementById('location-search'));
        this.geocoder = new window.google.maps.Geocoder();

        this.map.addListener("bounds_changed", () => {
            this.searchBox.setBounds(this.map.getBounds());
        });

        this.map.addListener('zoom_changed', () => {
            this.changeMapZoom(this.map.zoom);
        })

        this.map.addListener("click", (mapsMouseEvent) => {
            if (this.addingSite) {
                this.newSiteCoords = mapsMouseEvent.latLng.toJSON();
            } else if (this.getInfoLayersString) {
                const featureInfoCircle = new window.google.maps.Circle({
                    center: mapsMouseEvent.latLng.toJSON(),
                    radius: 10000,
                    // map: this.map,
                    // strokeColor: "#000"
                });
                const featureInfoBounds = featureInfoCircle.getBounds();
                const swCorner = featureInfoBounds.getSouthWest();
                const neCorner = featureInfoBounds.getNorthEast();
                const bbox = `${swCorner.lng()},${swCorner.lat()},${neCorner.lng()},${neCorner.lat()}`
                this.getFeatureInfo(bbox);
            }
        });
        
        this.map.data.addListener("click", (event) => {
            this.clickedSite = {
                id: event.feature.getId(),
                site_name: event.feature.getProperty('site_name')
            };
        });
        
        this.searchBox.setFields(["place_id", "geometry"]);

        this.searchBox.addListener("place_changed", () => {
            const place = this.searchBox.getPlace();

            if (!place.place_id) {
                return;
            }

            const bounds = new window.google.maps.LatLngBounds();

            this.geocoder.geocode({placeId: place.place_id})
                    .then(({ results }) => {
                        if (results[0].geometry.viewport) {
                            bounds.union(results[0].geometry.viewport);
                        } else {
                            bounds.extend(results[0].geometry.location);
                        }

                        this.map.fitBounds(bounds);
                    })
                    .catch(() => showSnackbar("Could not map the requested location.", "error", 3000));

        });
        
        if (!this.getSites.length) {
            requestSites(this.getAccess).then(res => {
                if (res.status == 200 && res.data.length) {
                    res.data.forEach(d => {
                        this.changeSites({
                            id: d.id, site_name: d.site_name,
                            lng_coord: d.lng_coord, lat_coord: d.lat_coord
                        });
                    });
                }
            });
        } else {
            this.getSites.forEach(s => {
                this.map.data.add({
                    geometry: {lat: s.lat_coord, lng: s.lng_coord},
                    id: s.id,
                    properties: {
                        site_name: s.site_name
                    }
                });
            });
        }

        const layers = new window.google.maps.ImageMapType({
          getTileUrl: this.getTileUrl,
          name: "STAND Layers",
          alt: "Selected Contextual Layers",
          minZoom: 0,
          maxZoom: 19,
          opacity: 0.75,
        });
        this.map.overlayMapTypes.push(layers);


    },
    watch: {
        getLayersString: function() {
            this.map.overlayMapTypes.removeAt(0);
            this.tilesLoading = true;
            const layers = new window.google.maps.ImageMapType({
                getTileUrl: this.getTileUrl,
                name: "STAND Layers",
                alt: "Selected Contextual Layers",
                minZoom: 0,
                maxZoom: 19,
                opacity: 0.75,
                });
            layers.addListener('tilesloaded', () => this.tilesLoading = false)
            this.map.overlayMapTypes.push(layers);
        },
        getSites: function(sites) {
            sites.forEach(s => {
                this.map.data.add({
                    geometry: {lat: s.lat_coord, lng: s.lng_coord},
                    id: s.id,
                    properties: {
                        site_id: s.id,
                        site_name: s.site_name
                    }
                });
            });
        }
    },
    methods: {
        ...mapActions([
            'restore','changeSelectedMatchCounties',
            'changeSites', 'changeMapZoom', 'changeFeatureInfo', 'changeLayerVisibility'
        ]),
        printMap() {
            showSnackbar('Printing is not yet available', 'error', 3000);
        },
        showInfo() {
            this.pageInfo = true;
        },

        xyzToBounds(x, y, z) {
            const EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];
            const tileSize = (EXTENT[1] * 2) / Math.pow(2, z);
            const minx = EXTENT[0] + x * tileSize;
            const maxx = EXTENT[0] + (x + 1) * tileSize;
            // remember y origin starts at top
            const miny = EXTENT[1] - (y + 1) * tileSize;
            const maxy = EXTENT[1] - y * tileSize;
            return [minx, miny, maxx, maxy];
        },
        async getFeatureInfo(bbox) {
                const res = await requestFeatureInfo(this.getInfoLayersString, bbox);
                if (res.status == 200) {
                    this.changeFeatureInfo(res.data)
                } else {
                    showSnackbar('There was a problem loading feature data', 'error', 3000);
                }
        },
        getTileUrl(coordinates, zoom) {
            return (
            "https://geoserver.fptz.org/geoserver/wms?service=WMS&version=1.1.0" +
            "&request=GetMap" +
            `&layers=${this.getLayersString}` +
            (this.getLayerVisibility('counties_geo') && !this.isLayerDisabled('counties_geo') ?
            `&cql_filter=IN%20(${this.getSelectedMatchCounties.map(d => `%27counties.${d.id}%27`).join("%2C%20")})${'%3BINCLUDE'.repeat(this.getLayersString.split(',').length)}`
            : "") +
            "&format=image/png" +
            "&width=256&height=256&srs=EPSG:3857" +
            "&transparent=true" +
            "&BBOX=" +
            this.xyzToBounds(coordinates.x, coordinates.y, zoom).join(",")
            );
        },

        zoomToPoint(lat,lng) {
            this.map.setOptions({
                center: { lat, lng },
                zoom: 12
            });
        },
        selectPanel(key) {
            if (key == this.selectedPanel) {
                this.selectedPanel = null;
            } else {
                this.selectedPanel = key;
            }
            this.activePanel = key;
        },
        mapSelectCounty() {
            this.geocoder.geocode({
                address: this.select.replace(',', ' County,'),
                region: 'us'
            }, (results, status) => {
                if (status === 'OK') {
                    this.map.fitBounds(results[0].geometry.bounds);
                } else {
                    showSnackbar('Could not find requested location', 'error', 3000);
                }
            });
        },
        locateSite() {
            if (this.addingSite) {
                this.cancelSite()
            } else {
                // Put button into 'loading' state
                this.addingSite = true;
                this.infoWindow.close()
                // On click of map, get coordinates
                // this.mapClickListener = this.map.addListener("click", (mapsMoustEvent) => {
                //     this.newSiteCoords = mapsMoustEvent.latLng.toJSON();
                // });
            }
        },
        async addSite() {
            await this.restore();
            const res = await postSite(this.getAccess, this.newSiteName, this.newSiteCoords.lng, this.newSiteCoords.lat);
            if (res.status == 201) {
                showSnackbar('Site added!', 'success', 3000);
                this.changeSites({
                    id: res.data.id,
                    site_name: res.data.site_name,
                    lng_coord: res.data.lng_coord,
                    lat_coord: res.data.lat_coord
                });
            } else if(res.status == 406) {
                showSnackbar('Site rejected; no data coverage', 'error', 3000)
            } else {
                showSnackbar('ERROR - site was not added', 'error', 3000);
            }
            // this.mapClickListener.remove();
            // this.mapClickListener = null;
            this.newSiteCoords = null;
            this.addingSite = false;
        },
        cancelSite() {
            this.newSiteCoords = null;
            this.addingSite = false;
            // this.mapClickListener.remove();
            // this.mapClickListener = null;
        },
        clearClickedSite() {
            this.clickedSite = {
                id: null,
                site_name: null
            };
        },
        async deleteSite(id, name) {
            await this.restore();
            const res = await deleteSite(this.getAccess, id);
            if (res.status == 204) {
                this.changeSites({ id });
                this.map.data.remove(this.map.data.getFeatureById(id));
                showSnackbar(`"${name}" has been deleted`, 'warning', 3000);
            } else {
                showSnackbar(`ERROR - "${name}" was NOT deleted`, 'error', 3000);
            }
            this.clearClickedSite();
        },
    }
}
</script>

<style>
</style>

<style scoped>
    @media print {
        #map-toolbar, #side-menu, #btn-bar, #btn-grp, #btn-grp * {
            display: none;
            visibility: hidden;
        }
        .gm-style div > img {
            position: absolute;
        }
    }
    #map { 
        height: calc(100vh - 64px);
    }
    #map-toolbar {
        position: absolute;
        top: 10px;
        right: 10px;
        max-width: 600px;
    }
    #map-toolbar button {
        margin: 14px;
    }

    /* The following two rules are meant to remove the 
    'powered by Google' image from the Autocomplete results menu
    but is not working. Because we are giving credit to Google in
    the main map, we do not need to include credit here as well */
    .hdpi.pac-logo:after {
        background-image: none !important;
        height: 0px;
    }
    .pac-logo:after {
        background-image: none !important;
        height: 0px;
    }
    /* End of attempt to stop Google */
    #side-menu {
        position: fixed;
        z-index: 0;
        top: 64px;
        height: calc(100% - 64px);
    }
    .site-li:not(:last-of-type):after {
        content: "";
        border-bottom: 1px solid #333;
        margin: 0 auto;
        padding-top: 10px;
        width: 100%;
        display: block;
    }
    #btn-bar {
        width: 5px;
    }
    #btn-grp {
        align-self: center;
    }
    #btn-grp div {
        padding: 5px;
        cursor: pointer;
    }
    .slidein {
        max-width: 400px;
        background: #ddd;
        left: 0;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        transition: all 0.2s ease-in-out;
    }
    .slide-enter, .slide-leave-active {
    left: -100%;
    }
</style>