import { API } from './config';

export async function requestUserInfo(access) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const response = await API.get('/auth/users/me/', {headers})
    .then(res => ({
        status: res.status,
        id: res.data.id,
        email: res.data.email,
        full_name: res.data.full_name,
        organization: res.data.organization
    }))
    .catch(async error => ({
        status: error.response.status,
    }));

    return response;
}

export async function requestChangePassword(
    access, current_password, new_password, re_new_password
    ) {
    
    const headers = {
        'Authorization': `JWT ${access}`
    };

    const data = JSON.stringify({
        current_password, new_password, re_new_password
    })

    const response = await API.post('/auth/users/set_password/', data, {headers})
    .then(res => ({
        status: res.status,
    }))
    .catch(async error => ({
        status: error.status,
        ...error.response.data,
    }));

    return response;
}

export async function requestResetPassword(email) {
    const data = JSON.stringify({
        email
    });

    const response = await API.post('/auth/users/reset_password/', data)
    .then(res => ({
        status: res.status
    }))
    .catch(async error => ({
        status: error.status,
        ...error.response.data,
    }));

    return response;
}