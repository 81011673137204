import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import user from './modules/user';
import discovery from './modules/discovery';
import exploration from './modules/exploration';
import comparison from './modules/comparison';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        user,
        discovery,
        exploration,
        comparison
    },

    getters: {
        routeQueryParams: (state, allGetters, rootState) => rootState.route.query,
        routeParams: (state, allGetters, rootState) => rootState.route.params,
      },
})
