<template>
    <v-dialog
      v-model="isOpen"
      max-width="600px"
      @click:outside="close"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="current_password"
                    label="Current Password*"
                    type="password"
                    required
                ></v-text-field>
                <div class="text-overline error--text">{{ current_password_check }}</div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="new_password"
                    label="New Password*"
                    type="password"
                    required
                ></v-text-field>
                <div class="text-overline error--text">{{ new_password_check }}</div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="re_new_password"
                    label="Repeat New Password*"
                    type="password"
                    @keydown="passwordKeydown"
                    required
                ></v-text-field>
                <div class="text-overline error--text">{{ re_new_password_check }}</div>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changePassword"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import { showSnackbar } from "@commons/Actions";
  import { requestChangePassword } from '@api/user';
  import { mapGetters, mapActions } from 'vuex';
  import EventBus, { ACTIONS } from '@commons/EventBus';

  export default {
    name: 'ChangePassword',
    data: () => ({
        isOpen: false,
        current_password: '',
        new_password: '',
        re_new_password: '',
        current_password_check: '',
        new_password_check: '',
        re_new_password_check: '',
    }),
    mounted() {
        EventBus.$on(ACTIONS.CHANGE_PASSWORD, () => {
            this.isOpen = true;
        });
    },
    computed: {
        ...mapGetters(['getAccess']),
    },
    methods: {
      ...mapActions(['restore']),
        close() {
          this.isOpen = false;
          this.current_password = '';
          this.new_password = '';
          this.re_new_password = '';
          this.current_password_check = '';
          this.new_password_check = '';
          this.re_new_password_check = '';
        },
        passwordKeydown(event) {
            if (event.keyCode ===13) {
                this.changePassword();
            }
        },
        async changePassword() {
          this.current_password_check = '';
          this.new_password_check = '';
          this.re_new_password_check = '';

          await this.restore();
            const res = await requestChangePassword(
              this.getAccess, this.current_password,
              this.new_password, this.re_new_password
            );
            if (res.status == 204) {
              showSnackbar('Password changed', 'success', 3000);
              this.isOpen = false;
            } else if (res.current_password) {
              this.current_password_check = res.current_password[0];
            } else if (res.new_password) {
              this.new_password_check = res.new_password[0];
            } else {
              this.re_new_password_check = res.non_field_errors[0];
            }
        }
    }
  }
</script>