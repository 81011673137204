<template>
  <v-app>
    <v-app-bar
      color="white"
      light
      app
    >
      <v-img id="nric-logo" height="30px" :contain="true" max-width="100px" @click="footer = !footer"
        :src="`${publicPath}logo_NRIC.svg`"
      ></v-img>
      <router-link to="/">
        <v-img width="150px" id="tool-name" :src="`${publicPath}logo_stand.svg`"
        ></v-img>
      </router-link>

      <v-tabs
        optional
        v-model="tab"
        background-color="transparent"
        color="primary"
        centered
      >
        <v-tab to="/discovery/">Discovery</v-tab>
        <v-tab to="/exploration/">Exploration</v-tab>
        <v-tab to="/comparison/">Comparison</v-tab>
      </v-tabs>

      <a id="email-support" class="text-body-2 font-weight-light text-decoration-none" href="mailto:noreply@fptz.org">Email&nbsp;Support</a>

      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn outlined large v-on="on" :loading="!isLoggedIn" color="primary">
            {{ getFullName }}
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <h3>{{ getFullName }}</h3>
              <p class="text-caption mt-1">
                {{ getEmail }}
              </p>
              <p class="text-caption mt-1">
                {{ getOrganization }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="changePassword"
              >
                Change Password
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="disconnect"
              >
                Disconnect
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>

    </v-app-bar>

    <v-main>
      <router-view v-if="isLoggedIn"></router-view>
      <Login @loginClick="login" v-else/>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="snackbarTimeout">{{ snackbarMessage }}</v-snackbar>
      <layer-info
        :is-open="layerInfo"
      ></layer-info>
      <modal-reference-map
        :is-open="referenceMap"
      ></modal-reference-map>
      <feature-detail
        :is-open="featureDetail"
        :tool="'stand'"
      ></feature-detail>
      <discovery-match-report-card
        :is-open="discoveryMatchReportCard"
      ></discovery-match-report-card>
      <change-password></change-password>
      <terms-of-use></terms-of-use>
        <v-dialog
            max-width="500px"
            persistent
            :value="popupBlockerDetected"
        >
            <v-card>
                <v-card-title class="justify-center">
                    <v-icon x-large>mdi-alert-circle</v-icon>
                </v-card-title>
                <v-card-text>
                    <p>
                        It seems that you have a popup blocker enabled. Some features
                        of STAND will not function as expected if popups are not allowed.
                    </p>
                    <p>
                        Please allow popups for https://stand.fptz.org in your browser settings.
                        You may need to reload STAND for changes to take effect.
                    </p>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="popupBlockerDetected = false"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
  <v-bottom-sheet v-model="footer" dark>
    <v-sheet>
    <v-row>
      <v-col cols="6">
        <p class="text-left text-caption"
        >Developed by the National Reactor Innovation Center in Collaboration with:</p>
        <v-row>
          <v-col cols="6">
            <v-img height="30px" :contain="true" :src="`${publicPath}logo_fastestpath.svg`"></v-img>
            </v-col>
        </v-row>
          <v-row>

          <v-col cols="3"><v-img height="30px" :contain="true" max-width="100px" :src="`${publicPath}logo_argonne.svg`"></v-img></v-col>
          <v-col cols="3"><v-img height="30px" :contain="true" max-width="100px" :src="`${publicPath}logo_inl.png`"></v-img></v-col>
          <v-col cols="3"><v-img height="30px" :contain="true" max-width="100px" :src="`${publicPath}logo_oakridge.svg`"></v-img></v-col>
          </v-row>
      </v-col>
      <v-spacer/>
      <v-col>
        <p class="overline">Contact Us</p>
        <a class="text-body-2 font-weight-light text-decoration-none" href="mailto:fastestpath@umich.edu">fastestpath@umich.edu</a>
      </v-col>
      <v-col>
        <p class="overline">University of Michigan Partners</p>
        <div><a class="text-body-2 font-weight-light text-decoration-none" href="https://ners.engin.umich.edu/" target="_blank"> Nuclear Engineering &amp; Radiological Sciences</a></div>
        <div><a class="text-body-2 font-weight-light text-decoration-none" href="https://csed.engin.umich.edu/" target="_blank">Center for Socially Engaged Design</a></div>
      </v-col>
    </v-row>
    </v-sheet>
  </v-bottom-sheet>
  </v-app>
</template>

<script>
import Login from '@commons/Login';
import LayerInfo from '@commons/LayerInfo.vue';
import ModalReferenceMap from '@commons/ModalReferenceMap.vue';
import FeatureDetail from '@commons/FeatureDetail.vue';
import DiscoveryMatchReportCard from './components/DiscoveryMatchReportCard.vue';
import TermsOfUse from '@commons/TermsOfUse.vue';
import EventBus, { ACTIONS } from '@commons/EventBus';
import { showChangePassword } from '@commons/Actions';
import ChangePassword from '@commons/ChangePassword.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',

  components: {
    Login,
    LayerInfo,
    ModalReferenceMap,
    FeatureDetail,
    DiscoveryMatchReportCard,
    ChangePassword,
    TermsOfUse,
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getFullName', 'getEmail', 'getOrganization'])
  },
  data: () => ({
    footer: false,
    publicPath: process.env.BASE_URL,
    tab: null,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    snackbarTimeout: -1,
    referenceMap: false,
    layerInfo: false,
    featureDetail: false,
    discoveryMatchReportCard: false,
    popupBlockerDetected: false,
  }),
  mounted() {
    this.restore();
    this.detectPopupBlocker();
    EventBus.$on(ACTIONS.SNACKBAR, (message, color, timeout) => {
      this.snackbarMessage = message;
      this.snackbarColor = color;
      this.snackbarTimeout = timeout;
      this.snackbar = true;
    });
  },
  beforeUpdate() {
    if (!this.getFullName && this.isLoggedIn) this.loadUser();
  },
  methods: {
    ...mapActions(['login', 'logout', 'restore', 'loadUser']),
    changePassword() {
      showChangePassword();
    },
    disconnect() {
      this.logout();
      localStorage.removeItem('refresh');
      window.location.href = "https://nric.inl.gov/stand_tool-2/";
    },
    detectPopupBlocker() {
      const test = window.open(null,"","width=100,height=100");
      try {
        test.close();
      } catch (e) {
        this.popupBlockerDetected = true;
      }
}
  }
};
</script>

<style scoped>
@media print {
  header {
    display: none;
  }
}
  #nric-logo {
    cursor: pointer;
  }
  #tool-name {
    padding-left: 10px;
  }
  .v-bottom-sheet .v-sheet{
    background-color: #024794;
    padding: 0 10px;
  }
  .v-bottom-sheet a {
    color: white;
  }

  .v-bottom-sheet .col {
    flex-grow: 2;
  }

  #email-support {
    margin-right: 1.5rem;
  }
</style>