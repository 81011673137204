<template>
    <div>
        <v-sheet
                id="overview"
                elevation="4"
                rounded
                outlined
                max-width="1000px"
        >
        <div class="text-h5 font-weight-bold primary--text">
            Site Comparison
        </div>
        <div class="text-body-1">
            Site comparison uses a multi-objective evaluation
            model as a structured framework for identifying which
            proposed sites best maximize the attributes that the
            user values. Attribute weighting factors in the site
            preference model are based on your expression of two
            independent factors:
        </div>
        <ul class="text-body-2">
            <li>
                How relevant each attribute is to your choice of a site
            </li>
            <li>
                How significant is the difference between each attribute's best value and worst value
            </li>
        </ul>
        <div class="text-body-1">
            An attribute's relevance is an indication of how important that
            attribute is in choosing a site. The best-to-worst range
            significance is an indication of how much the alternative sites
            differ in their ability to meet the objective represented by any
            attribute. You should think of these two factors completely
            independent of each other. An attribute that is very important to
            you should be rated as such in your indication of how relevant it
            is to your choice of a site. Even though this attribute may be
            very important to your choice of a site, if the alternative sites
            show very little difference between the best and worst value, it
            may be appropriate to indicate its range significance as
            relatively low on that (independent) scale. If an attribute is not
            at all part of your consideration in selecting a site, if you don’t
            care about it at all, it is not relevant to your site selection choice
            and should be indicated as Not Relevant. Likewise, if the best and
            worst alternative site values for some attributes are the same, there
            is no variation and no information that will help choose between the
            alternative sites and should be indicated as Not Significant.
        </div>
        <div class="text-body-1">
            The preference model sums your indications of Attribute
            Relevance and Range Significance to derive a raw weight for each
            attribute, which are then normalized to a sum of 100. Attributes
            indicated with relatively high relevance and relatively high range
            significance will have higher combined weights than those with
            relatively lower indications.
        </div>
        <div class="text-body-1">
            After indicating your Attribute Relevance and Range
            Significance choices, the preference model will display
            your choices in a matrix format along with the normalized
            weight for each attribute. You can go back to either the
            Attribute Relevance page or the Range Significance page to
            change your indications until you are satisfied with the
            normalized weights. 
        </div>
        <div class="text-subtitle-2">How To Use This Section:</div>
        <ol class="text-body-2">
            <li>Review Sites: Users can add 2 - 20 sites for comparison.</li>
            <li>
                Relevance Form: Users set the relevance of each attribute
                across three objectives; Socioeconomic, Safety and Proximity.
            </li>
            <li>
                Significance Form: Users set the significance of each
                attribute that was assigned a relevance value in step two.
            </li>
            <li>
                Attribute Relevance - Range Significance Matrix: Users can
                review their relevance and significance settings as well as
                the normalized weight for each attribute. Users can go back
                to either the Attribute Relevance and/or Significance pages
                to change your indications until you are satisfied with the
                normalized weights. 
            </li>
            <li>
                Results: View and export results charts, graphs, and download
                data in .csv format.
            </li>
        </ol>
        <div class="text-subtitle-2">Comparison between the Contiguous U.S, and Alaska/Hawaii may be limited by lack of data availability</div>
        <v-btn block class="success" @click="compareSites">Begin</v-btn>
        </v-sheet>
    </div>
</template>

<script>
export default {
    name: 'ComparisonOverview',
    props: {
        compareSites: Function
    }
}
</script>


<style scoped>
    #overview {
        margin: 50px auto;
        padding: 25px;
    }
    #overview>div:not(:first-child) {
        margin: 25px 0;
    }
    button.success {
        margin-top: 30px;
    }
</style>