import { requestComparisonRelevance, requestComparisonSignificance } from '@api/star/comparison'

const state = {
    comparison_step: 0,
    sites: [],
    site_areas: [],
    no_data_site: false,
    // database restriction_id value matches array position + 1
    nuclear_restrictions_relevances: [0,0,0,0,0,0,0],
    energy_price_relevance: null,
    net_electricity_imports_relevance: null,
    nuclear_sentiment_relevance: null,
    sentiment_buffer_miles: 0,
    nuclear_inclusive_policy_relevance: null,
    market_regulation_relevance: null,
    preferred_market_comp: null,
    // cdc_svi_relevance: null,
    // cdc_svi_buffer_miles: 0,
    operating_nuclear_relevance: null,
    nuclear_r_and_d_relevance: null,
    safe_shutdown_earthquake_relevance: null,
    safe_shutdown_earthquake_threshold: '6.5g',//'0.3g',
    fault_lines_relevance: null,
    landslide_hazard_relevance: null,
    electrical_substations_relevance: null,
    labor_rate_relevance: null,
    population_relevance: null,
    generator_retirement_relevance: null,
    min_years_to_retirement_comp: null,
    max_years_to_retirement_comp: null,
    retiring_generator_technologies_comp: ['Coal','Natural Gas','Nuclear'],
    hazardous_facilities_relevance: null,
    hazardous_facilities: [
        "Airports","Biodiesel Plants","Biological Products Manufacturing",
        "Chemical Manufacturing","Ethanol Plants","Explosives Manufacturing",
        "Liquified Natural Gas Import Terminals","Lubricating Oils and Grease Plants",
        "Natural Gas Compressor Stations","Natural Gas Import/Export Locations",
        "Natural Gas Processing Plants","Natural Gas Storage Facilities",
        "Nitrogenous Fertilizer Plants","Nuclear Fuel Plants","Oil Refineries",
        "Petroleum Pumping Stations","Pharmaceutical Preparations Manufacturing",
        "Phosphatic Fertilizer Plants","POL Terminals, Storage Facilities, Tank Farms"
    ],
    protected_lands_relevance: null,
    protected_lands: [
        'National monuments', 'Forests', 'National, state, and local parks',
        'Wild and scenic rivers', 'Wilderness areas', 'Wildlife refuges',
        'American Indian reservations', 'Hospitals', 'Correctional facilities',
        'Schools/colleges', 'Critical habitat'
    ],
    transportation_relevance: null,
    transportation: 'Major Roads',//['Major Roads', 'Navigable Waterways', 'Rail Lines'],
    one_hundred_year_flood_relevance: null,
    open_water_and_wetlands_relevance: null,
    slope_relevance: null,
    slope_threshold: '12%',
    streamflow_relevance: null,
    streamflow_threshold: 15000,
    nuclear_restrictions_significance: null,
    energy_price_significance: null,
    net_electricity_imports_significance: null,
    nuclear_sentiment_significance: null,
    nuclear_inclusive_policy_significance: null,
    market_regulation_significance: null,
    // cdc_svi_significance: null,
    operating_nuclear_significance: null,
    nuclear_r_and_d_significance: null,
    safe_shutdown_earthquake_significance: null,
    fault_lines_significance: null,
    landslide_hazard_significance: null,
    electrical_substations_significance: null,
    labor_rate_significance: null,
    population_significance:null,
    generator_retirement_significance: null,
    hazardous_facilities_significance: null,
    protected_lands_significance: null,
    transportation_significance: null,
    one_hundred_year_flood_significance: null,
    open_water_and_wetlands_significance: null,
    slope_significance: null,
    streamflow_significance: null,
    layer_stats: {},
    comparison_results: [],
};

const getters = {
    getSiteAreas: state => state.site_areas,
    getComparisonStep: state => state.comparison_step,
    getSites: state => state.sites,
    hasNoDataSite: state => state.no_data_site,
    getLayerStats: state => key => state.layer_stats[key],
    getComparisonResults: state => state.comparison_results,
    getSentimentBufferMiles: state => state.sentiment_buffer_miles,
    getPreferredMarketComp: state => state.preferred_market_comp,
    // getCdcSviBufferMiles: state => state.cdc_svi_buffer_miles,
    getSafeShutdownEarthquakeThreshold: state => state.safe_shutdown_earthquake_threshold,
    getSlopeThreshold: state => state.slope_threshold,
    getStreamflowThreshold: state => state.streamflow_threshold,
    getMinYearsToRetirementComp: state => state.min_years_to_retirement_comp,
    getMaxYearsToRetirementComp: state => state.max_years_to_retirement_comp,
    getRetiringGeneratorTechnologiesComp: state => state.retiring_generator_technologies_comp,
    isRetiringTechnologyComp: state => technology => state.retiring_generator_technologies_comp.includes(technology),
    getHazardousFacilities: state => state.hazardous_facilities,
    isHazardousFacility: state => facilityType => state.hazardous_facilities.includes(facilityType),
    getProtectedLands: state => state.protected_lands,
    isProtectedLand: state => landType => state.protected_lands.includes(landType),
    getTransportation: state => state.transportation,
    isTransportation: state => method => state.transportation.includes(method),
    getRestrictionRelevance: state => index => state.nuclear_restrictions_relevances[index],
    getRestrictionRelevances: state => state.nuclear_restrictions_relevances,
    getRestrictionsOverallRelevance: state => state.nuclear_restrictions_relevances.reduce((sum, val) => sum + val, 0) / 
                                                (state.nuclear_restrictions_relevances.filter(d => d > 0).length || 1),

    getRelevance: state => key => state[`${key}_relevance`],
    getAllRelevance: state => {
        return Object.keys(state)
                    .filter(key => /_relevance$/.test(key))
                    .reduce((obj, key) => {
                        obj[key] = state[key];
                        return obj;
                    }, {
                        nuclear_restrictions_relevance: state.nuclear_restrictions_relevances,
                    });
    },

    getSignificance: state => key => state[`${key}_significance`],
    getAllSignificance: state => {
        return Object.keys(state)
                    .filter(key => /_significance$/.test(key))
                    .reduce((obj, key) => {
                        obj[key] = state[key];
                        return obj;
                    }, {});
    },
    getWeightsByRange: state => (
        minRelevance,
        maxRelevance,
        minSignificance,
        maxSignificance,
        normalized
    ) => {
        let significances = Object.fromEntries(
            Object.entries(state)
                    .filter(kv => /_significance$/.test(kv[0]))
        );

        let relevances = Object.fromEntries(
            Object.entries(state)
                    .filter(kv => /relevance$/.test(kv[0]))
        );
        relevances['nuclear_restrictions_relevance'] = 
            state.nuclear_restrictions_relevances.reduce((sum, val) => sum + val, 0) / 
            (state.nuclear_restrictions_relevances.filter(d => d > 0).length || 1);

        let keys = Object.keys(significances).map(key => key.split('_').slice(0,-1).join('_'));

        const sumWeights = keys.reduce((sum, key) => {
            const significance = significances[`${key}_significance`];
            const relevance = relevances[`${key}_relevance`];
            const weight = significance == 0 || relevance == 0 ? 0 : significance + relevance;
            return sum + weight;
        }, 0);

        significances = Object.fromEntries(
            Object.entries(significances)
                .filter(kv => kv[1] >= minSignificance &&
                                kv[1] <= maxSignificance)
        );

        relevances = Object.fromEntries(
            Object.entries(relevances)
                .filter(kv => kv[1] >= minRelevance &&
                                kv[1] <= maxRelevance)
        );

        keys = Object.keys(relevances)
                            .map(key => key.split('_').slice(0,-1).join('_'));
        keys = Object.keys(significances)
                            .map(key => key.split('_').slice(0,-1).join('_'))
                            .filter(key => keys.includes(key));

        let weights = keys.reduce((weights, key) => {
            const title = key.split('_')
                                .map(d => d.substring(0,1).toUpperCase() + d.substring(1))
                                .join(' ');
            const significance = significances[`${key}_significance`];
            const relevance = relevances[`${key}_relevance`];
            weights[title] = significance == 0 || relevance == 0 ? 0 : significance + relevance;
            return weights;
        }, {});

        if (normalized) {
            weights = Object.entries(weights).reduce((normWeights, [key, val]) => {
                normWeights[key] = (val / sumWeights * 100).toFixed(3);
                return normWeights;
            }, {});
        } 
        
        return Object.entries(weights).sort((a, b) => b[1] - a[1])
            .sort((a, b) => {
                if (a[0] < b[0]) {
                    return -1;
                } else if (a[0] > b[0]) {
                    return 1;
                } else {
                    return 0;
                }
            });

    },
    getWeights: state => normalized => {
        const significances = Object.fromEntries(Object.entries(state).filter(kv => /_significance$/.test(kv[0])));

        const relevances = Object.fromEntries(Object.entries(state).filter(kv => /_relevance$/.test([kv[0]])));
        relevances['nuclear_restrictions_relevance'] = state.nuclear_restrictions_relevances.reduce((sum, val) => sum + val, 0) / 
                                                        (state.nuclear_restrictions_relevances.filter(d => d > 0).length || 1);

        const keys = Object.keys(significances).map(key => key.split('_').slice(0,-1).join('_'));

        const weights = keys.reduce((weights, key) => {
            // const title = key === 'cdc_svi' ? 'CDC SVI' : key.split('_')
            //                     .map(d => d.substring(0,1).toUpperCase() + d.substring(1))
            //                     .join(' ');
            const title = key.split('_').map(d => d.substring(0,1).toUpperCase() + d.substring(1)).join(' ');
            const significance = significances[`${key}_significance`];
            const relevance = relevances[`${key}_relevance`];
            weights[title] = significance == 0 || relevance == 0 ? 0 : significance + relevance;
            return weights;
        }, {});

        if (normalized) {
            const sumWeights = Object.values(weights).reduce((sum, weight) => sum + weight, 0);
            return Object.entries(weights).reduce((normWeights, [key, val]) => {
                normWeights[key] = val / sumWeights * 100;
                return normWeights;
            }, {});
        } else {
            return weights;
        }
    },
    getSiteTableHeaders: (state, getters) => {
        const weights = getters.getWeights(false);
        const headers = Object.keys(weights)
            .filter(key => weights[key] > 0)
            .map(key => {
                return {
                    text: key,
                    value: key.toLowerCase().split(' ').join('_')
                }
            });

        headers.unshift(
            {text: 'Rank', value: 'rank'},
            {text: 'Site Name', value: 'name'}
        );

        return headers;
    },
    getSiteTableItems: (state, getters) => {
        const headerMap = {};

        getters.getSiteTableHeaders.forEach(h => {
            if (h.value === 'name') {
                headerMap["name"] = 'site_name_';
            } else if (h.value === 'rank') {
                headerMap["rank"] = 'overall_rank_'
            } else {
                headerMap[h.value] = h.value + '_actual_value_';
            }
        });

        return getters.getComparisonResults.map(site => {
            const attributes = {};

            for (const [key, value] of Object.entries(headerMap)) {
                attributes[key] = site[value];
            }

            return attributes;
        });
    },


};

const actions = {
    changeSiteAreas:({ commit }, value) => commit('setSiteAreas', value),
    changeComparisonStep: ({ commit }, value) => commit('setComparisonStep', value),
    changeLayerStats: ({ commit }, value) => commit('setLayerStats', value),
    changeComparisonResults: ({ commit }, value) => commit('setComparisonResults', value),
    changeSites: ({ commit, state }, value) => {
        let sites= [...state.sites];
        let siteIDs = sites.map(d => d.id);
        sites = siteIDs.includes(value.id) ?
            sites.filter(d => d.id != value.id) : sites.concat(value);
        commit('setSites', sites);
        // Set comparison phase to 'Review Sites' if a site is added or removed
        // This is for sites added/removed in the exploration phase
        if (state.comparison_step > 1) {
            commit('setComparisonStep', 1);
        }
    },
    changeNoDataSite: ({ commit }, value) => commit('setNoDataSite', value),
    changeSentimentBufferMiles: ({ commit }, value) => commit('setSentimentBufferMiles', value),
    changePreferredMarketComp: ({ commit }, value) => commit('setPreferredMarketComp', value),
    // changeCdcSviBufferMiles: ({ commit }, value) => commit('setCdcSviBufferMiles', value),
    changeSafeShutdownEarthquakeThreshold: ({ commit }, value) => commit('setSafeShutdownEarthquakeThreshold', value),
    changeSlopeThreshold: ({ commit }, value) => commit('setSlopeThreshold', value),
    changeStreamflowThreshold: ({ commit }, value) => commit('setStreamflowThreshold', value),
    changeYearsToRetirementComp: ({ commit }, range) => {
        commit('setMinYearsToRetirementComp', range[0]);
        commit('setMaxYearsToRetirementComp', range[1]);
    },
    changeRetiringGeneratorTechnologiesComp: ({ commit, state }, value) => {
        let retiringGeneratorTechnologies = [...state.retiring_generator_technologies_comp];
        
        retiringGeneratorTechnologies = retiringGeneratorTechnologies.includes(value) ?
            retiringGeneratorTechnologies.filter(d => d != value) : retiringGeneratorTechnologies.concat(value);

        commit('setRetiringGeneratorTechnologiesComp', retiringGeneratorTechnologies);
    },
    
    changeHazardousFacilities: ({ commit, state }, value) => {
        let hazardousFacilities = [...state.hazardous_facilities];
        
        hazardousFacilities = hazardousFacilities.includes(value) ?
            hazardousFacilities.filter(d => d != value) : hazardousFacilities.concat(value);

        commit('setHazardousFacilities', hazardousFacilities);
    },
    
    
    changeProtectedLands: ({ commit, state }, value) => {
        let protectedLands = [...state.protected_lands];
        
        protectedLands = protectedLands.includes(value) ?
            protectedLands.filter(d => d != value) : protectedLands.concat(value);

        commit('setProtectedLands', protectedLands);
    },
    
    changeTransportation: ({ commit }, value) => commit('setTransportation', value),
    // changeTransportation: ({ commit, state }, value) => {
    //     let transportation = [...state.transportation];
    //     transportation = transportation.includes(value) ? transportation.filter(d => d != value) : transportation.concat(value);
    //     commit('setTransportation', transportation);
    // },
    
    loadComparisonRelevance: async ({ commit, state, rootGetters, dispatch }) => {
        const response = await requestComparisonRelevance(rootGetters.getAccess, rootGetters.getUserId);

        if(response.status === 200) {
            commit('setSentimentBufferMiles', response.sentiment_buffer_miles);
            commit('setPreferredMarketComp', response.preferred_market);
            // commit('setCdcSviBufferMiles', response.svi_buffer_miles);
            commit('setSafeShutdownEarthquakeThreshold', response.safe_shutdown_earthquake_threshold);
            commit('setSlopeThreshold', response.slope_threshold);
            commit('setStreamflowThreshold', response.streamflow_threshold);
            commit('setMinYearsToRetirementComp', response.min_years_to_retirement);
            commit('setMaxYearsToRetirementComp', response.max_years_to_retirement);
            if (response.retiring_generator_technologies.length) {
                commit('setRetiringGeneratorTechnologiesComp', response.retiring_generator_technologies);
            }
            if (response.hazardous_facilities.length) {
                commit('setHazardousFacilities', response.hazardous_facilities);
            }
            if (response.protected_lands.length) {
                commit('setProtectedLands', response.protected_lands);
            }
            if (response.transportation.length) {
                commit('setTransportation', response.transportation[0]);
            }
            if (response.nuclear_restrictions_relevance.length) {
                commit('setRestrictionRelevances', response.nuclear_restrictions_relevance);
            }

            Object.keys(state)
                .filter(key => /_relevance$/.test(key))
                .map(key => key.replace('_relevance', ''))
                .forEach(key => {
                commit('setRelevance', {key, val: response[`${key}_relevance`]});
            });
        } else {
            dispatch('restore', null, { root: true });
            dispatch('loadComparisonRelevance');
        }
    },
    changeRelevance: ({ commit }, key_val) => commit('setRelevance', key_val),
    changeRestrictionRelevance: ({ commit }, idx_val) => commit('setRestrictionRelevance', idx_val),
    changeSignificance: ({ commit }, key_val) => commit('setSignificance', key_val),
    
    loadComparisonSignificance: async ({ commit, rootGetters, dispatch }) => {
        const response = await requestComparisonSignificance(rootGetters.getAccess, rootGetters.getUserId);

        if(response.status === 200) {
            Object.keys(state)
                .filter(key => /_significance$/.test(key))
                .map(key => key.replace('_significance', ''))
                .forEach(key => {
                commit('setSignificance', {key, val: response[`${key}_significance`]});
            });
        } else {
            dispatch('restore', null, { root: true });
            dispatch('loadComparisonSignificance');
        }
    },
};

const mutations = {
    setSiteAreas: (state, value) => state.site_areas = value,
    setComparisonStep: (state, step) => state.comparison_step = step,
    setSites: (state, sites) => state.sites = sites,
    setNoDataSite: (state, value) => state.no_data_site = value,
    setLayerStats: (state, layer_stats) => state.layer_stats = {...state.layer_stats, ...layer_stats},
    setComparisonResults: (state, comparison_results) => state.comparison_results = comparison_results,
    setSentimentBufferMiles: (state, sentiment_buffer_miles) => state.sentiment_buffer_miles = sentiment_buffer_miles,
    setPreferredMarketComp: (state, preferred_market_comp) => state.preferred_market_comp = preferred_market_comp,
    // setCdcSviBufferMiles: (state, cdc_svi_buffer_miles) => state.cdc_svi_buffer_miles = cdc_svi_buffer_miles,
    setSafeShutdownEarthquakeThreshold: (state, threshold) => state.safe_shutdown_earthquake_threshold = threshold,
    setSlopeThreshold: (state, threshold) => state.slope_threshold = threshold,
    setStreamflowThreshold: (state, threshold) => state.streamflow_threshold = threshold,
    setMinYearsToRetirementComp: (state, min_years_to_retirement) => state.min_years_to_retirement_comp = min_years_to_retirement,
    setMaxYearsToRetirementComp: (state, max_years_to_retirement) => state.max_years_to_retirement_comp = max_years_to_retirement,
    setRetiringGeneratorTechnologiesComp: (state, retiring_generator_technologies) => state.retiring_generator_technologies_comp = retiring_generator_technologies,
    setTransportation: (state, transportation) => state.transportation = transportation,
    setProtectedLands: (state, protected_lands) => state.protected_lands = protected_lands,
    setHazardousFacilities: (state, hazardous_facilities) => state.hazardous_facilities = hazardous_facilities,
    setRestrictionRelevances: (state, relevances) => state.nuclear_restrictions_relevances = relevances,
    setRestrictionRelevance: (state, { index, val }) =>  {
        const relevances = [...state.nuclear_restrictions_relevances];
        relevances[index] = val;
        state.nuclear_restrictions_relevances = relevances;
    },

    setRelevance: (state, { key, val }) => state[`${key}_relevance`] = val,
    setSignificance: (state, { key, val }) => state[`${key}_significance`] = val,
};

export default {
    state,
    getters,
    actions,
    mutations
};